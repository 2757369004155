import {PhoneNumberObject} from '@/domain/models/PhoneNumberObject';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedPhoneNumberObject { 
  public numberType: CodeObject;
  public number: string;
  public telecomType: CodeObject | null;
  public comment: string | null;

  constructor(numberType: CodeObject, number: string, telecomType: CodeObject | null = null, comment: string | null = null) {
    this.numberType = numberType;
    this.number = number;
    this.telecomType = telecomType;
    this.comment = comment;
  }

  public toJSON(): unknown {
    return {
      numberType: this.numberType,
      number: this.number,
      telecomType: this.telecomType,
      comment: this.comment,
    };
  }

  public static fromJson(json: unknown): PhoneNumberObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new PhoneNumberObject(
      CodeObject.fromJson(parsedJson['numberType']),
      parsedJson['number'] as string,
      parsedJson['telecomType'] ? CodeObject.fromJson(parsedJson['telecomType']) : null,
      parsedJson['comment'] as string,
    );
  }

  public static multipleFromJson(json: unknown): PhoneNumberObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
