import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {DosingInstructionObject} from '@/domain/models/DosingInstructionObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {QuantityObject} from '@/domain/models/QuantityObject';

export class GeneratedInstructionsForUse { 
  public description: string;
  public dosingInstructions: DosingInstructionObject[];
  public routeOfAdministration: CodeObject | null;
  public additionalInstructions: CodeObject[] | null;
  public repeatPeriodCyclicalSchedule: QuantityObject | null;

  constructor(description: string, dosingInstructions: DosingInstructionObject[], routeOfAdministration: CodeObject | null = null, additionalInstructions: CodeObject[] | null = null, repeatPeriodCyclicalSchedule: QuantityObject | null = null) {
    this.description = description;
    this.dosingInstructions = dosingInstructions;
    this.routeOfAdministration = routeOfAdministration;
    this.additionalInstructions = additionalInstructions;
    this.repeatPeriodCyclicalSchedule = repeatPeriodCyclicalSchedule;
  }

  public toJSON(): unknown {
    return {
      description: this.description,
      dosingInstructions: this.dosingInstructions,
      routeOfAdministration: this.routeOfAdministration,
      additionalInstructions: this.additionalInstructions,
      repeatPeriodCyclicalSchedule: this.repeatPeriodCyclicalSchedule,
    };
  }

  public static fromJson(json: unknown): InstructionsForUse {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new InstructionsForUse(
      parsedJson['description'] as string,
      parsedJson['dosingInstructions'] ? DosingInstructionObject.multipleFromJson(parsedJson['dosingInstructions']) : [],
      parsedJson['routeOfAdministration'] ? CodeObject.fromJson(parsedJson['routeOfAdministration']) : null,
      parsedJson['additionalInstructions'] ? CodeObject.multipleFromJson(parsedJson['additionalInstructions']) : null,
      parsedJson['repeatPeriodCyclicalSchedule'] ? QuantityObject.fromJson(parsedJson['repeatPeriodCyclicalSchedule']) : null,
    );
  }

  public static multipleFromJson(json: unknown): InstructionsForUse[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
