import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import {ProviderSpecificationObject} from '@/domain/models/ProviderSpecificationObject';

export class GeneratedHealthcareProvider { 
  public id: number;
  public providerSpecification: ProviderSpecificationObject;

  constructor(id: number, providerSpecification: ProviderSpecificationObject) {
    this.id = id;
    this.providerSpecification = providerSpecification;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      providerSpecification: this.providerSpecification,
    };
  }

  public static fromJson(json: unknown): HealthcareProvider {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new HealthcareProvider(
      parsedJson['id'] as number,
      ProviderSpecificationObject.fromJson(parsedJson['providerSpecification']),
    );
  }

  public static multipleFromJson(json: unknown): HealthcareProvider[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
