import {DrugObject} from '@/domain/models/DrugObject';

export class GeneratedDrugObject { 
  public id: number;
  public arsenaryId: number | null;
  public name: string;
  public formularium: string | null;
  public purchasePrice: string;
  public packageUnit: string;
  public administrationRoute: number | null;
  public isOpium: boolean;
  public formattedDrugId: string;
  public prkCode: number;
  public hpkCode: number;
  public gpkCode: number;
  public isEmergencySupply: boolean;
  public emergencySupplyLabel: string | null;
  public inDds: boolean;
  public ddsLabel: string | null;
  public drugProperties: string[];
  public atc: string | null;
  public isCoumarin: boolean;
  public infusionAllowed: boolean;

  constructor(id: number, arsenaryId: number | null = null, name: string, formularium: string | null = null, purchasePrice: string, packageUnit: string, administrationRoute: number | null = null, isOpium: boolean, formattedDrugId: string, prkCode: number, hpkCode: number, gpkCode: number, isEmergencySupply: boolean, emergencySupplyLabel: string | null = null, inDds: boolean, ddsLabel: string | null = null, drugProperties: string[], atc: string | null = null, isCoumarin: boolean, infusionAllowed: boolean) {
    this.id = id;
    this.arsenaryId = arsenaryId;
    this.name = name;
    this.formularium = formularium;
    this.purchasePrice = purchasePrice;
    this.packageUnit = packageUnit;
    this.administrationRoute = administrationRoute;
    this.isOpium = isOpium;
    this.formattedDrugId = formattedDrugId;
    this.prkCode = prkCode;
    this.hpkCode = hpkCode;
    this.gpkCode = gpkCode;
    this.isEmergencySupply = isEmergencySupply;
    this.emergencySupplyLabel = emergencySupplyLabel;
    this.inDds = inDds;
    this.ddsLabel = ddsLabel;
    this.drugProperties = drugProperties;
    this.atc = atc;
    this.isCoumarin = isCoumarin;
    this.infusionAllowed = infusionAllowed;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      arsenaryId: this.arsenaryId,
      name: this.name,
      formularium: this.formularium,
      purchasePrice: this.purchasePrice,
      packageUnit: this.packageUnit,
      administrationRoute: this.administrationRoute,
      isOpium: this.isOpium,
      formattedDrugId: this.formattedDrugId,
      prkCode: this.prkCode,
      hpkCode: this.hpkCode,
      gpkCode: this.gpkCode,
      isEmergencySupply: this.isEmergencySupply,
      emergencySupplyLabel: this.emergencySupplyLabel,
      inDds: this.inDds,
      ddsLabel: this.ddsLabel,
      drugProperties: this.drugProperties,
      atc: this.atc,
      isCoumarin: this.isCoumarin,
      infusionAllowed: this.infusionAllowed,
    };
  }

  public static fromJson(json: unknown): DrugObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DrugObject(
      parsedJson['id'] as number,
      parsedJson['arsenaryId'] as number,
      parsedJson['name'] as string,
      parsedJson['formularium'] as string,
      parsedJson['purchasePrice'] as string,
      parsedJson['packageUnit'] as string,
      parsedJson['administrationRoute'] as number,
      parsedJson['isOpium'] as boolean,
      parsedJson['formattedDrugId'] as string,
      parsedJson['prkCode'] as number,
      parsedJson['hpkCode'] as number,
      parsedJson['gpkCode'] as number,
      parsedJson['isEmergencySupply'] as boolean,
      parsedJson['emergencySupplyLabel'] as string,
      parsedJson['inDds'] as boolean,
      parsedJson['ddsLabel'] as string,
      parsedJson['drugProperties'] as string[],
      parsedJson['atc'] as string,
      parsedJson['isCoumarin'] as boolean,
      parsedJson['infusionAllowed'] as boolean,
    );
  }

  public static multipleFromJson(json: unknown): DrugObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
