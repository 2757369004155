<template>
  <!--
        (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)
        (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)

        LEGACY - USE MedimoVueSelect2, MedimoLabeledVueSelect2 and MedimoValidatedVueSelect2

        (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)
        (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)
  -->
    <div :id="id" :class="'medimo-select2 ' + validation_class + ' ' + select2_class" ref="select2_parent" style="position: relative;">
        <medimo-input v-if="editable" v-bind:name="name+'_editable'" :modelValue="custom_text" :placeholder="placeholder" @update:modelValue="$emit('editedText', $event)" class="editable-select2"></medimo-input>
        <select :multiple="multiple" ref="select2_element" :style="{width: width}" :disabled="disabled" :class="extra_classes" v-bind:name="name"></select>
        <medimo-tooltip v-if="help_text.length" :content="help_text" :class="(inline ? '' : 'not-inline')">
          <fa-icon icon="fa-solid fa-circle-question" class="text-primary" size="lg" />
        </medimo-tooltip>
      <div class="invalid-feedback" v-show="showInvalid">
        Ongeldige waarde ingevoerd
      </div>
      <medimo-loader-overlay :size="16" :loading="loading"></medimo-loader-overlay>
    </div>
</template>

<script>
import MedimoInput from '@/vue/components/general/form/base/MedimoInput';
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import Utility from '@/vue/utility/utility';
import { nextTick } from 'vue';

export default {
  components: {
    MedimoTooltip,
    MedimoInput,
    MedimoLoaderOverlay,
  },
  emits: ['editedText','update:modelValue','opening','select','unselect'],
  /**
     * options format:
     * [
     *  {
     *    text: text,
     *    value: value,
     *    (optional) attributes: { attribute1: value, attribute2: value}
     *    (optional) selected: bool
     *
     *   }
     * ]
     */
  props: {
    id: {},
    'inline': {default: true},
    'modelValue': {default: ''},
    'options': {
      default: function () {
        return [];
      }
    },
    // Door deze in te stelen kun je vanaf X handmatig filteren / zoeken binnen de select2's
    'minimum_results_for_search': {default: 15},
    'allow_editable_text_only': {default: false},
    'minimum_input_length': {default: 3},
    'width': {default: '100%'},
    'name': {default: 'select2'},
    'ajaxCall': {default: ''},
    'help_text': {default: ''},
    'select2_class': {default: ''},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'placeholder': {default: 'Maak een keuze'},
    'multiple': {default: false},
    'auto_select': {default: false}, // Als er maar 1 optie is, auto-select hij die
    'disabled': {default: false},
    'loading': {default: false}, // Will show a small loader overlay, for when you're waiting on an external call

    // Set to TRUE to show the chosen Select2 value in a MedimoInput that's placed above the Select2.
    // Visually exactly the same, but allows for manual editing of the selected value.
    editable: {default: false},
    // You can have the editable_text pre-fill as well
    editable_text: {default: null},
  },

  mixins: [],

  data: function () {
    return {
      // Used a a temporary storage for the editable text
      custom_text: '',
      showInvalid: false,
      isOpen: false,
      select2_element: null,
      select2_parent: null,
    };
  },

  computed: {
    settings: function () {
      const settings = {
        minimumResultsForSearch: this.minimum_results_for_search,
        placeholder: this.placeholder,
        dropdownParent: this.select2_parent,
      };
        //extend with select2MultiWordMatchAjax if ajax

      if (this.ajaxCall !== '') {
        settings['ajax'] = {
          url: this.ajaxCall,
          delay: 300,
          cache: true,
          dataType: "json",
          data: function (params) {
            return {
              search: params.term, // search term
              page: params.page
            };
          },
          processResults: function (data, params) {
            // Catch errors
            if (data instanceof Array) {
              // Let formajaxprocessor catch notification
              // TODO Stan handle errors , old style: processAjaxUpdate(data);
              // Return empty result
              return {
                results: [],
                pagination: {
                  more: false
                }
              };
              // No errors, so return data
            } else {
              // parse the results into the format expected by Select2
              // since we are using custom formatting functions we do not need to
              // alter the remote JSON data, except to indicate that infinite
              // scrolling can be used
              params.page = params.page || 1;

              data.results = $.map(data.results, function (obj) {
                obj.id = obj.id || obj.value;

                return obj;
              });

              return {
                results: data.results,
                pagination: {
                  more: (params.page * 30) < data.total_count
                }
              };
            }
          }
        };

        settings['minimumInputLength'] = this.minimum_input_length;

        settings['language'] = 'nl';

        settings['formatResult'] = function formatResult(result, container, query, escapeMarkup) {
          const terms = query.term.split(" ");
          let text = result.text;

          for (let i = 0; i < terms.length; i++) {
            const markup = [];
            Select2.util.markMatch(text, terms[i], markup, escapeMarkup);
            text = markup.join("");
          }

          return text;
        };

        settings['escapeMarkup'] = function escapeMarkup(m) {
          return m;
        };

      }
      return settings;
    },
    invalidValue() {
      if (this.modelValue === '' || this.modelValue === null) {
        return false;
      }
      // Als de waarde niet gevonden wordt returned deze helper -1
      return Utility.find_index_of_matching_element(this.options, 'value', this.modelValue) < 0;
    }
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.setOptions();
        // Als hij al open was moeten we hem even closen en weer openen om de nieuwe data te tonen.
        if (this.isOpen) {
          this.close();
          this.open();
        }
      }
    },
    modelValue(val, old) {
      if (val !== old) {
        $(this.select2_element).val(this.modelValue).trigger('change');
      }
    },
    editable_text(newValue, oldValue) {
      this.custom_text = newValue;
    },
    invalidValue: {
      immediate: true,
      handler(isInvalid) {
        if (isInvalid) {
          // // Empty the value als hij niet bestaat zodat we geen invalide waardes vast kunnen houden
          const vm = this;
          setTimeout(() => {
            vm.$emit('update:modelValue', ''); // Moet een kleine delay, anders issie de Select2 render voor en dan krak
          }, 100);
          // En we laten 5 seconden de boodschap aan de gebruiker zien
          this.showInvalid = true;
          setTimeout(() => {this.showInvalid = false;}, 5000);
        }
      }
    },
  },

  created() {
    //
  },

  mounted() {
    this.select2_element = this.$refs.select2_element;
    this.select2_parent = this.$refs.select2_parent;

    this.initSelect2();
    this.setOptions();

    // When the select2 is editable and editable text is given, we use that,
    // otherwise we use the text given in the option.
    if (this.editable) {
      const selectedOption = Utility.find_model_by_property(this.options,'value', this.modelValue);

      // When editable_text is not null and allow_editable_text_only is true or no selectedOption.
      if (this.editable_text !== null && (this.allow_editable_text_only || selectedOption === null)) {
        if (this.editable_text.length) {
          this.custom_text = this.editable_text;
        }
      } else if (!this.allow_editable_text_only && selectedOption !== null) {
        if (typeof selectedOption.text !== 'undefined' && selectedOption.text !== null) {
          this.custom_text = selectedOption.text;
        }
      }
    }

    // Als er maar 1 optie is om uit te kiezen kunnen we die auto-selecten:
    if (this.auto_select && this.options.length === 1) {
      this.$emit('update:modelValue', this.options[0].value);
    }

    nextTick(() => {
      if (this.modelValue !== '') {
        $(this.select2_element).val(this.modelValue).trigger('change');
      }
    });
  },

  methods: {
    open() {
      $(this.select2_element).select2('open');
    },
    close() {
      $(this.select2_element).select2('close');
    },
    setOptions: function () {
      const vm = this;

      // Clear the HTML before appending new options
      $(this.select2_element).html('');

      // We voegen eerst een lege default toe omdat anders een waarde van "" de eerste selecteert:
      if (this.multiple === false) {
        const newOption = new Option(this.placeholder, "", true, true);
        $(vm.select2_element).append(newOption);
      }

      this.options.forEach(function (option) {
        let selected = false;
        let defaultSelected = false;

        if (typeof option.selected !== "undefined") {
          selected = option.selected;
          defaultSelected = option.selected;
        } else if (option.value === vm.value) {
          selected = true;
          defaultSelected = true;
        }

        const newOption = new Option(option.text, option.value, defaultSelected, selected);
        if (typeof option.attributes !== 'undefined') {
          const keys = Object.keys(option.attributes);
          keys.forEach(function (key) {
            newOption.setAttribute(key, option.attributes[key]);
          });
        }
        $(vm.select2_element).append(newOption);
      });

      if (this.modelValue !== '') {
        $(this.select2_element).val(this.modelValue).trigger('change');
      }
      else {
        $(this.select2_element).trigger('change');
      }
    },
    destroySelect2: function () {
      $(this.select2_element).off().select2('destroy');
    },
    initSelect2: function () {
      const vm = this;

      $(this.select2_element).select2(this.settings);

      $(this.select2_element).on(
        "select2:opening",
        function (e) {
          vm.$emit('opening');
        }
      );
      $(this.select2_element).on(
        "select2:open",
        function (e) {
          vm.isOpen = true;
        }
      );
      $(this.select2_element).on(
        "select2:close",
        function (e) {
          vm.isOpen = false;
        }
      );
      $(this.select2_element).on(
        "select2:select",
        function (e) {
          const data = e.params.data;
          vm.$emit('select', data);
          if (typeof data.element !== 'undefined') {
            vm.showInvalid = false;
            vm.$emit('update:modelValue', data.element.value);
            vm.$emit('editedText', data.element.text);
            vm.custom_text = data.element.text;
          }
        }
      );
      $(this.select2_element).on(
        "select2:unselect",
        function (e) {
          // https://stackoverflow.com/questions/35206029/trying-to-stop-event-propagation-of-select2-multi-select-option-remove
          if (!e.params.originalEvent) {
            return;
          }
          e.params.originalEvent.stopPropagation();

          const data = e.params.data;
          vm.$emit('unselect', data);
          vm.$emit('update:modelValue', null);
        }
      );
      $(this.select2_element).on(
        "select2:change",
        function (e) {
          const selectedOption = e.target.querySelector('option[selected]');
          if (selectedOption !== null) {
            vm.custom_text = selectedOption.innerText;
          }
        }
      );
    },
  },

  unmounted: function () {
    this.destroySelect2();
  }
};
</script>
