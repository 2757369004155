import {store} from '@/vue/store';
import {CodeValue} from "@/domain/models/CodeValue";
import {CodeObject} from "@/domain/models/CodeObject";
import {AsNeededObject} from "@/domain/models/AsNeededObject";
import {QuantityObject} from "@/domain/models/QuantityObject";
import {HealthcareProvider} from "@/domain/models/HealthcareProvider";

export class InitialDataController {
  private storeName = 'mp9/initial_data';

  public read(type: string, patientId: number): void {
    // When already stored we do not retrieve the data again.
    if (store.getters[this.storeName + '/data'].length !== 0 && store.getters[this.storeName + '/type'] === type) {
      return;
    }

    store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/initial-data?type=' + type,
    }).then(response => {
      store.commit(this.storeName + '/set_data', response.data as Options[]);
      store.commit(this.storeName + '/set_type', type);
    }).catch(() => {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error();
    });
  }

  public dataFromStore(type: string): Options[] {
    return store.getters[this.storeName + '/data'][type];
  }
}

export interface Options {
  value: string,
  text: string,
  plural?: string,
  reference: {
    codeValue?: CodeValue,
    codeObject?: CodeObject,
    weekDays?: CodeValue[] | null,
    timeUnit?: QuantityObject | null,
    cyclicalScheme?: QuantityObject | null,
    doseDuration?: QuantityObject | null
    asNeededObject?: AsNeededObject,
    healthcareProvider?: HealthcareProvider,
    numberOfDays?: number,
  }
}
