<template>
  <div v-if="!loading">
    <div v-if="!show_error_overlay && isAllowedToViewPage">
      <h1 class="mb10" v-if="title.length">
        {{ title }}
      </h1>
      <slot></slot>
      <div id="modal-position"></div>
    </div>
    <div v-else-if="!isAllowedToViewPage" class="page-component" style="min-height: 50vh;">
      <div class="row">
        <div class="col-12">
          <h1 id="overview-title">
            Ongeautoriseerd
          </h1>
        </div>
        <div class="col-12 mt-2">
          U heeft onvoldoende rechten voor deze pagina. Indien u via Medimo hier terecht bent gekomen gelieve
          de <a href='mailto:helpdesk@medimo.nl'>Medimo helpdesk</a> hiervan te waarschuwen.
        </div>
      </div>
    </div>
    <page-block v-else class="page-component mt-2">
      <div class="col-12">
        <h1>Er is een fout opgetreden</h1>
      </div>
      <div class="col-12 mt-2">
          <p>
            Er is een logging verzonden naar het ontwikkelteam van Medimo.<br>
            Indien u deze fout vaker tegenkomt gelieve de <a href='mailto:helpdesk@medimo.nl'>Medimo helpdesk</a> hiervan te
            waarschuwen.<br>
          </p>
          <p>
            In alle overige gevallen ga naar de hoofdpagina van <a href='/'>Medimo</a>.
          </p>
      </div>
      <div class="col-12 mt-2">
          <p class="postscript">
            Tijd: {{ now }}  URL: {{ $store.getters['settings/breadcrumbs/previous_breadcrumb'] }} JS.LOGID:{{ sentry_event_id }}
          </p>
      </div>
    </page-block>

    <page-block v-if="currentPageIsVuePage()"
        :transparent="true"
        v-bind:class="{
            'overview-page': currentlyOnOverviewPage,
            'pt-0': currentlyOnOverviewPage,
            'pl-0 pr-0 pt-3': ! currentlyOnOverviewPage,
        }">
      <medimo-footer-nav v-slot="vm">
        <div id="footernav" class="text-muted">
          <medimo-button type="medimo-15" class="home-medimo-link mr-2" icon="fa-solid fa-house" to="/" @click="vm.hideClusterSelector" aria-label="home" />
          <medimo-button type="medimo-15" class="home-medimo-link back" icon="fa-solid fa-arrow-left" @click="$router.go(-1)" aria-label="back" />
        </div>
      </medimo-footer-nav>
    </page-block>
  </div>
</template>

<script>

import IsBreadcrumb from '@/vue/mixins/IsBreadcrumb';
import MedimoFooterNav from '@/vue/components/nav/MedimoFooterNav';
import PageBlock from '@/vue/components/general/layout/PageBlock';
import MedimoLink from '@/vue/components/general/MedimoLink';
import ChecksIfVuePage from '@/vue/mixins/navigation/ChecksIfVuePage';
import MedimoAlertContainer from '@/vue/components/general/MedimoAlertContainer';
import MedimoNotification from '@/vue/components/general/MedimoNotification';
import moment from 'moment-mini';

export default {
  components: {
    MedimoFooterNav,
    PageBlock,
    MedimoLink,
    MedimoAlertContainer,
    MedimoNotification,
  },

  props: {
    'title': {type: String, default: ''},
    'loading': {type: Boolean, default: false},
    'disable_breadcrumb': {type: Boolean, default: false},
  },

  mixins: [
    IsBreadcrumb,
    ChecksIfVuePage,
  ],

  data: function () {
    return {
      show_all_errors: false,
    };
  },

  computed: {
    show_error_overlay() {
      return this.page_errors.length > 0;
    },
    page_errors() {
      return this.$store.getters['settings/errors/page_errors'];
    },
    currentlyOnOverviewPage() {
      return this.$store.state.settings.navigation.currentlyOnOverviewPage;
    },
    /**
     * This computed property checks if the user is allowed to be on the current page
     * using the "visible" property from the routes store.
     * Only when the value is explicitely FALSE, do we use it. To prevent unwanted future behaviour.
     * TRUE by default.
     * @returns {boolean}
     */
    isAllowedToViewPage() {
      const route = this.$store.getters['routes/routes']({to: this.$route.path});
      if (typeof route !== 'undefined') {
        return route.visible;
      }
      return true;
    },
    sentry_event_id() {
      return this.$store.state.settings.errors.sentry_event_id;
    },
    now() {
      return moment().format('DD-MM-YYYY hh:mm:ss');
    }
  },

  created() {
    this.$store.commit('settings/errors/clear');
  },
  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
