<template>
  <medimo-checkbox
      ref="checkbox"
      v-bind:modelValue="modelValue"
      @update:modelValue="onChecked($event)"
      :inline="inline"
      :name="name"
      :extra_classes="extra_classes"
      :extra_classes_container="extra_classes_container"
      :validation_class="validation_class"
      :help_text="help_text"
      :label-clickable="labelClickable"
      :label_cols="label_cols"
      :label_position="label_position"
      :disabled="disabled"
      :id="id"
      :input_id="input_id"
  >
    <template v-slot:label>
      <label class="checkbox-label unselectable" :style="'cursor: inherit; font-style: ' + labelFontStyle" :for="input_id">{{ label }}</label>
      <span v-if="warningText" class="text-danger text-small m-2">{{ warningText }}</span>
    </template>
  </medimo-checkbox>
</template>

<script>
import MedimoCheckbox from './MedimoCheckbox';
import {v4 as uuidv4} from 'uuid';

export default {
  components: {
    MedimoCheckbox
  },

  emits: ['update:modelValue', 'update:self'],

  props: {
    // Set this to left or right to move the label to the left or right of the checkbox
    'label_position': {default: 'left'},
    'labelFontStyle': {default: 'inherit'},
    'labelClickable': {default: false},
    'warningText': {default: ''},

    'inline': {default: true},
    'modelValue': {default: '', type: [String, Boolean, Number]},
    'label': {default: ''},
    'name': {default: 'checkbox'},
    'extra_classes': {default: ''},
    'extra_classes_container': {default: ''},
    'validation_class': {default: ''},
    'help_text': {default: ''},
    'label_cols': {},
    'id': {default: false, type: [String, Boolean]},
    'disabled': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      'checked': false,
      'input_id': uuidv4(),
    };
  },

  computed: {
    //
  },

  created() {
    // ETA in Vue3 merge, onderstaand ziet er raar uit... reversen van de value, als er testen mis gaan, deze weg
    // We can't modify the prop, so we work around that with the checked value
    this.checked = !!this.modelValue;
    // if (this.id === false) {
    //   this.id = uuidv4();
    // }
  },

  mounted() {
    //
  },

  methods: {
    onChecked(value) {
      this.$emit('update:modelValue', value);
      this.$emit('update:self', this.$refs.checkbox);
    }
  },

  watch: {
    checked(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value) {
      this.checked = value;
    }
  }
};
</script>
