import {store} from "@/vue/store";
import DataType from "@/enums/DataType";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";
import {AbstractMedicationStore} from "@/controllers/AbstractMedicationStore";

export abstract class AbstractPharmaceuticalTreatment<T> extends AbstractMedicationStore {
  protected abstract storeName: string;
  protected abstract dataType: string;

  public async readFromStore(id: number, dataType: DataType): Promise<T | null> {
    try {
      return store.getters[this.storeName + '/' + dataType](id);
    } catch (error: unknown) {
      throw new Error();
    }
  }

  public async readFromStoreByProperty(id: number, property: string): Promise<T[] | null> {
    return store.getters[this.storeName + '/backendByProperty'](id, property);
  }

  public removeFromStore(): void {
    store.commit('mp9/medication_agreements/clearData');
    store.commit('mp9/administration_agreements/clearData');
    store.commit('mp9/variable_dosing_regimens/clearData');
    store.commit('mp9/medication_uses/clearData');
  }

  public userModifiedData(pharmaceuticalTreatmentId: number): T[] {
    const dataFromStore: AbstractMedicationData<T>[] = this.dataFromStore(pharmaceuticalTreatmentId);

    // Retrieve all user modified records.
    const userModifiedData: T[] = [];
    for (const data of dataFromStore) {
      if (data.userModified !== null) {
        userModifiedData.push(data.userModified);
      }
    }

    return userModifiedData;
  }

  private dataFromStore(pharmaceuticalTreatmentId: number): AbstractMedicationData<T>[] {
    try {
      return store.getters[this.storeName + '/findByTreatment'](pharmaceuticalTreatmentId);
    } catch (error: unknown) {
      throw new Error();
    }
  }
}
