'use strict';

class OptionHelper {
  static actionOption = 1;
  static filterOption = 2;
  static filterStatusOption = 3;
  static startOption = 4;
  static stopOption = 5;
  static changeOption = 6;
  static stopFoundStatusOption = 7;
  static customQuestionOption = 8;
  static customAnswerOption = 9;
  static extraProtocolOption = 10;

  constructor(language, Utility = null) {
    this.language = language;
    this.utility = Utility;
  }

  getTitle(item) {
    switch (item.type) {
      case OptionHelper.filterOption:
      case OptionHelper.filterStatusOption:
        return this.getFilterTitle(item.values);
      case OptionHelper.actionOption:
        return this.getActionTitle(item.values);
      case OptionHelper.startOption:
        return this.getStartStatusTitle(item.values, true);
      case OptionHelper.changeOption:
        return this.getStartStatusTitle(item.values, false);
      case OptionHelper.stopOption:
      case OptionHelper.stopFoundStatusOption:
        return this.getStopStatusTitle(item.values);
      case OptionHelper.customQuestionOption:
        return {txt: item.values.question, html: 'Stel vraag:'};
      case OptionHelper.customAnswerOption:
        return {txt: item.values.answer, html: 'Antwoord optie:'};
      case OptionHelper.extraProtocolOption:
        return {txt: (item.values.global ? 'Landelijk - ' : 'Lokaal - ') + item.values.text, html: 'Extra protocol:'};
      default:
        return {txt: '', html: ''};
    }
  }
  getFilterTitle(values) {
    const val = JSON.parse(values);

    return {html: this.utility.ucfirst(this.language['patient']) + ' voldoet ' + (val.inverse ? 'NIET ' : '') + 'aan:', txt: this._formatGroup(val)};
  }
  getActionTitle(values) {
    let startAfterText = '';

    if (values['startAfterDays'] !== '0' && values['startAfterDays'] !== 0) {
      if (values['startAfterDays'] === '1') {
        startAfterText = ' (start na 1 dag)';
      } else {
        startAfterText = ' (start na ' + values['startAfterDays'] + ' dagen)';
      }
    }
    return {html: 'Voeg actie toe:', txt: values['name'] + startAfterText};
  }
  getStartStatusTitle(values, start) {
    const drugName = values['drugName'];
    const usageType = values['usageType'];
    let prettyUsageType;
    if (usageType === 'T') {
      const daysDuration = values['daysDuration'];
      prettyUsageType = ' tijdelijk';
      if (typeof daysDuration !== 'undefined') prettyUsageType += this._getDaysDuration(daysDuration);
    } else if (usageType === 'S') prettyUsageType = ' handmatig schema';
    else if (usageType === 'C') prettyUsageType = ' continue';

    let titleStartMoment = this._getStartMomentText(values);

    const startAfterDays = values['startAfterDays'];
    if (start) {
      if (startAfterDays === '1') titleStartMoment += ' (na minimaal ' + startAfterDays + ' dag)';
      else if (startAfterDays !== '0') titleStartMoment += ' (na minimaal ' + startAfterDays + ' dagen)';
    } else {
      if (startAfterDays === '1') titleStartMoment += ' (minimaal ' + startAfterDays + ' dag na voorganger)';
      else if (startAfterDays !== '0') titleStartMoment += ' (minimaal ' + startAfterDays + ' dagen na voorganger)';
    }

    return {txt: drugName + ' ' + titleStartMoment + ', ' + prettyUsageType, html: start ? 'START' : 'WIJZIG'};
  }
  getStopStatusTitle(values) {
    let drugName = values.drugName;

    if (typeof values.drugName === 'undefined') {
      drugName = 'gevonden medicatieregel(s)';
    }

    let titleStartMoment = this._getStartMomentText(values);
    const startAfterDays = values.startAfterDays;
    if (startAfterDays === '1') titleStartMoment += ' (minimaal ' + startAfterDays + ' dag na voorganger)';
    else if (startAfterDays !== '0') titleStartMoment += ' (minimaal ' + startAfterDays + ' dagen na voorganger)';

    return {txt: drugName + ' ' + titleStartMoment, html: 'STOP'};
  }
  _getDaysDuration(daysDuration) {
    if (daysDuration === '1') {
      return ' voor ' + daysDuration + ' dag';
    } else {
      return ' voor ' + daysDuration + ' dagen';
    }
  }
  _getStartMomentText(values) {
    let titleStartMoment = '';
    const startMoment = values['startMoment'];
    if (startMoment === '4') titleStartMoment += 'per direct';
    else if (startMoment === '0') titleStartMoment += 'per vandaag';
    else if (startMoment === '1') titleStartMoment += 'per morgen';
    else if (startMoment === '2') titleStartMoment += 'per ' + this.language['weekdoos'];
    return titleStartMoment;
  }
  _formatGroup(group) {
    let returnString = '';

    if (typeof group.groups !== 'undefined') {
      let firstGroup = true;
      group.groups.forEach((subGroup) => {
        const subGroupText = this._formatGroup(subGroup);
        // If we cannot show anything, filter this group from title. It will be removed in the backend
        if (!subGroupText) {
          return;
        }

        if (firstGroup) {
          firstGroup = false;
        } else {
          returnString += '<br/><div style="margin: 3px;"><b>' + this._formatLogic(group.logic) + '</b></div>';
        }
        returnString += subGroupText;
      });
    }

    if (typeof group.items !== 'undefined') {
      let firstItem = true;
      group.items.forEach((item) => {
        // If we cannot show anything, filter this item from title. It will be removed in the backend
        if (!item.text) {
          return;
        }

        if (firstItem) {
          firstItem = false;
          returnString += ' ';
        } else {
          returnString += '<br/> <b>' + this._formatLogic(group.logic) + '</b> ';
        }
        returnString += item.text;
      });
    }

    return returnString;
  }
  _formatLogic(logic) {
    return logic === 'a' ? 'en' : 'of';
  }
}

module.exports = OptionHelper;
