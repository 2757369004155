import Utility from '@/vue/utility/utility';

// initial state of the overview pages
const state = {

  showLogo: true,
  showClusterSelector: false,
  showClusterSwitch: false,
  isVueErrorPage: false,
  adminPanel: {
    show: false,
    large: true,

    fabsVisible: true,
    otrsVisible: true,
    koppelingenVisible: true,
  },
  currentlyOnOverviewPage: false,

  // Dit is een array met de actieve tabellen. Deze houden we bij in een array om zo een "historie"
  // te hebben van de tabellen die actief waren, en op die manier ook niet direct een herfocus nodis
  // te hebben om
  activeTableUid: 0,
  activeTableUids: [],
  availableTables: [],

  // Deze houdt per MedimoTable bij welke row geselecteerd is
  selectedTableRowUid: {},
  // Deze houdt per MedimoTable bij welke rows er geselecteerd kunnen worden
  availableTableRows: {},
  // This is a toggle that's being watched by all MedimoTrs
  // Changing it triggers the Trigger event on the Selected TableRow
  triggerSelectedTableRow: false,

  // This one stores the available UIDs of the Page Nav Items
  selectedPageNavItemUid: 0,
  availablePageNavItems: [],

  // This one stores the available UIDs of the OverviewLinks
  selectedOverviewLinkUid: 0,
  availableOverviewLinks: [],

  // This one stores the available UIDs of the NavSearch
  selectedNavSearchLinkUid: 0,
  availableNavSearchLinks: [],

  // This one is being watched by the NavSearch component, making it visible from anywhere in the application
  // Changing it from true to false or false to true will trigger it
  navSearchToggle: false,

  // This one controls if the arrows should navigate within the search results, or the regular page
  navSearchNavigation: false,

  // TOUCH - Commit to true when there are Unsaved changes
  hasUnsavedChanges: false,
  // TOUCH - Commit array to whitelist specific URLs on a page where you do not want the unsaved changes
  // modal to show when the user navigates to there. Supports regex / wildcards
  unsavedChangesUrlWhitelist: [
    // this.$route.path, // belangrijk omdat anders _terug_ gaan weer een modal triggert
    // '/status/.*/stop-dosage-instruction/new-.*',
  ],
  // DO NOT TOUCH - This one automatically controls the visibility of the UnsavedChangesModal
  showUnsavedChangesModal: false,
  // DO NOT TOUCH - This variable automatically stores the link that the user was navigating too before being stopped and shown the modal
  unsavedChangesModalToLink: '',

  // Als je deze set met een link zal de status pagina naar deze pagina redirecten zodra die geladen is
  // Wordt gebruikt om na de history backs bij het voorschrijven weer forward te gaan en zo de juiste
  // browser history intact te houden
  statusForwardRedirectLink: ''
};

// getters
const getters = {
  activeTableUid(state, getters, rootState) {
    // De laatst toegevoegde is altijd de actieve tabel
    return state.activeTableUids[(state.activeTableUids.length - 1)];
  },
  activeTableUids(state, getters, rootState) {
    // De laatst toegevoegde is altijd de actieve tabel
    return state.activeTableUids;
  },
  availableTableRows(state, getters, rootState) {
    // De laatst toegevoegde is altijd de actieve tabel
    return state.availableTableRows;
  },
  selectedPageNavItemIndex(state, getters, rootState) {
    return Utility.find_index_of_matching_value(state.availablePageNavItems, state.selectedPageNavItemUid);
  },
  selectedOverviewLinkIndex(state, getters, rootState) {
    return Utility.find_index_of_matching_value(state.availableOverviewLinks, state.selectedOverviewLinkUid);
  },
  selectedNavSearchLinkIndex(state, getters, rootState) {
    return Utility.find_index_of_matching_value(state.availableNavSearchLinks, state.selectedNavSearchLinkUid);
  },
  selectedTableRowIndex(state, getters, rootState) {
    return (tableUid) => {
      return Utility.find_index_of_matching_value(state.availableTableRows[tableUid], state.selectedTableRowUid[tableUid]);
    };
  },
  hasUnsavedChanges(state, getters, rootState) {
    return state.hasUnsavedChanges;
  },
  showUnsavedChangesModal(state, getters, rootState) {
    return state.showUnsavedChangesModal;
  },
  unsavedChangesModalToLink(state, getters, rootState) {
    return state.unsavedChangesModalToLink;
  },
  isWhiteListedForUnsavedChanges(state, getters, rootState) {
    return (to) => {
      const url = to.path;
      return state.unsavedChangesUrlWhitelist.some(whitelistedUrlWithRegex => {
        const regex = new RegExp(whitelistedUrlWithRegex);
        return regex.test(url);
      });
    };
  },
  statusForwardRedirectLink(state, getters, rootState) {
    return state.statusForwardRedirectLink;
  }
};

// actions
const actions = {
  checkForUnsavedChanges({state, commit, dispatch, getters, rootState}, to) {
    return new Promise((resolve, reject) => {
      if (state.hasUnsavedChanges && !getters['isWhiteListedForUnsavedChanges'](to)) {
        commit('setUnsavedChangesModalToLink', to.path);
        commit('setShowUnsavedChangesModal', true);
        reject();
      }
      else {
        resolve();
      }
    });
  },
  selectNextTableRow({state, commit, dispatch, getters, rootState}, tableUid) {
    const currentSelectedTableRowIndex = getters.selectedTableRowIndex(tableUid);
    const newIndex = currentSelectedTableRowIndex + 1;
    //  Only move when we can
    if (newIndex < state.availableTableRows[tableUid].length) {
      commit('setSelectedTableRowUid', {
        tableUid: tableUid,
        rowUid: state.availableTableRows[tableUid][newIndex]
      });
    }
  },
  selectPreviousTableRow({state, commit, dispatch, getters, rootState}, tableUid) {
    const currentSelectedTableRowIndex = getters.selectedTableRowIndex(tableUid);
    const newIndex = currentSelectedTableRowIndex - 1;
    //  Only move when we can
    if (newIndex >= 0) {
      commit('setSelectedTableRowUid', {
        tableUid: tableUid,
        rowUid: state.availableTableRows[tableUid][newIndex]
      });
    }
  },
  resetAllSelectedTableRowUids({state, commit, dispatch, getters, rootState}) {
    Object.keys(state.selectedTableRowUid).forEach(tableUid => {
      commit('setSelectedTableRowUid', {
        tableUid: tableUid,
        rowUid: 0,
      });
    });
  },
  clearQueryString({state, commit, dispatch, getters, rootState}, payload) {
    const cleanUrl = window.location.href.replace(payload.query_string, '');
    window.history.replaceState({}, '', cleanUrl);
  },
};

// mutations
const mutations = {

  setShowClusterSelector(state, value) {
    state.showClusterSelector =value;
  },

  /*
   |--------------------------------------------------------------------------
   | Page Nav Items Navigation Settings
   |--------------------------------------------------------------------------
   |
   | These are used to control the navigation of elements across the
   | non overview pages
   |
   */
  removeFromAvailablePageNavItems(state, uidToBeRemoved) {
    const items = state.availablePageNavItems;

    const newItems = Utility.remove_id_from_array(items, uidToBeRemoved);

    state.availablePageNavItems =newItems;
    state.selectedPageNavItemUid =0;
  },
  addToAvailablePageNavItems(state, uid) {
    const pageNavItems = state.availablePageNavItems;
    pageNavItems.push(uid);

    state.availablePageNavItems = pageNavItems;
    state.selectedPageNavItemUid =0;
  },
  resetPageNavItems(state, data) {
    state.availablePageNavItems = [];
  },
  setSelectedPageNavItemsByIndex(state, index) {
    state.selectedPageNavItemUid = state.availablePageNavItems[index];
  },
  setSelectedPageNavItemsUid(state, data) {
    state.selectedPageNavItemUid = data;
  },

  /*
   |--------------------------------------------------------------------------
   | Overview Links Settings
   |--------------------------------------------------------------------------
   |
   | These are used to navigate the overview pages
   |
   |
   */
  setSelectedOverviewLinkByIndex(state, index) {
    state.selectedOverviewLinkUid = state.availableOverviewLinks[index];
  },
  setSelectedOverviewLinkUid(state, data) {
    state.selectedOverviewLinkUid = data;
  },
  addToAvailableOverviewLinks(state, uid) {
    const overviewLinks = state.availableOverviewLinks;
    overviewLinks.push(uid);

    // Sort ascending
    overviewLinks.sort(function (a, b) {
      return a - b;
    });

    state.availableOverviewLinks = overviewLinks;
    state.selectedOverviewLinkUid = overviewLinks[0];
  },
  resetOverviewLinks(state, data) {
    state.availableOverviewLinks = [];
  },


  /*
   |--------------------------------------------------------------------------
   | NavSearch Navigation Settings
   |--------------------------------------------------------------------------
   |
   | These are used to control the navigation in the search bar
   |
   |
   */
  toggleNavSearch(state) {
    state.navSearchToggle = !state.navSearchToggle;
  },
  setNavSearchNavigation(state, value) {
    state.navSearchNavigation = value;
  },
  removeFromAvailableNavSearchLinks(state, uidToBeRemoved) {
    const items = state.availableNavSearchLinks;

    const newItems = Utility.remove_id_from_array(items, uidToBeRemoved);

    state.availableNavSearchLinks = newItems;
    state.selectedNavSearchLinkUid = 0;
  },
  addToAvailableNavSearchLinks(state, uid) {
    const navSearchLinks = state.availableNavSearchLinks;
    navSearchLinks.push(uid);

    state.availableNavSearchLinks = navSearchLinks;
    state.selectedNavSearchLinkUid = 0;
  },
  resetNavSearchLinks(state, data) {
    state.availableOverviewLinks = [];
  },
  setSelectedNavSearchLinkByIndex(state, index) {
    state.selectedNavSearchLinkUid = state.availableNavSearchLinks[index];
  },
  setSelectedNavSearchLinkUid(state, data) {
    state.selectedNavSearchLinkUid = data;
  },


  /*
   |--------------------------------------------------------------------------
   | MedimoTable Navigation settings
   |--------------------------------------------------------------------------
   |
   | Used to nagivate around the Medimo tables and rows
   |
   |
   */
  addToActiveTableUids(state, uid) {
    // We voegen hem aan een soort historie bij, zodat we voorheen actieve tabellen kunnen restoren
    // als een tabel gedestroyed wordt (bijv. door een modal die dicht gaat)
    const activeTableUids = state.activeTableUids;
    activeTableUids.push(uid);
    // En we halen alle dubbele er uit, da's natuurlijk onnodig
    // activeTableUids = utility.remove_duplicates_from_array(activeTableUids);
    state.activeTableUids = activeTableUids;
  },
  removeFromActiveTableUids(state, uid) {
    const items = state.activeTableUids;
    const newItems = Utility.remove_id_from_array(items, uid);

    state.activeTableUids = newItems;
  },
  addToAvailableTables(state, tableUid) {
    const items = state.availableTables;
    items.push(tableUid);

    // Hier voegen we een lege array toe aan de tableRows zodat die gevuld & gebruikt kan worden
    state.availableTableRows[tableUid] = [];
    // By default selecteren we niks, ook dit wordt vanuit de MedimoTrs aangestuurd
    // state.selectedTableRowUid, tableUid, 0);
    state.availableTables = Utility.remove_duplicates_from_array(items);
    state.activeTableUid = tableUid;
  },
  removeFromAvailableTables(state, uidToBeRemoved) {
    const items = state.availableTables;

    const newItems = Utility.remove_id_from_array(items, uidToBeRemoved);

    state.availableTables = newItems;
  },
  /**
   * Set the selected table row to the first available one.
   */
  selectFirstTableRow(state, tableUid) {
    let rowUid = 0;
    // Hij moet defined zijn, anders mag de waarde op 0
    if (typeof state.availableTableRows[tableUid] !== 'undefined') {
      if (state.availableTableRows[tableUid].length) {
        rowUid = state.availableTableRows[tableUid][0];
      }
    }
    state.selectedTableRowUid[tableUid] = rowUid;
  },
  setSelectedTableRowUid(state, payload) {
    state.selectedTableRowUid[payload.tableUid] = payload.rowUid;
  },
  clearAvailableTableRows(state, uid) {
    state.stateavailableTableRows = {};
    state.stateselectedTableRowUid = {};
  },
  addToAvailableTableRows(state, payload) {
    // Iedere MedimoTable heeft z'n eigen array met de Uids van zijn MedimoTrs er in.
    // Deze wordt automatisch gevuld door de MedimoTrs zelf
    let items = state.availableTableRows[payload.tableUid];
    items.push(payload.rowUid);
    items = Utility.remove_duplicates_from_array(items);

    state.availableTableRows[payload.tableUid] = items;
  },
  removeFromAvailableTableRows(state, payload) {
    const items = state.availableTableRows[payload.tableUid];

    const newItems = Utility.remove_id_from_array(items, payload.rowUid);

    state.availableTableRows[payload.tableUid] = newItems;
    state.selectedTableRowUid[payload.tableUid] = 0;
  },
  /**
   * Sets the availableTableRows
   * @param state
   * @param payload array with the UIDs of the availableTableRows
   */
  setAvailableTableRows(state, payload) {
    // Hier slaan we de available table rows op per table UID. Op die manier is er een persistent
    // state van alle MedimoTables hun rows zodat als je na het sluiten van een modal terug komt
    // bij een andere tabel, de rows van die nog gewoon bekend zijn
    state.availableTableRows[payload.tableUid] = Utility.remove_duplicates_from_array(payload.rowUids);
  },
  removeAvailableTableRows(tableUid) {
    delete state.availableTableRows[tableUid];
  },
  triggerSelectedTableRow(state) {
    state.triggerSelectedTableRow = !state.triggerSelectedTableRow;
  },
  setCurrentlyOnOverviewPage(state, value) {
    state.currentlyOnOverviewPage = value;
  },
  setAdminPanel(state, payload) {
    state.adminPanel[payload.property] = payload.value;
  },
  setShowLogo(state, value) {
    state.showLogo = value;
  },
  setShowClusterSwitch(state, value) {
    state.showClusterSwitch = value;
  },
  setHasUnsavedChanges(state, value) {
    state.hasUnsavedChanges = value;
  },
  setShowUnsavedChangesModal(state, value) {
    state.showUnsavedChangesModal = value;
  },
  setUnsavedChangesModalToLink(state, value) {
    state.unsavedChangesModalToLink = value;
  },
  setUnsavedChangesUrlWhitelist(state, value) {
    state.unsavedChangesUrlWhitelist = value;
  },
  setStatusForwardRedirectLink(state, value) {
    state.statusForwardRedirectLink = value;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
