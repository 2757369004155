import {HealthcareProfessionalObject} from '@/domain/models/HealthcareProfessionalObject';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedHealthcareProfessionalObject { 
  public text: string;
  public firstName: string;
  public lastName: string;
  public specialism: CodeObject | null;

  constructor(text: string, firstName: string, lastName: string, specialism: CodeObject | null = null) {
    this.text = text;
    this.firstName = firstName;
    this.lastName = lastName;
    this.specialism = specialism;
  }

  public toJSON(): unknown {
    return {
      text: this.text,
      firstName: this.firstName,
      lastName: this.lastName,
      specialism: this.specialism,
    };
  }

  public static fromJson(json: unknown): HealthcareProfessionalObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new HealthcareProfessionalObject(
      parsedJson['text'] as string,
      parsedJson['firstName'] as string,
      parsedJson['lastName'] as string,
      parsedJson['specialism'] ? CodeObject.fromJson(parsedJson['specialism']) : null,
    );
  }

  public static multipleFromJson(json: unknown): HealthcareProfessionalObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
