<template>
  <div style="position:relative;"
       :class="'medimo-input ' + (validation_class.length ? 'form-control ' + validation_class : '') + ' ' + (sub_text.length ? 'with-subtext' : '')">
    <input :class="'form-control ' + input_class"
           ref="input"
           :maxlength="maxlength"
           :style="extra_styles"
           :type="type"
           :value="modelValue"
           @blur="onBlur($event)"
           @focus="onFocus"
           @keyup="onKeyEvent('keyup', $event)"
           @keydown="onKeyEvent('keydown', $event)"
           :name="name"
           :placeholder="placeholder"
           :disabled="disabled"
           autocomplete="off"
           @input="onInput"
           :min="min"
           :max="max"
           :step="step"
           :title="placeHolderAsTitle ? placeholder : ''"
           @click="emit('click')"
    >
    <MedimoTooltip v-if="help_text.length" :content="help_text">
      <FaIcon icon="fa-solid fa-circle-question" class="text-primary" size="lg" />
    </MedimoTooltip>
    <span class="text-small text-muted" v-if="hasSubText">{{ sub_text }}</span>
  </div>
</template>

<script setup lang="ts">
import MedimoTooltip from '@/vue/components/general/MedimoTooltip.vue';
import {computed, nextTick, ref, watch, getCurrentInstance} from 'vue';
import {onMounted} from "@vue/runtime-core";

const emit = defineEmits(['keyup', 'keydown', 'focus', 'blur', 'update:modelValue', 'keydown_backspace', 'keydown_tab', 'keydown_enter', 'keydown_left', 'keydown_up', 'keydown_right', 'keydown_down', 'keydown_period', 'keyup_backspace', 'keyup_tab', 'keyup_enter', 'keyup_left', 'keyup_up', 'keyup_right', 'keyup_down', 'keyup_period', 'click']);

interface Props {
  modelValue?: string|number|null, // Dit is in Vue3 de nieuwe value
  name?: string,
  label?: string,
  disabled?: boolean,
  placeholder?: string,
  extra_classes?: string,
  validation_class?: string,
  type?: string,
  extra_styles?: string,
  help_text?: string,
  sub_text?: string,
  hide_zero?: boolean,
  clear_on_click?: boolean,
  input_class?: string,
  input_id?: string,
  focus?: boolean,
  maxlength?: string,
  forceUpdate?: boolean,
  placeHolderAsTitle?: boolean,
  min?: number,
  max?: number,
  step?: number,
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  name: '',
  label: '',
  disabled: false,
  placeholder: '',
  extra_classes: '',
  validation_class: '',
  type: 'text',
  extra_styles: '',
  help_text: '',
  sub_text: '',
  // This automatically hides the zero when clicking on the input, and it has a 0
  hide_zero: false,
  // Clears the input when a users clicks on it
  clear_on_click: false,
  // Use this to give the input a special class or ID, handy for testing
  input_class: '',
  input_id: '',
  // Attach to a boolean value to have the input be focused when switched to true
  focus: false,
  maxlength: '',
  // Zet deze op FALSE om de force update te disablen. In het geval van een ranged number input
  // of andere waarden waar die waarde met :value wordt ingevoerd kan dat wenselijk zijn
  forceUpdate: true,

  placeHolderAsTitle: false,
});

const instance = getCurrentInstance();
const temporaryValue = ref<string|number|null>('');
const input = ref();

// Needed to have a custom event translator instead of defining both the keyCode and human type
// Backspace and Period arent natively supported by Vue, so this covers all bases
const keys: {[keyCode: number]: string} = {
  8: 'backspace',
  9: 'tab',
  13: 'enter',
  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',
  190: 'period',
};

const hasSubText = computed<boolean>(() => {
  return props.sub_text.length > 0;
});

onMounted(() => {
  if (props.focus) {
    setFocus();
  }
  nextTick(() => {
    // This updates after local store updates. Don't know how to fix this properly
    setTimeout(() => {
      instance?.proxy?.$forceUpdate();
    }, 100);
  });
});

function onInput(event: Event) {
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.value);
}

function onBlur(event: Event) {
  const target = event.target as HTMLInputElement;
  // When hide_zero or clear_on_click is enabled we want to set the value again when the user input was empty
  if (props.hide_zero || props.clear_on_click) {
    const oldValue = props.hide_zero ? '0' : temporaryValue.value;

    setTimeout(function () {
      if (!props.modelValue) {
        //Do not reset if the input is still active:
        const activeElement = document.activeElement;
        if (input.value && activeElement !== input.value) {
          emit('update:modelValue', oldValue);
        }
      }
    }, 1);
  }
  // Emit @blur regardless
  emit('blur', target.value);

  // Onderstaand is nodig om eventuele upstream updates van de :value goed te tonen.
  // Dit _zou_ in theorie niet nodig moeten zijn als die chain goed in elkaar zit, maar het is net zo praktisch dat hier
  // af te vangen. Aangezien het een @blur is is het een veilige aanname dat de gebruiker "klaar" is met dit element, en we
  // dus een update kunnen forcen, en daarmee de input value rerenderen.
  //
  // Een situatie waarbij dit mis kan gaan is als een waarde upstream ge-transformed wordt qua input.
  // Dan wordt deze upstream hetzelfde geacht waardoor de reactive update downstream uit blijft, en de input niet geupdate wordt.
  // Dit is bijv. het geval van tijd, dat 22 upstream identiek is aan 22:00, dus niet veranderd, en dus "hier" de 22 blijft staan
  if (props.forceUpdate) {
    setTimeout(() => {
      instance?.proxy?.$forceUpdate();
    }, 1);
  }
}

function onFocus() {
  temporaryValue.value = props.modelValue;

  if (props.hide_zero) {
    if (props.modelValue !== '0.' && parseFloat(String(props.modelValue).replace(",", ".")) === 0) {
      emit('update:modelValue', '');
    }
  } else if (props.clear_on_click) {
    emit('update:modelValue', '');
  }

  // Always emit the focus
  emit('focus');
}

function onKeyEvent(type: 'keyup'|'keydown', event: KeyboardEvent) {
  if (typeof keys[event.keyCode] !== 'undefined') {
    const eventName = type + '_' + keys[event.keyCode] as 'keyup_backspace'|'keyup_tab'|'keyup_enter'|'keyup_left'|'keyup_up'|'keyup_right'|'keyup_down'|'keyup_period'|'keydown_backspace'|'keydown_tab'|'keydown_enter'|'keydown_left'|'keydown_up'|'keydown_right'|'keydown_down'|'keydown_period';
    emit(eventName, event);
  }
  else {
    // Any keyup
    emit(type, event); // keyup of keydown
  }
}

function setFocus() {
  nextTick(() => {
    input.value.focus();
  });
}

watch(() => props.focus, (value) => {
  if (value) {
    setFocus();
  }
});
</script>
