import {QuantityObject} from '@/domain/models/QuantityObject';
import {CodeObject} from '@/domain/models/CodeObject';
import Utility from '@/vue/utility/utility';

export class GeneratedQuantityObject { 
  public value: number | null;
  public code: CodeObject | null;

  constructor(value: number | null = null, code: CodeObject | null = null) {
    this.value = value;
    this.code = code;
  }

  public toJSON(): unknown {
    return {
      value: this.value,
      code: this.code,
    };
  }

  public static fromJson(json: unknown): QuantityObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new QuantityObject(
      parsedJson['value'] as number,
      parsedJson['code'] ? CodeObject.fromJson(parsedJson['code']) : null,
    );
  }

  public static multipleFromJson(json: unknown): QuantityObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }

  public static fromObject(object: QuantityObject | null | undefined): QuantityObject {
    const clonedObject = Utility.deep_clone(object);
    return new QuantityObject(
      clonedObject?.value ?? null as number | null,
      clonedObject?.code ? CodeObject.fromObject(clonedObject.code) : null,
    );
  }
}
