import {GeneratedMedicationUse} from '@/domain/models/generated/GeneratedMedicationUse';
import SnomedCt from "@/enums/SnomedCt";

export class MedicationUse extends GeneratedMedicationUse {
  public author(): string {
    if (this.authorProfessional) {
      return this.authorProfessional.professionalObject.fullName();
    }

    if (this.authorProvider) {
      return this.authorProvider.providerSpecification.organizationName;
    }

    if (this.authorIsPatient) {
      return 'Patient';
    }

    return '-';
  }

  public informant(): string {
    if (this.informantHealthCareProfessional) {
      return this.informantHealthCareProfessional.professionalObject.fullName();
    }

    if (this.informantContactPerson) {
      return this.informantContactPerson;
    }

    if (this.informantIsPatient) {
      return 'Patient';
    }

    return '-';
  }

  public hasBeenTemporarilyStopped(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Suspended;
  }
}
