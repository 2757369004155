<template>
  <MedimoExternalDataTable
      class="mt-3"
      ref="externalDataTable"
      :store_name="storeName"
      :privacy_filter="false"
      :search_on_load="true"
      :minimum_height="true"
      :name="name"
      :refresh_table="refresh"
      :search_on_route_change="search_on_route_change"
  >
    <MedimoThead>
      <MedimoTr>
        <MedimoTh v-for="columnName in Object.keys(columns)" :column_classes="columnClasses[columnName] ?? 'col'" :sort_column_1="columnName" :store_name="storeName">
          {{ columns[columnName] }}
        </MedimoTh>
      </MedimoTr>
    </MedimoThead>
    <MedimoTbody>
      <MedimoTargetSelectorTr
          :navigateHard="navigateHard"
          :columns="columns"
          :column-classes="columnClasses"
          :navigateOnClick="navigateOnClick"
          :navigateToUrl="navigateToUrl"
          :target="target"
          v-for="target in externalDataTable?.records"
          @triggered="emit('triggered', target)"/>
    </MedimoTbody>
  </MedimoExternalDataTable>
</template>

<script setup lang="ts">
import {onBeforeMount, ref, computed, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from "vue-router";
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTargetSelectorTr from "@/vue/components/selectors/targetselector/MedimoTargetSelectorTr.vue";

const store = useStore();
const route = useRoute();
const externalDataTable = ref();
const emit = defineEmits(['triggered']);
const refresh = ref<number>(0);

onBeforeMount(() => {
  // clear old data first
  store.commit(props.storeName + '/clear_params');
  store.commit(props.storeName + '/clear_search_results');
  store.commit(props.storeName + '/clear_search_meta');
  store.commit(props.storeName + '/clear_sort_columns');

  // set new data
  store.commit(props.storeName + '/set_search_endpoint', props.searchEndpoint);

  store.commit(props.storeName + '/set_param', {
    name: 'datatables',
    value: 1,
  });
  Object.keys(route.query).forEach(queryName => {
    store.commit(props.storeName + '/set_param', {
      name: queryName,
      value: route.query[queryName],
    });
  });

  // sort columns
  const sortColumn = props.sortColumn === undefined ? (Array.isArray(props.columns) ? props.columns[0] : Object.keys(props.columns)[0]) : props.sortColumn;
  const sortDirection = props.sortDirection === undefined ? (sortColumn?.endsWith('id') ? 'DESC' : 'ASC') : props.sortDirection;
  store.commit(props.storeName + '/set_state', {
    property: 'sort_columns',
    value: [{column_name: sortColumn, direction: sortDirection}],
  });
});

interface Props {
  searchEndpoint: string,
  search_on_route_change?: boolean,
  columns: { [key: string]: string },
  columnClasses?: { [key: string]: string },
  navigateHard?: boolean,
  sortColumn?: string | null,
  sortDirection?: 'ASC' | 'DESC' | null,
  navigateOnClick?: boolean,
  navigateToUrl?: Function,
  name ?: string|null,
  storeName ?: string
}

const props = withDefaults(defineProps<Props>(), {
  navigateHard: false,
  columnClasses: () => ({}),
  navigateOnClick: true,
  search_on_route_change: true,
  name: null,
  storeName: 'selectors/target_selector',
});

const name = computed<string>(() => {
  return props.name ? props.name : 'entityTargetSelector' + props.searchEndpoint;
});

/*
 |--------------------------------------------------------------------------
 | Datatable related
 |--------------------------------------------------------------------------
 */

defineExpose({
  storeName: props.storeName,
});

watch(() => props.searchEndpoint, (value) => {
  store.commit(props.storeName + '/set_search_endpoint', value);
  refresh.value++;
});

</script>
