import {CodeObject} from '@/domain/models/CodeObject';
import {CodeSystem} from '@/domain/models/CodeSystem';
import Utility from '@/vue/utility/utility';

export class GeneratedCodeObject { 
  public system: CodeSystem | null;
  public code: string;
  public display: string | null;

  constructor(system: CodeSystem | null = null, code: string, display: string | null = null) {
    this.system = system;
    this.code = code;
    this.display = display;
  }

  public toJSON(): unknown {
    return {
      system: this.system,
      code: this.code,
      display: this.display,
    };
  }

  public static fromJson(json: unknown): CodeObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new CodeObject(
      parsedJson['system'] ? CodeSystem.fromJson(parsedJson['system']) : null,
      parsedJson['code'] as string,
      parsedJson['display'] as string,
    );
  }

  public static multipleFromJson(json: unknown): CodeObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }

  public static fromObject(object: CodeObject | null | undefined): CodeObject {
    const clonedObject = Utility.deep_clone(object);
    return new CodeObject(
      clonedObject?.system ? CodeSystem.fromObject(clonedObject.system) : null,
      clonedObject.code as string,
      clonedObject?.display ?? null as string | null,
    );
  }
}
