import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  quick_filters: {
    scope: {
      mustBeActive: true,
      group: {
        local : {label: 'Lokaal', value: true},
        global : {label: 'Landelijk', value: false}
      }
    }
  },
  useLocalQuickFilters: true,
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
