// initial state
// shape: [{ id, quantity }]
import moment from 'moment-mini';
import Utility from "@/vue/utility/utility";
import BaseModule from "@/vue/store/modules/base_module";
import * as Sentry from "@sentry/vue";
import {ManualVueSentry} from "@/vue/utility/errors";

const state = {
  data: {
    permissions: [],
    cluster_permissions: {
      webservices: {},
    },
    clusters: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 10,
        total: 0,
      }
    },
    news: {
      items: [],
      count: 0
    },
    language: {},
    app_url: '',
    status_table_view: 0,
    mp9_status_table_view: 1,
    user_permissions_table_view: 0,
    full_authenticated: false,
    otp_wall_raised: false,
    latest_session_login: null,
    use_cluster_selection: false,
  },
  isLoaded: false,
  waarneming_quick_filter_setting: false,

  // Keeps track of the popup dialogs that the user has opened.
  // Formatted as follows:
  processed_action_dialogs: {
    //   'action_id': {
    //     'timestamp': '2020-01-01 10:00'
    //   }
  },
  // Met deze waarde houden we bij hoe vaak de gebruiker deze sessie de notifications
  // dropdown gesloten heeft. Als dit boven de X uit komt, markeren we alle berichten als gelezen
  medimo_notifications_dropdown_closed_x_times: 0,
  // Voor het Helpdesk / Admin panel. Deze data is ook persisted
  current_cluster_info: {
    id: null,
    name: '',
    customer: {
      id: null,
      naam: null,
      afspraken: []
    },
    tickets: [],
  },
  // Keeps track of the mbo medguard reviews that has been skipped.
  // Formatted as follows:
  skipped_signals_review_for_patient: {
    //   'patient_id': {
    //     'timestamp': '2020-01-01 10:00'
    //   }
  },
  // Shaken Status switch table view button
  show_status_patient_selector_tooltip: true,
  show_status_switch_table_tooltip: true,
  // Shaken AdvancedFilters button
  show_advanced_filters_tooltip: true,
  show_all_mutations: false,

  status_views: [
    'schema',
    'dosing_instructions',
    'poliklinisch',
    'bijzonderheden'
  ],
  mp9_status_views: [
    'schema',
    'dosing_instructions',
    // 'poliklinisch',
    'bijzonderheden'
  ],
};

// getters
const getters = {
  /**
   * Checks the permissions of the current user, returns if they're a match
   *
   * @param state
   * @param getters
   * @param rootState
   * @returns {boolean}
   */
  canAny(state, getters, rootState) {

    return (canDo) => state.data.permissions.filter((permission) => {

      if (typeof canDo === 'string') {
        canDo = [canDo];
      }
      return canDo.includes(permission);

    }).length > 0;
  },
  // Deze komt dan overeen met de Backend definitie
  hasPermissionsFor(state,getters,rootState) {
    return (type) => {
      if (type === 'NON_MEDICAL') {
        return getters['canAny'](['viewPatientData', 'editPatientBasicInfo', 'editPatientAssignments', 'editPatientTemporaryAbsences']);
      }
      if (type === 'REPORT_STATISTICS') {
        return getters['canAny'](['overviewStatistics']);
      }
      if (type === 'REPORT_PATIENTS') {
        return getters['canAny'](['overviewPatientInformation']);
      }
      if (type === 'REPORT_DISPENSES') {
        return getters['canAny'](['dispenseMedications']);
      }
      if (type === 'REPORT_EPISODES') {
        return getters['canAny'](['showEpisodes', 'editEpisodes']);
      }
      if (type === 'REPORT_DRUG_REQUESTS') {
        return getters['canAny'](['requestRefill']);
      }
      if (type === 'REPORT_EMERGENCY_REQUESTS') {
        return getters['canAny'](['requestEmergencySupply', 'requestEmergencySupplyGeneric']);
      }
      if (type === 'REPORT_TDR') {
        return getters['canAny'](['overviewMedicationInformation']);
      }
      if (type === 'REPORT_MEDICATIONS') {
        return getters['canAny'](['overviewMedicationInformation']);
      }
      if (type === 'REPORT_PATIENTDOSSIER') {
        return getters['canAny'](['viewPrescriptions']);
      }
      if (type === 'SHOW_ANY_READINGS') {
        return getters['canAny'](['showLabresultsAll', 'showLabresultsPharmacy']);
      }
    };
  },
  has_processed_action_dialog(state, getters, rootState, rootGetters) {
    return (action_id) => {
      if (typeof state.processed_action_dialogs[action_id] === 'undefined') {
        return false;
      }

      // We don't show the dialog for at least 30 minutes.
      const timeToShowDialogAgain = moment().subtract(30, 'minutes');
      const lastProcessed = moment(state.processed_action_dialogs[action_id].timestamp);

      return lastProcessed.isAfter(timeToShowDialogAgain);
    };
  },

  has_skipped_signals_review_for_patient(state, getters, rootState, rootGetters) {
    return (patient_id) => {
      return typeof state.skipped_signals_review_for_patient[patient_id] !== 'undefined';
    };
  },

  language(state, getters, rootState, rootGetters) {
    return state.data.language;
  },
  data(state, getters, rootState, rootGetters) {
    return state.data;
  },
  show_all_mutations(state, getters, rootState, rootGetters) {
    return state.data.show_all_mutations;
  },
  current_cluster_info(state, getters, rootState, rootGetters) {
    return state.current_cluster_info;
  },
  logoutUrl(state, getters, rootState, rootGetters) {
    return state.logoutUrl;
  },
  user_permissions_table_view_string(state, getters, rootState, rootGetters) {
    const views = [
      'flat',
      'two_tables',
      'combined'
    ];
    return views[state.data.user_permissions_table_view % views.length];
  },
  user_permissions_table_view(state, getters, rootState, rootGetters) {
    return state.data.user_permissions_table_view;
  },
  status_table_view_string(state, getters, rootState, rootGetters) {
    return state.status_views[state.data.status_table_view % state.status_views.length];
  },
  status_table_view(state, getters, rootState, rootGetters) {
    return state.data.status_table_view;
  },
  status_table_views(state, getters, rootState, rootGetters) {
    return state.status_views;
  },
  mp9_status_table_view_string(state, getters, rootState, rootGetters) {
    return state.mp9_status_views[state.data.mp9_status_table_view % state.mp9_status_views.length];
  },
  mp9_status_table_view(state, getters, rootState, rootGetters) {
    return state.data.mp9_status_table_view;
  },
  mp9_status_table_views(state, getters, rootState, rootGetters) {
    return state.mp9_status_views;
  },
  waarneming_quick_filter_setting(state, getters, rootState, rootGetters) {
    return state.waarneming_quick_filter_setting;
  },
};

// actions
const actions = {
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/users/current';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    }).catch(error => {
      // Niks aan de hand. Mochten er HTTP errors zijn toont de noty een fout
    });
  },
  async otpwall_check({state, commit, dispatch, getters, rootState, rootGetters}, return_url) {
    // Prevent raising wall more than once, because of beforeRouteEnter calling otpwall_check mostly.
    if (state.data.full_authenticated) {
      return false; // All ok, OTP wall is NOT showing.
    } else if (state.data.otp_wall_raised || window.otp_wall_raised) {
      return true; // OTP wall is showing or will show within a sec.
    }

    // We werken hier nu met een backend flow dmv de AuthController en OtpController
    // We activeren de OtpWall door een call te doen naar de backend,
    // deze raised de wall, past de otp_wall_raised waarde aan voor de user
    // We gebruiken otp_wall_raised om te voorkomen dat de wall meerdere keren ge-raised
    // wordt vanwege beforeRouteEnter. Door vervolgens een harde redirectTo te doen in de frontend,
    // gaan we over de CheckForOtpWall middleware en is vanaf dat moment de OtpWall login
    // altijd zichtbaar, en niet meer te omzeilen voor de gebruiker.
    // Pas nadat de gebruiker met success door validateHardwareCode() of validateMessageCode()
    // heen is, wordt otp_wall_raised in de backend weer op false gezet
    state.data.otp_wall_raised = true;
    window.otp_wall_raised = true;
    commit('set_data', state.data);

    if (!return_url) {
      return_url = rootGetters['routes/current_route'];
    }

    // Check if user is fully authenticated, otherwise redirect to otp wall two factor authentication
    // Wanneer niet fully authenticated, en geen wall, dan raisen we hem door endpoint aan te halen.
    try {
      const response = await dispatch('api/postEndpoint', {
        endpoint: '/otp-wall/activate',
        data: {
          redirectTo: return_url,
        }
      }, {root: true});
      // Show OTP wall by redirecting hard so the OTP wall middleware will kick in.
      // It takes a little time before the redirect kills the js runtime after this..
      window.location.href = response.data.redirectTo;
    } catch (error) {
      // manually send sentry just to rule out errors during building the sentry
      const scope = new Sentry.Scope();
      scope.setTag('logger', 'javascript');
      scope.setTag('framework', 'vue');
      try {
        scope.setUser(window.app?.$store?.state?.current_user?.data);
        scope.setExtra('Medimo Breadcrumbs', window.app?.$store?.state?.settings?.breadcrumbs?.data);
        scope.setExtra('Medimo Sentry Store Data', window.app?.$store?.state?.sentry?.data);
      } catch (e) {
        scope.setExtra('setScope error', e.message);
      }
      scope.setExtra('log', JSON.stringify(window.medimo.debugOtp));
      const message = 'Vue: OTP-wall already raised: ' + (new Date()).toISOString();
      Sentry.captureException(new ManualVueSentry(new Error('Vue: OTP-wall already raised'), message), scope);

      state.data.otp_wall_raised = false;
      window.otp_wall_raised = false;
      commit('set_data', state.data);
    }

    return true; // OTP wall will show.
  },
  eraseTdrInitials({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/users/current/tdr-initials';

    return dispatch('api/deleteEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a DELETE
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
      dispatch('notifications/addSuccessNotification', {message: 'Gebruikerscode verwijderd'}, {root: true});
    });
  },
  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/users/current';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  update_parameters({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/users/current/parameters';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  update_session({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/users/current';

    commit('updateData', Utility.clone(payload.data));

    payload.data.session_only = true;
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  fetch_current_cluster_info({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/clusters/current/info';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}, // Empty, since it's a GET
      rejectErrors: true,
    }, {root: true}).then(response => {
      commit('set_current_cluster_info', response.data.data);
    }).catch(() => {
      // Als hij errors krijgt, tonen we deze data in het paneel
      commit('set_current_cluster_info', {
        id: 0,
        name: 'Error bij ophalen',
        customer: {afspraken: '', 'naam': 'Error bij ophalen'},
        fabbers: [],
        tickets: [],
        koppelingen: [],
      });
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  updateData(state, data) {
    Object.keys(data).forEach(property => {
      state.data[property] = data[property];
    });
  },
  set_data(state, data) {
    state.data = data;
    state.isLoaded = true;
  },
  set_show_all_mutations(state, payload) {
    state.data.show_all_mutations =  payload;
  },
  set_medimo_notifications_dropdown_closed_x_times(state, amount) {
    state.medimo_notifications_dropdown_closed_x_times =  amount;
  },
  set_processed_action_dialog(state, action) {
    state.processed_action_dialogs[action.id] = {
      timestamp: moment.now(),
    };
  },
  set_skipped_signals_review_for_patient(state, patient) {
    state.skipped_signals_review_for_patient[patient.id] = {
      timestamp: moment.now(),
    };
  },
  set_current_cluster_info(state, cluster_info) {
    state.current_cluster_info = cluster_info;
  },
  switch_user_permissions_table_view(state) {
    state.user_permissions_table_view = (state.user_permissions_table_view + 1);
  },
  set_show_status_switch_table_tooltip(state, value) {
    state.show_status_switch_table_tooltip = value;
  },
  set_show_status_patient_selector_tooltip(state, value) {
    state.show_status_patient_selector_tooltip = value;
  },
  set_show_advanced_filters_tooltip(state, value) {
    state.show_advanced_filters_tooltip = value;
  },
  set_waarneming_quick_filter_setting(state, value) {
    state.waarneming_quick_filter_setting = value;
  },
  setLogoutUrl(state, value) {
    state.logoutUrl = value;
  },
  sendOtpSentry(state, value) {
    // Empty on purpose.
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
