import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedPharmaceuticalProduct { 
  public id: number;
  public hpk: number;
  public gpk: number;
  public prk: number;
  public zi: number;
  public description: string;
  public isMagistralPreparation: boolean;
  public isNotTaxeProduct: boolean;
  public productCode: CodeObject[];
  public drugId: number | null;
  public prescribeReasonObliged: boolean;

  constructor(id: number, hpk: number, gpk: number, prk: number, zi: number, description: string, isMagistralPreparation: boolean, isNotTaxeProduct: boolean, productCode: CodeObject[], drugId: number | null = null, prescribeReasonObliged: boolean) {
    this.id = id;
    this.hpk = hpk;
    this.gpk = gpk;
    this.prk = prk;
    this.zi = zi;
    this.description = description;
    this.isMagistralPreparation = isMagistralPreparation;
    this.isNotTaxeProduct = isNotTaxeProduct;
    this.productCode = productCode;
    this.drugId = drugId;
    this.prescribeReasonObliged = prescribeReasonObliged;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      hpk: this.hpk,
      gpk: this.gpk,
      prk: this.prk,
      zi: this.zi,
      description: this.description,
      isMagistralPreparation: this.isMagistralPreparation,
      isNotTaxeProduct: this.isNotTaxeProduct,
      productCode: this.productCode,
      drugId: this.drugId,
      prescribeReasonObliged: this.prescribeReasonObliged,
    };
  }

  public static fromJson(json: unknown): PharmaceuticalProduct {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new PharmaceuticalProduct(
      parsedJson['id'] as number,
      parsedJson['hpk'] as number,
      parsedJson['gpk'] as number,
      parsedJson['prk'] as number,
      parsedJson['zi'] as number,
      parsedJson['description'] as string,
      parsedJson['isMagistralPreparation'] as boolean,
      parsedJson['isNotTaxeProduct'] as boolean,
      parsedJson['productCode'] ? CodeObject.multipleFromJson(parsedJson['productCode']) : [],
      parsedJson['drugId'] as number,
      parsedJson['prescribeReasonObliged'] as boolean,
    );
  }

  public static multipleFromJson(json: unknown): PharmaceuticalProduct[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
