import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  locationName: '',
  data: [],
  search_results: [],
  single: null,
  search_columns: ['name'],
  sort_columns: [{column_name: 'name', direction: 'ASC'}],
};

const getters = {
  ...BaseModule.getters
};

const actions = {
  ...BaseModule.actions,
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/protocols/single/' + payload.id + '?entityId=' + payload.entityId + '&entityType=' + payload.entityType,
    }, {root: true}).then(response => {
      commit('set_state', {property: 'single', value: response.data.data});
    });
  },
  start({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/protocols/start/' + payload.id,
      data: {
        patientIds: payload.patientIds,
      }
    }, {root: true});
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
