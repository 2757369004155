<template>
    <div>
        <medimo-form-column column_classes="col-12 p-0">
          <medimo-form-row margin_classes="mt-0 mb-1" class="medicatie-bewaking" id="medicatie-bewaking">
            <medimo-form-column class="px-2" column_classes="col-12">
              <strong>Medicatiebewaking</strong>
            </medimo-form-column>
            <medimo-form-column column_classes="col-12">
              <medimo-loader-overlay transition="" :loading="loading" size="32" class="medimo-guard-is-loading"></medimo-loader-overlay>
              <medimo-form-row>
                <medication-guard-signal
                    v-for="(signal, key, index) in orderedSignals"
                    :key="signal.uniqueHash"
                                                 :show_dosing="show_dosing"
                                                 :schema_view="schema_view"
                                                 :signal="signal"
                                                 :isMutationMedicationSignal="isMutationMedicationSignal"
                                                 :examine="examine"
                                                 :forProvisionals="forProvisionals"
                        ></medication-guard-signal>
                        <medication-guard-signal v-if="orderedSignals.length === 0"
                                                 :hasInteractions="false"
                            :signal="{title: 'Geen medicatiebewakingssignalen gevonden', type:'ExtraNotice'}">
                        </medication-guard-signal>
                    </medimo-form-row>
                </medimo-form-column>
            </medimo-form-row>
        </medimo-form-column>
    </div>
</template>

<script>
import MedicationGuardSignal from '/vue/components/status/medication-guard/MedicationGuardSignal';
import MedimoLoaderOverlay from "/vue/components/general/MedimoLoaderOverlay";
import MedimoLoader from "/vue/components/general/MedimoLoader";
import MedimoFormRow from "/vue/components/general/form/base/MedimoFormRow";
import MedimoFormColumn from "/vue/components/general/form/base/MedimoFormColumn";

export default {
  components: {
    MedicationGuardSignal,
    MedimoLoaderOverlay,
    MedimoLoader,
    MedimoFormRow,
    MedimoFormColumn,
  },

  props: {
    'loading': {default: false},
    'signals': {
      type: Array,
      default: () => []
    },
    'examine': {default: false},
    'show_dosing': {default: false},
    'schema_view': {default: false},
    'isMutationMedicationSignal': {default: false},
    'forProvisionals': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    orderedSignals: function () {
      return this.signals.sort(function (a, b) {
        if (a.order == b.order) {
          if (a.type.toUpperCase() > b.type.toUpperCase()) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return a.order - b.order;
        }
      });
    },
  },
};
</script>

<style lang="scss">
    .medicatie-bewaking {
        .title {
            width: 100%;
        }
    }
</style>
