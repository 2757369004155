import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/actions/active-actions',
  quick_filters: {
    'validUntilFilter': {
      'mustBeActive': true,
      'group': {
        'vandaag': false,
        '7 dagen': true,
        '30 dagen': false,
        'alle acties': false,
      }
    },
    'patientFilter': {
      'mustBeActive': true,
      'group': {
        'eigen patient_plural': true,
        'waarneming': false,
      }
    },
  },
  toggleQuickFilters: true,
  sort_columns: [{column_name: 'validUntil', direction: 'ASC'}],
  default_sort_columns: [{column_name: 'validUntil', direction: 'ASC'}],
  search_columns: [
    'action.name',
    'action.validUntil|dutch_date',
    'patient.naam',
    'patient.kamer',
    'ward.naam',
    'location.naam',
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
