import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  data: [],
  sort_columns: [
    {column_name: 'name', direction: 'ASC'}
  ],
  settings: [],
};

const getters = {
  ...BaseModule.getters,
};

const actions = {
  ...BaseModule.actions,
};

const mutations = {
  ...BaseModule.mutations,
  set_settings(state, payload) {
    state.settings = payload;
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions: actions,
  mutations,
};
