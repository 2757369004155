import BaseModule from '@/vue/store/modules/base_module';
import Utility from '@/vue/utility/utility';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
  // dummy_variable2: null

  // We use these to keep track of the notifications, delete them when necessary etc.
  // Like a DB id.
  notification_id: 0,

  // The temporary notifications shown above all at the top of the screen.
  alerts: [
    // {
    //     type: 'danger',
    //     visible_for: 900000,
    //     title: 'Alert Titel',
    // },
    // {
    //     type: 'warning',
    //     visible_for: 900000,
    //     title: 'Alert Titel',
    // },
    // {
    //     type: 'success',
    //     visible_for: 900000,
    //     title: 'Alert Titel',
    //     message: 'Optioneel bericht',
    // },
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
  alerts(state, getters, rootState) {
    return state.alerts;
  }
};

// actions
const actions = {
  /**
     *
     * Notifications
     * Check /examples/components/notifications for usage examples
     */
  /**
     * @param state
     * @param commit
     * @param dispatch
     * @param getters
     * @param rootState
     *
     * @param payload Object Needs to have a 'message' property, the rest is optional
     */
  addWarningNotification({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'warning';
    dispatch('add_notification', payload);

  },
  addDangerNotification ({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'danger';
    dispatch('add_notification', payload);

  },
  addSuccessNotification ({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'success';
    dispatch('add_notification', payload);

  },
  addInfoNotification ({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'info';
    dispatch('add_notification', payload);
  },

  /**
     *
     * Alerts
     * Check /examples/components/alerts for usage examples
     *
     */
  addWarningAlert({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'warning';
    dispatch('add_alert', payload);

  },
  addDangerAlert ({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'danger';
    dispatch('add_alert', payload);

  },
  addSuccessAlert ({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'success';
    dispatch('add_alert', payload);

  },
  addInfoAlert ({state, commit, dispatch, getters, rootState}, payload) {
    payload.type = 'info';
    dispatch('add_alert', payload);

  },
  add_notification({state, commit, dispatch, getters, rootState}, payload) {
    payload.state_key = 'data';
    if (typeof payload.new === 'undefined') {
      payload.new = true;
    }
    commit('add_notification', payload);
  },
  add_alert({state, commit, dispatch, getters, rootState}, payload) {
    payload.state_key = 'alerts';
    commit('add_notification', payload);
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  add_notification(state, payload) {
    // Set the default time to show the notification to 10 seconds
    if (typeof payload.visible_for === 'undefined' || payload.visible_for === null) {
      payload.visible_for = 10000;
    }

    if (typeof payload.href === 'undefined' || payload.href === null) {
      payload.href = '';
    }

    const notifications = state[payload.state_key];
    
    // Dubbele notificaties hoeven we niet te tonen, dus we zoeken hier naar een match, is die er, dan pushen we niet
    const matchingNotifications = notifications.filter(notification => {
      return notification.title + notification.message + notification.type === payload.title + payload.message + payload.type;
    })

    if (matchingNotifications.length === 0) {
      notifications.push({
        id: state.notification_id,
        new: payload.new, // For the notifications, will trigger the "bell"
        title: payload.title,
        message: payload.message,
        type: payload.type,
        href: payload.href,
        visible_for: payload.visible_for,
        timestamp: Date.now(),
      });
    }

    // Here we set either the data (notifications) or alerts (notys)
    state[payload.state_key] = notifications;

    state.notification_id++;
  },
  delete_alert(state, alert_id) {
    const alerts = state.alerts;
    const index = Utility.find_index_of_matching_element(state.alerts, 'id', alert_id);
    alerts.splice(index, 1);
    state.alerts = alerts;
  },
  delete_notification(state, notification_id) {
    const notifications = state.data;
    const index = Utility.find_index_of_matching_element(state.data, 'id', notification_id);
    notifications.splice(index, 1);
    state.data = notifications;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
