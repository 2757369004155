import Utility from '@/vue/utility/utility';
import Times from '@/vue/utility/times';
import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

// initial state
// shape: [{ id, quantity }]
const state = {
  store_namespace: 'manual_dosing_schemas',

  data: [],
  shadow_data: [],
  db_data: [],
  lastTempId: 0,
};

// getters
const getters = {
  get_stop_datetime(state, getters, rootState, rootGetters) {
    return (dosageInstructionId) => {
      const shadowModel = getters['find_shadow_model'](dosageInstructionId);
      let dates = shadowModel.dates;
      //sort dates ascending
      dates = dates.sort(function(a, b) {return a>b;});
      return moment(dates[dates.length-1], 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00';
    };
  },
  local_changes_ahead(state, getters, rootState, rootGetters) {
    return (dosageInstructionId) => {
      const localModel = getters['find'](dosageInstructionId);
      const dbModel = getters['find_db_model'](dosageInstructionId);
      return !Utility.is_equal(localModel, dbModel);
    };
  },
  is_empty(state, getters, rootState, rootGetters) {
    return (dosingInstructionId) => {
      const schema = getters['find_shadow_model'](dosingInstructionId);
      const nonZero = schema.values.filter( (value) => { return value.value === 'x' || parseFloat(value.value) > 0;});
      return nonZero.length === 0;
    };
  },
  shadow_stop_datetime(state, getters, rootState, rootGetters) {
    return (dosageInstructionId) => {
      const schema = getters['find_shadow_model'](dosageInstructionId);
      return moment(schema.dates[schema.dates.length-1], 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00';
    };
  },
  manualdosing_shadow_changes_ahead(state, getters, rootState, rootGetters) {
    return (dosageInstructionId) => {
      const localModel = getters['find'](dosageInstructionId);
      const shadowModel = getters['find_shadow_model'](dosageInstructionId);
      return !Utility.is_equal(localModel, shadowModel);
    };
  },
  for_dosage_instructions(state, getters, rootState) {
    return (dosageInstructions) => {
      let schemas = [];
      for (let i = 0; i < dosageInstructions.length; i++) {
        schemas = schemas.concat(getters['for_dosage_instruction'](dosageInstructions[i].id));
      }
      return schemas;
    };
  },
  for_dosage_instruction(state, getters, rootState) {
    return (id) => Utility.find_models_by_property(state.data, 'id', Utility.parse_id(id));
  },
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  init_schema({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {

    /**
     * Start code to initiate manual dosing schema with regular dosing values
     */
    const timeValues = dosingInstruction.times;

    let times = timeValues.map(function(time) { return Times.encodeTime(time.time); });
    times = times.filter(function(time) { return time !== "";});

    const startDate = moment(dosingInstruction.start_moment_datetime, 'YYYY-MM-DD HH:mm');

    let values = timeValues.filter(function(timeValue) { return timeValue.time !== "" && timeValue.value !== ""; });
    values = values.map(function(timeValue) {

      let sanitizedValue = timeValue.value.replace(',', '.');
      if (timeValue.value !== 'x' && isNaN(parseFloat(timeValue.value))) {
        sanitizedValue = 'x';
      }

      return {
        time: Times.encodeTime(timeValue.time),
        value: sanitizedValue,
        date: startDate,
      };
    });
    /**
     * End code to initiate manual dosing schema with regular dosing values
     */

    const manualDosingSchema = {
      dates: [startDate],
      times: times,
      values: values,
      id: dosingInstruction.id
    };

    commit('add_to_data', manualDosingSchema);
    commit('add_to_shadow_data', manualDosingSchema);
  },
  create({state, commit, dispatch, getters, rootState}, payload) {

    let newTempId = payload.id;

    if (!newTempId) {
      //Find last temp id in store
      newTempId = state.lastTempId + 1;
      state.lastTempId = newTempId;
      newTempId = 'new-' + newTempId;
    }

    const manualDosingSchema = {
      dates: payload.dates,
      times: payload.times,
      values: payload.values,
      id: newTempId
    };

    commit('add_to_data', manualDosingSchema);
    commit('add_to_shadow_data', manualDosingSchema);

    return newTempId;
  },
  store_shadow_changes_as({state, commit, dispatch, getters, rootState}, payload)
  {
    const models_to_copy = Utility.find_models_by_property(state.shadow_data, payload.prop, payload.old_id);

    models_to_copy.forEach(function(model_to_copy) {
      const clone = Utility.deep_clone(model_to_copy);
      clone[payload.prop] = payload.new_id;
      commit('add_to_data', clone);
    });
  },
  clear({state, commit, dispatch, getters, rootState}, id) {
    const schema = getters['find_shadow_model'](id);
    schema.values = [];
    schema.dates = [schema.dates[0]];
    commit('add_to_shadow_data', schema);
  },
  update_start_date({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const updatedStartDate = payload.updated_start_date;
    const schema = getters['find_shadow_model'](id);

    const updatedStart = moment(updatedStartDate, 'YYYY-MM-DD H:i').startOf('day');
    const currentStart = moment(schema.dates[0], 'YYYY-MM-DD');
    const diffInDays = updatedStart.diff(currentStart, 'days');

    schema.dates = schema.dates.map(function(date) {
      return moment(date, 'YYYY-MM-DD').add(diffInDays, 'days').format('YYYY-MM-DD');
    });

    schema.values = schema.values.map(function(value) {
      value.date = moment(value.date, 'YYYY-MM-DD').add(diffInDays, 'days').format('YYYY-MM-DD');
      return value;
    });

    commit('add_to_shadow_data', schema);
  },
  add_date({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const date = payload.date;
    const values = payload.values;
    const schema = getters['find_shadow_model'](id);
    schema.dates.push(date);
    values.forEach(function (value) {
      const clonedValue = Utility.deep_clone(value);
      clonedValue.date = date;
      schema.values.push(clonedValue);
    });
    commit('add_to_shadow_data', schema);
  },
  delete_time({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const time = payload.time;
    const schema = getters['find_shadow_model'](id);
    const index = Utility.find_index_of_matching_value(schema.times, time);
    schema.times.splice(index, 1);

    //remove values related to date
    for (let i = 0; i < schema.values.length; i++) {
      const value = schema.values[i];
      if (value.time === time) {
        schema.values.splice(i, 1);
        i--;
      }
    }
    commit('add_to_shadow_data', schema);
  },

  set_times_and_amounts({state, commit, dispatch, getters, rootState}, payload) {

    //update times:
    const id = payload.id;
    const times = payload.times;
    const amounts = payload.amounts;

    const schema = getters['find_shadow_model'](id);

    schema.times = [];
    schema.values = [];
    const date = schema.dates[0];
    for (let i = 0; i < times.length; i++) {
      const time = Times.encodeTime(times[i]);
      schema.times.push(time);
      schema.values.push({
        date: date, time: time, value: amounts[i]
      });
    }
    commit('add_to_shadow_data', schema);
  },
  remove_date({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const date = payload.date;
    const schema = getters['find_shadow_model'](id);
    const index = Utility.find_index_of_matching_value(schema.dates, date);
    schema.dates.splice(index, 1);

    //remove values related to date
    for (let i = 0; i < schema.values.length; i++) {
      const value = schema.values[i];
      if (value.date === date) {
        schema.values.splice(i, 1);
        i--;
      }
    }
    commit('add_to_shadow_data', schema);
  },
  update_time({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const newTime = Times.encodeTime(payload.newTime);
    const oldTime = Times.encodeTime(payload.oldTime);

    const schema = getters['find_shadow_model'](id);

    if (schema.times.includes(newTime)) {
      return;
    }

    const values = schema.values;
    const newValues = [];

    for (let i = 0; i < values.length; i++) {
      if (values[i].time === oldTime) {
        values[i].time = newTime;
      }
    }

    const index = schema.times.indexOf(oldTime);
    schema.times.splice(index, 1);
    schema.times.push(newTime);

    commit('add_to_shadow_data', schema);
  },
  add_time({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const time = Times.encodeTime(payload.time);
    const schema = getters['find_shadow_model'](id);
    if (schema.times.includes(time)) {
      return;
    }
    schema.times.push(time);

    commit('add_to_shadow_data', schema);
  },
  set_schema({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const values = payload.values;
    const times = payload.times;
    const dates = payload.dates;
    const schema = getters['find_shadow_model'](id);
    schema.times = times;
    schema.dates = dates;
    schema.values = values;

    commit('add_to_shadow_data', schema);
  },
  set_values({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const values = payload.values;
    const schema = getters['find_shadow_model'](id);
    schema.values = values;
    commit('add_to_shadow_data', schema);
  },
  set_times({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const times = payload.times;
    const schema = getters['find_shadow_model'](id);
    schema.times = times;
    commit('add_to_shadow_data', schema);
  },
  set_dates({state, commit, dispatch, getters, rootState}, payload) {
    const id = payload.id;
    const dates = payload.dates;
    const schema = getters['find_shadow_model'](id);
    schema.dates = dates;
    commit('add_to_shadow_data', schema);
  },
  set_value({state, commit, dispatch, getters, rootState}, payload) {

    const id = payload.id;
    const date = payload.date;
    const time = payload.time;
    const value = payload.value;
    const schema = getters['find_shadow_model'](id);
    const values = schema.values;

    const valueIndex = Utility.find_index_of_matching_elements(values, 'time', time, 'date', date);

    if (valueIndex !== -1) {
      values.splice(valueIndex, 1);
    }
    values.push({date: date, value: value, time: time});
    commit('add_to_shadow_data', schema);
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
