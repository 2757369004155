import Utility from "@/vue/utility/utility";
import BaseModule from "@/vue/store/modules/base_module";
import DataType from "@/enums/DataType";

const state = {
  ...BaseModule.state,
};

// getters
const getters = {
  ...BaseModule.getters,
  draft(state) {
    return (id) => Utility.find_model_by_id(state.data, id)[DataType.Draft];
  },
  userModified(state) {
    return (id) => Utility.find_model_by_id(state.data, id)[DataType.UserModified];
  },
  backend(state) {
    return (id) => Utility.find_model_by_id(state.data, id)[DataType.Backend];
  },
  backendByProperty(state) {
    return (id, property) => findByProperty(state.data, property, id);
  },
  findByTreatment(state) {
    return (id) => Utility.find_models_by_property(state.data, 'pharmaceuticalTreatmentId', id);
  },
};

function findByProperty(data, property, id) {
  const results = data.filter(item => item.backend[property] === id);
  return results.length > 0 ? results.map(item => item[DataType.Backend]) : null;
}

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  setData(state, data) {
    state.data.push({
      id: data.data.id,
      pharmaceuticalTreatmentId: data.data.pharmaceuticalTreatmentId,
      draft: null,
      userModified: null,
      backend: null,
    });

    const lastKey = state.data.length - 1;

    state.data[lastKey][DataType.Draft] = data.data; // Assign the cloned data
    state.data[lastKey][DataType.Backend] = state.object.fromJson(Utility.clone(data.data));
  },
  storeDraft(state, data) {
    const element = Utility.find_model_by_id(state.data, data.id);

    element[DataType.Draft] = data;
    element[DataType.UserModified] = state.object.fromJson(Utility.clone(data));
  },
  clearData(state) {
    state.data = [];
  },
  revertChanges(state, data) {
    if (state.data.length === 0) {
      return; // Nothing to revert, probably an F5.
    }

    const element = Utility.find_model_by_id(state.data, data);

    if (!element) {
      return; // Nothing found, probably an F5.
    }

    let revertTo = null;
    if (element.userModified === null) {
      revertTo = element.backend;
    } else {
      revertTo = element.userModified;
    }

    // When for example an new medication agreement started from an existing stopped MBH
    // we remove the complete record from the store based on these conditions:
    if (element.id === 0 && element.pharmaceuticalTreatmentId !== 0 && element.userModified === null) {
      state.data = state.data.slice(0, -1);
      return;
    }

    element[DataType.Draft] = state.object.fromJson(Utility.clone(revertTo)); // When userModified empty we revert back to backend changes.
  },
  removeLast(state) {
    if (state.data.length === 0) {
      return; // Nothing to revert, probably an F5.
    }

    state.data = state.data.slice(0, -1);
  },
  removeConcept(state, data) {
    const element = Utility.find_model_by_id(state.data, data);
    element[DataType.Draft] = state.object.fromJson(Utility.clone(element.backend));
    element[DataType.UserModified] = null;
  },
  removeConceptRowFromStore(state, id) {
    state.data = state.data.filter(row => {
      return row.id !== id;
    });
  },
  pushDosingInstruction(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);
    element.draft['instructionsForUse']['dosingInstructions'].push(payload.dosingInstruction);
  },
  setDosingInstructions(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);
    element.draft['instructionsForUse']['dosingInstructions'] = payload.dosingInstructions;
  },
  removeDosingInstruction(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);

    // Find the index of the dosing instruction with the specified id
    const dosingInstructionIndex = element.draft.instructionsForUse.dosingInstructions.findIndex(
      dosingInstruction => dosingInstruction.id === payload.dosingInstructionId
    );

    if (dosingInstructionIndex !== -1) {
      element.draft['instructionsForUse']['dosingInstructions'].splice(dosingInstructionIndex, 1);
    }
  },
  removeDosingInstructionBySequence(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);

    // Filter out dosing instructions with the specified sequenceNumber
    element.draft['instructionsForUse']['dosingInstructions'] = element.draft['instructionsForUse']['dosingInstructions'].filter(
      dosingInstruction => dosingInstruction.sequenceNumber !== payload.sequenceNumber
    );
  },
  removeDosingInstructionByAdministrationTime(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);

    // Filter out dosing instructions where any administrationTime matches the payload's TimeObject
    element.draft['instructionsForUse']['dosingInstructions'] = element.draft['instructionsForUse']['dosingInstructions'].filter(
      dosingInstruction => {
        // Check if the dosing instruction has an administration schedule with times
        if (!dosingInstruction.administeringSchedule?.administrationTime) {
          return true;
        }

        // Check if none of the TimeObjects in administrationTime match the payload's TimeObject
        return !dosingInstruction.administeringSchedule.administrationTime.some(
          timeObject => timeObject.hour === payload.time.hour && timeObject.minutes === payload.time.minutes
        );
      }
    );
  },
  updateDosingInstruction(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.Draft];

    // Find the index of the dosing instruction with the specified id
    const dosingInstructionIndex = draft['instructionsForUse']['dosingInstructions'].findIndex(
      dosingInstruction => dosingInstruction.id === payload.dosingInstructionId
    );

    if (dosingInstructionIndex !== -1) {
      draft['instructionsForUse']['dosingInstructions'][dosingInstructionIndex][payload.key] = payload.value;
    }
  },
  updateAdministeringSchedule(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.Draft];

    // Find the index of the dosing instruction with the specified id
    const dosingInstructionIndex = draft['instructionsForUse']['dosingInstructions'].findIndex(
      dosingInstruction => dosingInstruction.id === payload.dosingInstructionId
    );

    if (dosingInstructionIndex !== -1) {
      draft['instructionsForUse']['dosingInstructions'][dosingInstructionIndex]['administeringSchedule'][payload.key] = payload.value;
    }
  },
  updateInstructionForUse(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.Draft];
    draft['instructionsForUse'][payload.key] = payload.value;
  },
  updateSpecification(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.Draft];
    draft[payload.key] = payload.value;
  },
  updatePharmaceuticalProduct(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.Draft];
    draft['pharmaceuticalProduct'][payload.key] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
