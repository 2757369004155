import {MedicationVerificationItems} from '@/domain/models/MedicationVerificationItems';
import {MedicationVerificationItem} from '@/domain/models/MedicationVerificationItem';

export class GeneratedMedicationVerificationItems { 
  public newest: MedicationVerificationItem;
  public items: MedicationVerificationItem[];

  constructor(newest: MedicationVerificationItem, items: MedicationVerificationItem[]) {
    this.newest = newest;
    this.items = items;
  }

  public toJSON(): unknown {
    return {
      newest: this.newest,
      items: this.items,
    };
  }

  public static fromJson(json: unknown): MedicationVerificationItems {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationVerificationItems(
      MedicationVerificationItem.fromJson(parsedJson['newest']),
      parsedJson['items'] ? MedicationVerificationItem.multipleFromJson(parsedJson['items']) : [],
    );
  }

  public static multipleFromJson(json: unknown): MedicationVerificationItems[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
