import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {QuantityObject} from '@/domain/models/QuantityObject';
import moment from 'moment';

export class GeneratedTimeIntervalObject { 
  public startDateTime: moment.Moment | null;
  public endDateTime: moment.Moment | null;
  public duration: QuantityObject | null;
  public condition: string | null;

  constructor(startDateTime: moment.Moment | null = null, endDateTime: moment.Moment | null = null, duration: QuantityObject | null = null, condition: string | null = null) {
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.duration = duration;
    this.condition = condition;
  }

  public toJSON(): unknown {
    return {
      startDateTime: this.startDateTime ? this.startDateTime.format('YYYY-MM-DD HH:mm:ss') : null,
      endDateTime: this.endDateTime ? this.endDateTime.format('YYYY-MM-DD HH:mm:ss') : null,
      duration: this.duration,
      condition: this.condition,
    };
  }

  public static fromJson(json: unknown): TimeIntervalObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new TimeIntervalObject(
      parsedJson['startDateTime'] ? moment(parsedJson['startDateTime']) : null,
      parsedJson['endDateTime'] ? moment(parsedJson['endDateTime']) : null,
      parsedJson['duration'] ? QuantityObject.fromJson(parsedJson['duration']) : null,
      parsedJson['condition'] as string,
    );
  }

  public static multipleFromJson(json: unknown): TimeIntervalObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
