<template>
      <!--
          (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)
          (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)

          LEGACY - USE MedimoVueSelect2, MedimoLabeledVueSelect2 and MedimoValidatedVueSelect2

          (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)
          (!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)(!)
    -->
    <medimo-form-row :no_margins="no_margins">
      <medimo-form-column :no_margin="no_margins" :column_classes="inline ? label_column_classes : 'col-12'">
        <label :class="'select2 d-block pr-2 ' + ' ' + validation_class" :for="name">
          {{ label }}
        </label>
      </medimo-form-column>
      <medimo-form-column :no_margin="no_margins" :column_classes="inline ? inputColumnClasses : 'col-12'">
            <medimo-select2
                    :options="options"
                    :inline="inline"
                    :multiple="multiple"

                    v-bind:modelValue="modelValue"
                    @update:modelValue="onInput($event)"

                    :select2_class="select2_class"

                    @editedText="$emit('editedText', $event)"

                    v-on:select="onSelect"
                    v-on:unselect="onUnselect"

                    :name="name"
                    :editable="editable"
                    :editable_text="editable_text"
                    :ajaxCall="ajaxCall"
                    :placeholder="placeholder"
                    :extra_classes="extra_classes"
                    :validation_class="validation_class"
                    :help_text="help_text"
                    :minimum_results_for_search="minimum_results_for_search"

                    v-bind="$attrs"
                    :allow_editable_text_only="allow_editable_text_only"
                    :minimum_input_length="minimum_input_length"
                    :disabled="disabled"

            ></medimo-select2>
        </medimo-form-column>
    </medimo-form-row>
</template>

<script>

import MedimoSelect2 from './MedimoSelect2';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import MedimoFormRow from './MedimoFormRow';
import MedimoFormColumn from './MedimoFormColumn';

export default {

  components: {
    MedimoSelect2,
    MedimoTooltip,
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['editedText','update:modelValue','select','unselect'],

  props: {
    'no_margins': {default: true},
    'inline': {default: true},
    'modelValue': {default: ''},
    'options': {
      default: function () {
        return [];
      },
      type: [Array, Object]
    },
    'allow_editable_text_only': {default: false},
    'minimum_input_length': {default: 3},
    'multiple': {default: false},
    'name': {default: 'select2'},
    'ajaxCall': {default: ''},
    'placeholder': {default: 'Maak een keuze'},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'select2_class': {default: ''},
    'help_text': {default: ''},
    'label': {default: ''},

    // Set to TRUE to show the chosen Select2 value in a MedimoInput that's placed above the Select2.
    // Visually exactly the same, but allows for manual editing of the selected value.
    editable: {default: false},
    // You can have the editable_text pre-fill as well
    editable_text: {default: null, type: [String, null, undefined]},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    'label_column_classes': {default: 'col-auto'},
    inputColumnClasses: {default: 'col'},

    // Set the number of options there need to be for the search to be enabled
    'minimum_results_for_search': {default: 10},
    'disabled': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasHelpText() {
      return this.help_text.length;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      // To hopefully prevent some hairpulling mistakes in the future we log to the console when
      // the value is a number, and a string, meaning it will start to fail somewhere down the line if someone
      // expects an integer to be returned (it won't, Select2 transforms integers to string numbers)
      // if NOT Not a Number (i.e. is a number, sorry, no other way)
      if (!isNaN(value) && typeof value === 'string') {
        if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
          console.log('Please be wary that MedimoLabeledSelect2 is returning the number value as a string:', value);
        }
      }
      this.$emit('update:modelValue', value);
    },
    onSelect(value) {
      this.$emit('select', value);
    },
    onUnselect(value) {
      this.$emit('unselect', value);
    }
  },

  watch: {
    //
  }
};
</script>
