<template>
    <form class="medimo-form" @submit="$emit('submit')">

        <medimo-loader-overlay :loading="loading" :size="32"></medimo-loader-overlay>

        <slot></slot>

    </form>
</template>

<script>
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';
export default {
  emits: 'submit',

  components: {
    MedimoLoaderOverlay
  },

  props: {
    loading: {default: false}
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
