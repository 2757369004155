import {GeneratedAdministrationAgreement} from '@/domain/models/generated/GeneratedAdministrationAgreement';
import moment from "moment";
import SnomedCt from "@/enums/SnomedCt";

export class AdministrationAgreement extends GeneratedAdministrationAgreement {
  public isActive() {
    const now = moment();

    if (this.startDateTime?.isBefore(now) && this.endDateTime === null) {
      return true;
    }

    if (this.startDateTime?.isBefore(now) && this.endDateTime?.isAfter(now)) {
      return true;
    }

    return false;
  }

  public isNewOnExistingTreatment() {
    return this.id === 0 && this.pharmaceuticalTreatmentId !== 0;
  }

  public hasBeenCancelled(): boolean {
    if (this.stopType === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Cancelled;
  }

  public hasDiscontinueStop(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Discontinued;
  }

  public hasBeenTemporarilyStopped(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Suspended;
  }

  public hasStop(): boolean {
    return this.hasDiscontinueStop() || this.hasBeenTemporarilyStopped();
  }
}
