import {store} from "@/vue/store";
import {AbstractMedicationStore} from "@/controllers/AbstractMedicationStore";
import BuildingBlockType from "@/enums/BuildingBlockType";
import {PharmaceuticalTreatmentMedicationAgreementController} from "@/controllers/PharmaceuticalTreatmentMedicationAgreementController";
import {PharmaceuticalTreatmentAdministrationAgreementController} from "@/controllers/PharmaceuticalTreatmentAdministrationAgreementController";
import {PharmaceuticalTreatmentMedicationUseController} from "@/controllers/PharmaceuticalTreatmentMedicationUseController";
import {PharmaceuticalTreatmentVariableDosingRegimenController} from "@/controllers/PharmaceuticalTreatmentVariableDosingRegimenController";

export class PharmaceuticalTreatmentController extends AbstractMedicationStore {
  protected apiEndpoint = 'pharmaceutical-treatment';

  public async read(patientId: number, pharmaceuticalTreatmentId: number): Promise<void> {
    // When pharmaceutical treatment already loaded, we are done
    if (this.pharmaceuticalTreatmentIsAlreadyLoaded(pharmaceuticalTreatmentId)) {
      return;
    }

    // New pharmaceutical treatment so we throw an error if nothing in the store.
    if (pharmaceuticalTreatmentId === 0) {
      throw new Error('New pharmaceutical treatment not found.');
    }

    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/' + pharmaceuticalTreatmentId,
    });

    const pharmaceuticalTreatment = response.data.data;
    this.addToStore(pharmaceuticalTreatment);
  }

  public async store(patientId: number, pharmaceuticalTreatmentId: number, buildingBlockType: BuildingBlockType): Promise<void> {
    const userModifiedData = this.userModifiedData(pharmaceuticalTreatmentId, buildingBlockType);
    if (userModifiedData === null) {
      throw new Error('No user modified found');
    }

    // When treatment zero its a new one.
    let apiEndPoint = '';
    if (pharmaceuticalTreatmentId === 0) {
      apiEndPoint = '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/';
    } else {
      apiEndPoint = '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/' + pharmaceuticalTreatmentId;
    }

    await store.dispatch('api/postEndpoint', {
      endpoint: apiEndPoint,
      data: {data: userModifiedData, type: buildingBlockType}
    });

    this.removeFromStore();
  }

  private pharmaceuticalTreatmentIsAlreadyLoaded(pharmaceuticalTreatmentId: number): boolean {
    const getters = [
      store.getters['mp9/medication_agreements/findByTreatment'](pharmaceuticalTreatmentId),
      store.getters['mp9/administration_agreements/findByTreatment'](pharmaceuticalTreatmentId),
      store.getters['mp9/variable_dosing_regimens/findByTreatment'](pharmaceuticalTreatmentId),
      store.getters['mp9/medication_uses/findByTreatment'](pharmaceuticalTreatmentId),
    ];

    return getters.some(result => result.length > 0);
  }

  // todo m9 - meh
  private userModifiedData(pharmaceuticalTreatmentId: number, buildingBlockType: BuildingBlockType): any[] | null {
    const controller = new Map<BuildingBlockType, () => any[]>([
      [BuildingBlockType.medicationAgreement, () => new PharmaceuticalTreatmentMedicationAgreementController().userModifiedData(pharmaceuticalTreatmentId)],
      [BuildingBlockType.administrationAgreement, () => new PharmaceuticalTreatmentAdministrationAgreementController().userModifiedData(pharmaceuticalTreatmentId)],
      [BuildingBlockType.medicationUse, () => new PharmaceuticalTreatmentMedicationUseController().userModifiedData(pharmaceuticalTreatmentId)],
      [BuildingBlockType.variableDosingRegimen, () => new PharmaceuticalTreatmentVariableDosingRegimenController().userModifiedData(pharmaceuticalTreatmentId)],
    ]);

    const controllerFunction = controller.get(buildingBlockType);
    return controllerFunction ? controllerFunction() : null;
  }
}
