import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';
import Times from '@/vue/utility/times';
import moment from 'moment-mini';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  search_endpoint: '/api/v1/wards/search',

  store_namespace: 'wards',

  sort_columns: [],
  search_columns: [
    'afdeling.naam',
    'afdeling.adres',
    'afdeling.plaats',
    'location.naam',
  ],

  quick_filters: {
    //
  },

  data: []
};

// getters
const getters = {
  ...BaseModule.getters,
  default_times(state, getters, rootState) {
    return (id) => {
      const ward = Utility.find_model_by_id(state.data, id);
      const times = [];
      for (let i=1; i<=6; i++) {
        let time = '';
        if (Times.validateTime(ward['st_r'+i]) && ward['st_r'+i] !== '') {
          time = Times.encodeTime(ward['st_r'+i]);
        }
        times.push(time);
      }

      // En nu nog de tijden chronologisch sorteren:
      times.sort(function (a, b) {
        a = parseInt(a); // Het zijn strings met "701" etc.
        b = parseInt(b); // Vandaar dat ze eerst geparsed moeten worden
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      return times;
    };
  },
  deadline_today(state, getters) {
    return (ward) => {
      //See Ward.php
      return moment().startOf('day').add(1, 'days').subtract(ward.deadline_today_minutes, 'minutes');
    };
  },
  deadline_tomorrow(state, getters) {
    return (ward) => {
      const deadlineToday = getters['deadline_today'](ward);
      if (moment() < deadlineToday) {
        return moment().startOf('day').add(1, 'days');
      } else if (deadlineToday) {
        return deadlineToday.add(1, 'days');
      }
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/wards/single/' + payload.id,
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
