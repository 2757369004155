enum BuildingBlockType {
  medicationAgreement = 'medication-agreement',
  medicationUse = 'medication-use',
  variableDosingRegimen = 'variable-dosing-regimen',
  administrationAgreement = 'administration-agreement',
  dispenseRequest = 'dispense-request',
  medicationDispense = 'medication-dispense',
}

export default BuildingBlockType;
