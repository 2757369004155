import {AdministrationAgreement} from '@/domain/models/AdministrationAgreement';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';
import {CodeObject} from '@/domain/models/CodeObject';
import {CodeValue} from '@/domain/models/CodeValue';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import moment from 'moment';

export class GeneratedAdministrationAgreement { 
  public active: boolean;
  public medicationAgreementId: number | null;
  public previousAdministrationAgreementId: number | null;
  public supplier: HealthcareProvider;
  public supplierPrescriber: HealthcareProfessional | null;
  public additionalInformation: CodeObject[];
  public reasonModificationOrDiscontinuation: CodeValue | null;
  public reasonModificationOrDiscontinuationText: string | null;
  public endDateTime: moment.Moment | null;
  public id: number;
  public description: string;
  public patientId: number;
  public externalIdentifierSystem: CodeSystem;
  public externalIdentifier: IdentifierObject;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public pharmaceuticalTreatmentId: number;
  public startDateTime: moment.Moment | null;
  public instructionsForUse: InstructionsForUse;
  public periodOfUse: TimeIntervalObject;
  public stopType: CodeValue | null;
  public comment: string | null;
  public copyIndicator: boolean;
  public provenance: string | null;
  public icon: string;
  public mutationType: string;
  public distributedIndicator: boolean;
  public registrationDatetime: moment.Moment | null;
  public createdAt: moment.Moment | null;

  constructor(active: boolean, medicationAgreementId: number | null = null, previousAdministrationAgreementId: number | null = null, supplier: HealthcareProvider, supplierPrescriber: HealthcareProfessional | null = null, additionalInformation: CodeObject[], reasonModificationOrDiscontinuation: CodeValue | null = null, reasonModificationOrDiscontinuationText: string | null = null, endDateTime: moment.Moment | null = null, id: number, description: string, patientId: number, externalIdentifierSystem: CodeSystem, externalIdentifier: IdentifierObject, pharmaceuticalProduct: PharmaceuticalProduct, pharmaceuticalTreatmentId: number, startDateTime: moment.Moment | null = null, instructionsForUse: InstructionsForUse, periodOfUse: TimeIntervalObject, stopType: CodeValue | null = null, comment: string | null = null, copyIndicator: boolean, provenance: string | null = null, icon: string, mutationType: string, distributedIndicator: boolean, registrationDatetime: moment.Moment | null = null, createdAt: moment.Moment | null = null) {
    this.active = active;
    this.medicationAgreementId = medicationAgreementId;
    this.previousAdministrationAgreementId = previousAdministrationAgreementId;
    this.supplier = supplier;
    this.supplierPrescriber = supplierPrescriber;
    this.additionalInformation = additionalInformation;
    this.reasonModificationOrDiscontinuation = reasonModificationOrDiscontinuation;
    this.reasonModificationOrDiscontinuationText = reasonModificationOrDiscontinuationText;
    this.endDateTime = endDateTime;
    this.id = id;
    this.description = description;
    this.patientId = patientId;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.externalIdentifier = externalIdentifier;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.startDateTime = startDateTime;
    this.instructionsForUse = instructionsForUse;
    this.periodOfUse = periodOfUse;
    this.stopType = stopType;
    this.comment = comment;
    this.copyIndicator = copyIndicator;
    this.provenance = provenance;
    this.icon = icon;
    this.mutationType = mutationType;
    this.distributedIndicator = distributedIndicator;
    this.registrationDatetime = registrationDatetime;
    this.createdAt = createdAt;
  }

  public toJSON(): unknown {
    return {
      active: this.active,
      medicationAgreementId: this.medicationAgreementId,
      previousAdministrationAgreementId: this.previousAdministrationAgreementId,
      supplier: this.supplier,
      supplierPrescriber: this.supplierPrescriber,
      additionalInformation: this.additionalInformation,
      reasonModificationOrDiscontinuation: this.reasonModificationOrDiscontinuation,
      reasonModificationOrDiscontinuationText: this.reasonModificationOrDiscontinuationText,
      endDateTime: this.endDateTime ? this.endDateTime.format('YYYY-MM-DD HH:mm:ss') : null,
      id: this.id,
      description: this.description,
      patientId: this.patientId,
      externalIdentifierSystem: this.externalIdentifierSystem,
      externalIdentifier: this.externalIdentifier,
      pharmaceuticalProduct: this.pharmaceuticalProduct,
      pharmaceuticalTreatmentId: this.pharmaceuticalTreatmentId,
      startDateTime: this.startDateTime ? this.startDateTime.format('YYYY-MM-DD HH:mm:ss') : null,
      instructionsForUse: this.instructionsForUse,
      periodOfUse: this.periodOfUse,
      stopType: this.stopType,
      comment: this.comment,
      copyIndicator: this.copyIndicator,
      provenance: this.provenance,
      icon: this.icon,
      mutationType: this.mutationType,
      distributedIndicator: this.distributedIndicator,
      registrationDatetime: this.registrationDatetime ? this.registrationDatetime.format('YYYY-MM-DD HH:mm:ss') : null,
      createdAt: this.createdAt ? this.createdAt.format('YYYY-MM-DD HH:mm:ss') : null,
    };
  }

  public static fromJson(json: unknown): AdministrationAgreement {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new AdministrationAgreement(
      parsedJson['active'] as boolean,
      parsedJson['medicationAgreementId'] as number,
      parsedJson['previousAdministrationAgreementId'] as number,
      HealthcareProvider.fromJson(parsedJson['supplier']),
      parsedJson['supplierPrescriber'] ? HealthcareProfessional.fromJson(parsedJson['supplierPrescriber']) : null,
      parsedJson['additionalInformation'] ? CodeObject.multipleFromJson(parsedJson['additionalInformation']) : [],
      parsedJson['reasonModificationOrDiscontinuation'] ? CodeValue.fromJson(parsedJson['reasonModificationOrDiscontinuation']) : null,
      parsedJson['reasonModificationOrDiscontinuationText'] as string,
      parsedJson['endDateTime'] ? moment(parsedJson['endDateTime']) : null,
      parsedJson['id'] as number,
      parsedJson['description'] as string,
      parsedJson['patientId'] as number,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      IdentifierObject.fromJson(parsedJson['externalIdentifier']),
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['startDateTime'] ? moment(parsedJson['startDateTime']) : null,
      InstructionsForUse.fromJson(parsedJson['instructionsForUse']),
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['comment'] as string,
      parsedJson['copyIndicator'] as boolean,
      parsedJson['provenance'] as string,
      parsedJson['icon'] as string,
      parsedJson['mutationType'] as string,
      parsedJson['distributedIndicator'] as boolean,
      parsedJson['registrationDatetime'] ? moment(parsedJson['registrationDatetime']) : null,
      parsedJson['createdAt'] ? moment(parsedJson['createdAt']) : null,
    );
  }

  public static multipleFromJson(json: unknown): AdministrationAgreement[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
