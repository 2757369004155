<template>
  <medimo-button-link :slim="true" icon="fa-solid fa-external-link" @click="open_modal = true">
    Bekijk alle protocol regels
  </medimo-button-link>

  <medi-modal size="large" v-if="open_modal" @closed="open_modal = false">
    <protocol-builder :initial_rules="initialRules" :edit-mode="false" class="builder" />
  </medi-modal>
</template>
<script>
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoButtonLink from "@/vue/components/general/MedimoButtonLink.vue";
import ProtocolBuilder from '@/vue/components/protocol/ProtocolBuilder.vue';

export default {
  components: {MedimoButtonLink, MediModal, ProtocolBuilder},
  props: {
    initialRules: {required: true}
  },
  data() {
    return {
      open_modal: false
    }
  }
}
</script>
<style scoped lang="scss">
  .builder {
    overflow-x: auto;
  }
</style>
