import {store} from '@/vue/store';
import MedimoAlert from "@/declarations/MedimoAlert";
import {PatientProtocol} from "@/domain/models/PatientProtocol";
import {FullLocalProtocol} from "@/domain/models/FullLocalProtocol";

export class ProtocolController {
  private storeName = 'protocols';

  public singleFromStore(): FullLocalProtocol {
    return store.getters[this.storeName + '/state']('single');
  }

  public patientsFromStore(): PatientProtocol[] {
    return store.getters['patients_protocol/paginated_data'];
  }

  public totalPatientsFromStore(): PatientProtocol[] {
    return store.getters['patients_protocol/data'];
  }

  public async single(id: number, entityId: number, entityType: string): Promise<void> {
    await store.dispatch(this.storeName + '/fetch_single', {id: id, entityId: entityId, entityType: entityType});
  }

  public async patients(entityId: number, entityType: string): Promise<void> {
    await store.dispatch('patients_protocol/fetch', {entityId: entityId, entityType: entityType});
  }

  public async start(id: number, patientIds: number[]): Promise<void> {
    await store.dispatch(this.storeName + '/start', {id: id, patientIds: patientIds}).then((response) => {
      response.data.notifications.forEach((notification: MedimoAlert) => {
        store.dispatch('notifications/add_alert', notification);
      });
    });
  }
}
