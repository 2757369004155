<template>
  <div :dir="dir" class="v-select" :class="stateClasses" ref="vue-select">
    <slot name="header" v-bind="scope.header" />
    <div
        :id="`vs${uid}__combobox`"
        ref="toggle"
        class="vs__dropdown-toggle  form-control"
        role="combobox"
        :aria-expanded="dropdownOpen.toString()"
        :aria-owns="`vs${uid}__listbox`"
        aria-label="Search for option"
        @mousedown="toggleDropdown($event)"
    >
      <div ref="selectedOptions" class="vs__selected-options">
        <slot
            v-for="(option, i) in selectedValues"
            name="selected-option-container"
            :option="normalizeOptionForSlot(option)"
            :deselect="deselect"
            :multiple="multiple"
            :disabled="disabled"
        >
          <span :key="getOptionKey(option)" class="vs__selected" :style="(multiple || selectedWidth === 0 ? '' : 'width:' + selectedWidth + 'px; test: niks;')" v-show="!search.length">
            <slot
                name="selected-option"
                v-bind="normalizeOptionForSlot(option)"
            >
              {{ getOptionLabel(option) }}
            </slot>
            <button
                v-if="multiple"
                ref="deselectButtons"
                :disabled="disabled"
                type="button"
                class="vs__deselect"
                :title="`Deselect ${getOptionLabel(option)}`"
                :aria-label="`Deselect ${getOptionLabel(option)}`"
                @click="deselect(option)"
            >
              <component :is="childComponents.Deselect" />
            </button>
          </span>
        </slot>

        <slot name="search" v-bind="scope.search">
          <input
              class="vs__search"
              v-bind="scope.search.attributes"
              v-on="scope.search.events"
          />
        </slot>
      </div>

      <div ref="actions" class="vs__actions">
        <button
            v-if="showClearButton"
            ref="clearButton"
            :disabled="disabled"
            type="button"
            class="vs__clear"
            title="Clear Selected"
            aria-label="Clear Selected"
            @click="clearSelection"
        >
          <component :is="childComponents.Deselect" />
        </button>

        <slot name="open-indicator" v-bind="scope.openIndicator">
          <component
              :is="childComponents.OpenIndicator"
              v-if="!noDrop"
              v-bind="scope.openIndicator.attributes"
          />
        </slot>

        <slot name="spinner" v-bind="scope.spinner">
          <div v-show="mutableLoading" class="vs__spinner">Loading...</div>
        </slot>
      </div>
    </div>
    <transition :name="transition">
      <ul
          v-if="dropdownOpen"
          :id="`vs${uid}__listbox`"
          ref="dropdownMenu"
          :key="`vs${uid}__listbox`"
          v-append-to-body
          class="vs__dropdown-menu"
          role="listbox"
          tabindex="-1"
          @mousedown.prevent="onMousedown"
          @mouseup="onMouseUp"
      >
        <slot name="list-header" v-bind="scope.listHeader" />
        <li
            v-for="(option, index) in filteredOptions"
            :id="`vs${uid}__option-${index}`"
            :key="getOptionKey(option)"
            role="option"
            class="vs__dropdown-option"
            :class="{
            'vs__dropdown-option--deselect':
              isOptionDeselectable(option) && index === typeAheadPointer,
            'vs__dropdown-option--selected': isOptionSelected(option),
            'vs__dropdown-option--highlight': index === typeAheadPointer,
            'vs__dropdown-option--disabled': !selectable(option),
          }"
            :aria-selected="index === typeAheadPointer ? true : null"
            @mouseover="selectable(option) ? (typeAheadPointer = index) : null"
            @click.prevent.stop="selectable(option) ? select(option) : null"
        >
          <slot name="option" v-bind="normalizeOptionForSlot(option)">
            {{ getOptionLabel(option) }}
          </slot>
        </li>
        <li v-if="filteredOptions.length === 0" class="vs__no-options">
          <slot name="no-options" v-bind="scope.noOptions">
            {{ noOptionsMessage }}
          </slot>
        </li>
        <slot name="list-footer" v-bind="scope.listFooter" />
      </ul>
      <ul
          v-else
          :id="`vs${uid}__listbox`"
          role="listbox"
          style="display: none; visibility: hidden"
      ></ul>
    </transition>
    <slot name="footer" v-bind="scope.footer" />
  </div>
</template>

<script>
import BaseVueSelect from '/vue/components/general/form/base/vue-select2/vue3-src/components/Select.vue';
import appendToBody from '/vue/components/general/form/base/vue-select2/directives/appendToBody';
import WorksWithParentsSiblingsAndChildren from '/vue/mixins/WorksWithParentsSiblingsAndChildren';
import {nextTick} from 'vue';

/**
 * @name VueSelect
 */
export default {
  components: { ...BaseVueSelect.components.childComponents },
  emits:  ['open', 'close','option:selecting','option:created','option:selected','option:deselecting','option:deselected','update:modelValue','search:blur','search:focus', 'search'],
  directives: { appendToBody },

  // Pre Vue3
  // mixins: [mixins.pointerScroll, mixins.pointer, mixins.ajax, WorksWithParentsSiblingsAndChildren],
  // Post Vue3
  // mixins: [BaseVueSelect.mixins.pointerScroll, BaseVueSelect.mixins.pointer, BaseVueSelect.mixins.ajax, WorksWithParentsSiblingsAndChildren],
  mixins: [...BaseVueSelect.mixins, WorksWithParentsSiblingsAndChildren],

  props: Object.assign(BaseVueSelect.props, {
    noOptionsMessage: {default: 'Geen resultaten'},

    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: false,
    },

    /**
     * Used for calculations
     * @type {String}
     */
    help_text: {
      type: String,
      default: '',
    },

    /**
     * Als je de auto-breedte wil resetten (omdat er iets in de layout is veranderd) kan dat door deze te ++en
     * @type {Number}
     */
    resetWidth: {
      type: Number,
      default: 0,
    },

    /**
     * When `appendToBody` is true, this function is responsible for
     * positioning the drop down list.
     *
     * If a function is returned from `calculatePosition`, it will
     * be called when the drop down list is removed from the DOM.
     * This allows for any garbage collection you may need to do.
     *
     * @since v3.7.0
     * @see http://vue-select.org/guide/positioning.html
     */
    calculatePosition: {
      type: Function,
      /**
       * @param dropdownList {HTMLUListElement}
       * @param component {Vue} current instance of vue select
       * @param width {string} calculated width in pixels of the dropdown menu
       * @param top {string} absolute position top value in pixels relative to the document
       * @param left {string} absolute position left value in pixels relative to the document
       * @return {function|void}
       */
      default(dropdownList, component, { width, top, left }) {
        // De nextTick is nodig om met smallere pagina's geen verspringen te krijgen
        // bij de eerste render omdat er een scrollbar aanwezig is. Met de nextTick
        // is deze offset in de boundingBox bekend, en is de positie altijd correct.
        nextTick(() => {
          const boundingBox = component.$el.getBoundingClientRect();
          // By default is de 'top' berekend vanaf de top van de pagina.
          // Met de boundingBox waarde i.c.m. position "fixed" werkt het ook in MediModals
          // omdat we dan uitgaan van de 'top' vanaf de top van het scherm

          if (this.find_matching_parent('mediModalUid')) {
            top = (boundingBox.top + boundingBox.height) + 'px';
            dropdownList.style.position = 'fixed';
          }

          // We maken de breedte semi-dynamisch adhv de karakters
          let longestLength = 0;
          this.filteredOptions.forEach(option => {
            let optionLength = 0;
            if (typeof option.group === 'undefined') {
              optionLength = option[this.label].length;
            }
            longestLength = optionLength > longestLength ? optionLength : longestLength;
          });

          // We gaan er vanuit dat de gemiddelde karakter 6px breed is:
          const preferableWidth = longestLength * 6;
          const maxWidth = boundingBox.width * 1.5;
          const minWidth = boundingBox.width;
          let width = minWidth;
          if (preferableWidth > minWidth && preferableWidth < maxWidth) {
            width = preferableWidth;
          }
          if (preferableWidth > maxWidth) {
            width = maxWidth;
          }

          dropdownList.style.top = top;
          // Dankzij de nextTick is de left hier inclusief scrollbar breedte
          dropdownList.style.left = boundingBox.left + 'px';
          dropdownList.style.width = width + 'px';
          dropdownList.style.minWidth = width + 'px';
          dropdownList.style.zIndex = 100000; // Always on top
        });
      },
    },
  }),

  data() {
    return {
      search: '',
      open: false,
      isComposing: false,
      pushedTags: [],
      // eslint-disable-next-line vue/no-reserved-keys
      _value: [], // Internal value managed by Vue Select if no `value` prop is passed
      deselectButtons: [],

      // Deze hebben we nodig om met een click outside de VueSelect automatisch te kunnen closen
      // dit kan echter niet direct, anders opent hij nooit want... JS
      canBeClosed: false,
      // Deze is nodig om icm de directive de calculatePosition bij iedere change
      // opnieuw te berekenen, maar niet te triggeren als het om een simpele scroll gaat
      shouldRecalculatePosition: false,
      selectedWidth: 0,
    };
  },

  computed: Object.assign(BaseVueSelect.computed, {
    /**
     * The currently displayed options, filtered
     * by the search elements value. If tagging
     * true, the search text will be prepended
     * if it doesn't already exist.
     *
     * @return {array}
     */
    filteredOptions() {
      const optionList = [].concat(this.optionList).filter(option => {
        return typeof option.visible === 'undefined' || option.visible;
      });

      if (!this.filterable && !this.taggable) {
        return optionList;
      }

      const options = this.search.length
        ? this.filter(optionList, this.search, this)
        : optionList;
      if (this.taggable && this.search.length) {
        const createdOption = this.createOption(this.search);
        if (!this.optionExists(createdOption)) {
          options.unshift(createdOption);
        }
      }
      return options;
    },
  }),

  watch: Object.assign(BaseVueSelect.watch, {
    /**
     * Zie shouldRecalculatePosition data comments
     */
    filteredOptions: {
      deep: true,
      handler() {
        this.shouldRecalculatePosition = true;
      }
    },

    open(isOpen) {
      this.$emit(isOpen ? 'open' : 'close');

      setTimeout(() => {
        this.canBeClosed = isOpen;
      }, 100);
    },

    resetWidth() {
      this.setSelectedWidth();
    }
  }),

  created: BaseVueSelect.created,

  mounted() {
    this.setSelectedWidth();
  },

  methods: Object.assign(BaseVueSelect.methods, {
    setSelectedWidth() {
      this.selectedWidth = 0;
      if (this.multiple !== true) {
        setTimeout(() => {
          // Needed to get the elipsis break-off to work. It does not work with a 100% width style
          const toggleBox = this.$refs['vue-select'];
          let width = toggleBox ? toggleBox.getBoundingClientRect().width : 0;
          width = width - 30; // Deze moet altijd, want anders wordt hij mega breed
          // Account for the help & clear
          if (this.clearable) {
            width = width - 21;
          }
          if (this.help_text.length) {
            width = width - 21;
          }
          this.selectedWidth = Math.round(width, 0);
        }, 50); // Kleine delay anders rekent hij met zichzelf
      }
    },

    /**
     * Toggle the visibility of the dropdown menu.
     * @param  {Event} event
     * @return {void}
     */
    toggleDropdown(event) {
      const targetIsNotSearch = event.target !== this.searchEl;
      if (targetIsNotSearch) {
        event.preventDefault();
      }

      //  don't react to click on deselect/clear buttons,
      //  they dropdown state will be set in their click handlers
      const ignoredButtons = [
        ...(this.$refs['deselectButtons'] || []),
        ...([this.$refs['clearButton']] || []),
      ];

      if (
        this.searchEl === undefined ||
        ignoredButtons
          .filter(Boolean)
          .some((ref) => ref.contains(event.target) || ref === event.target)
      ) {
        event.preventDefault();
        return;
      }

      if (this.open && targetIsNotSearch) {
        this.searchEl.blur();
      } else if (!this.disabled) {
        this.open = true;
        nextTick(() => {
          this.searchEl.focus();
        });
      }
    },

    /**
     * Close the dropdown on blur.
     * @emits  {search:blur}
     * @return {void}
     */
    onSearchBlur() {
      this.canBeClosed = true;

      if (this.mousedown && !this.searching) {
        this.mousedown = false;
      } else {
        const { clearSearchOnSelect, multiple } = this;
        if (this.clearSearchOnBlur({ clearSearchOnSelect, multiple })) {
          this.search = '';
        }
        this.closeSearchOptions();
        return;
      }
      // Fixed bug where no-options message could not be closed
      if (this.search.length === 0 && this.options.length === 0) {
        this.closeSearchOptions();
        return;
      }
    },

    /**
     * Open the dropdown on focus.
     * @emits  {search:focus}
     * @return {void}
     */
    onSearchFocus() {
      this.canBeClosed = false;
      this.open = true;
      this.$emit('search:focus');
    },

    onClickedOutside() {
      // Hier moeten we even creatief zijn;
      // Als er buiten de select2 wordt geklikt
      // maar de Input niet actief is
      // dan sluiten we hem
      // Geval is namelijk dat door de AppendToBody de input clicken altijd geldt als _buiten_
      // maar dat UX technisch natuurlijk niet is
      nextTick(() => {
        if (this.open && this.canBeClosed) {
          this.open = false;
        }
      });
    }
  }),
};
</script>
