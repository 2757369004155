import {GeneratedMedicationAgreement} from '@/domain/models/generated/GeneratedMedicationAgreement';
import SnomedCt from "@/enums/SnomedCt";

export class MedicationAgreement extends GeneratedMedicationAgreement {
  public isNewOnExistingTreatment() {
    return this.id === 0 && this.pharmaceuticalTreatmentId !== 0;
  }

  public hasBeenCancelled(): boolean {
    if (this.stopType === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Cancelled;
  }

  public hasDiscontinueStop(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Discontinued;
  }

  public hasBeenTemporarilyStopped(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Suspended;
  }

  public hasStop(): boolean {
    return this.hasDiscontinueStop() || this.hasBeenTemporarilyStopped();
  }
}
