<template>
  <div id="admin-side-panel" v-if="current_user.is_admin && visible" :style="'width: ' + width + 'px; height: ' + (height - 50) + 'px;'">
    <!-- De clickOutside zetten we hier op zodat hij niet _altijd_ getriggerd wordt -->
    <medimo-loader-overlay :loading="loading"></medimo-loader-overlay>
    <div class="row justify-content-center m-0">
      <div class="col-md-12 p-0">
        <div class="card">
          <div class="card-header w-100 d-flex">
            <div class="my-auto">
              Helpdesk Panel
            </div>
            <medimo-button size="sm" type="medimo-5" class="ml-auto" v-if="large" @click="large = false">
              <fa-icon icon="fa-solid fa-caret-right" size="lg" />
            </medimo-button>
            <medimo-button size="sm" type="medimo-5" class="ml-auto" v-else @click="large = true">
              <fa-icon icon="fa-solid fa-caret-left" size="lg" />
            </medimo-button>
            <medimo-button size="sm" type="medimo-5" @click="visible = false">
              <fa-icon icon="fa-solid fa-remove" size="lg" />
            </medimo-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <medimo-link :to="'/config/resource/admintables/customer/' + current_cluster.customer?.id">
                  <strong> {{ customer_name }} </strong>
                </medimo-link>
              </div>
              <div class="col-12">
                Account manager: <strong>{{ current_cluster.customer?.consultant }}</strong>
              </div>
              <div class="col-12 mt-2">
                <div class="p-2 bg-medimo-5 text-medimo">
                  <em>
                    <medimo-link :to="'/config/resource/admintables/customer/' + current_cluster.customer?.id">
                      {{ afspraken }}
                    </medimo-link>
                  </em>
                </div>
              </div>
            </div>
          </div>
        </div>

        <admin-panel-block title="Functioneel applicatiebeheerders" open-property-name="fabsVisible">
          <div class="row">
            <div class="col-12 no-overflow" v-for="fabber in current_cluster.fabbers">
              <medimo-link :to="'/config/entities/users/edit/' + fabber.id">
                {{ fabber.naam }} <small class="text-medimo-75">({{fabber.mailadres}})</small>
              </medimo-link>
            </div>
          </div>
        </admin-panel-block>

        <admin-panel-block title="Openstaande tickets" open-property-name="otrsVisible">
            <div class="row">
              <div class="col-12" v-for="ticket in current_cluster.tickets" v-if="current_cluster.tickets.length">
                <medimo-link :to="'https://otrs.medimo.nl/otrs/index.pl?Action=AgentTicketZoom;TicketID=' + ticket.id">
                  <medimo-tooltip
                      :content="'Ticket staat ' + ticket.age_in_days +' dagen open'"
                      class="small">
                    {{ ticket.tn }}
                    <medimo-badge type="warning" v-if="ticket.third_line">3e lijn</medimo-badge>

                  </medimo-tooltip>
                  {{ ticket.title }}
                </medimo-link>
              </div>
              <div class="col-12" v-if="current_cluster.tickets.length === 0">
                <em>Geen openstaande tickets</em>
              </div>
            </div>
        </admin-panel-block>

        <admin-panel-block title="Koppelingen" open-property-name="koppelingenVisible">
          <div class="row">
            <div class="col-12 no-overflow" v-for="koppeling in current_cluster.koppelingen">
              <medimo-link :to="'/config/resource/webservice/webservice/' + koppeling.id" :navigateHard="true">
                {{ koppeling.koppelvlak }} <small class="text-medimo-75">{{ koppeling.environment_description }}</small>
              </medimo-link>
            </div>
          </div>
        </admin-panel-block>

      </div>
    </div>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import AdminPanelBlock from './admin-side-panel/AdminPanelBlock';
import MedimoBadge from '@/vue/components/general/MedimoBadge';
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';

export default {
  components: {
    MedimoLoaderOverlay,
    MedimoBadge,
    AdminPanelBlock,
  },

  props: [],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      height: '100vh',
      interval: null,
      loading: false,
    };
  },

  computed: {
    current_user() {
      return this.$store.getters['current_user/data'];
    },
    current_user_cluster_id() {
      return this.current_user.cluster_id;
    },
    current_cluster() {
      return this.$store.getters['current_user/current_cluster_info'];
    },
    cluster_name() {
      return this.current_cluster.name;
    },
    customer_name() {
      return this.current_cluster.customer.naam;
    },
    afspraken() {
      if (this.current_cluster.customer.afspraken.length === 0) {
        return 'Geen afspraken';
      }
      return this.current_cluster.customer.afspraken;
    },
    visible: {
      get() {
        return this.$store.state.settings.navigation.adminPanel.show;
      },
      set(value) {
        this.$store.commit('settings/navigation/setAdminPanel', {property: 'show', value: value});
      }
    },
    large: {
      get() {
        return this.$store.state.settings.navigation.adminPanel.large;
      },
      set(value) {
        this.$store.commit('settings/navigation/setAdminPanel', {property: 'large', value: value});
      }
    },
    width() {
      return this.large ? 400 : 250;
    },
    pageHeight() {
      // document.getElementById('page-container').clientHeight;
      return document.body.scrollHeight;
    }
  },

  mounted() {
    // Omdat het AdminPanel ook cross v2/v3 moet werken moet hier een kleine delay in omdat de
    // current_user pas beschikbaar is nadat de hele app gelaad is.
    setTimeout(() => {
      this.fetchCurrentClusterInfo();
    }, 1);

    this.interval = setInterval(() => {
      this.height = document.body.scrollHeight;
    }, 100);
  },
  onDestroyed() {
    clearInterval(this.interval);
  },

  methods: {
    closePanel() {
      this.visible = false;
    },
    fetchCurrentClusterInfo() {
      if (this.current_user.is_admin && this.visible && this.current_user.cluster_id !== this.current_cluster.id) {
        this.loading = true;
        this.$store.dispatch('current_user/fetch_current_cluster_info').then(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    }
  },

  watch: {
    visible(visble) {
      if (visble) {
        this.fetchCurrentClusterInfo();
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "/variables";
#admin-side-panel {
  .no-overflow {
    text-overflow: " (...)";
    overflow: hidden;
    white-space: nowrap;
    color: $medimo-75;
  }
}
</style>
