import {MedicationAgreement} from "@/domain/models/MedicationAgreement";
import {MedicationUse} from "@/domain/models/MedicationUse";
import {AdministrationAgreement} from "@/domain/models/AdministrationAgreement";
import {VariableDosingRegimen} from "@/domain/models/VariableDosingRegimen";
import {store} from "@/vue/store";

export abstract class AbstractMedicationStore {
  public removeFromStore(): void {
    store.commit('mp9/medication_agreements/clearData');
    store.commit('mp9/administration_agreements/clearData');
    store.commit('mp9/variable_dosing_regimens/clearData');
    store.commit('mp9/medication_uses/clearData');
  }

  public addToStore(pharmaceuticalTreatment: any): void {
    this.storeMedicationAgreements(pharmaceuticalTreatment);
    this.storeMedicationUses(pharmaceuticalTreatment);
    this.storeAdministrationAgreements(pharmaceuticalTreatment);
    this.storeVariableDosingRegimens(pharmaceuticalTreatment);
    this.storeDrug(pharmaceuticalTreatment);
  }

  public storeMedicationAgreements(pharmaceuticalTreatment: any): void {
    for (const agreement of pharmaceuticalTreatment['medicationAgreements']) {
      const medicationAgreement = MedicationAgreement.fromJson(agreement);
      store.commit('mp9/medication_agreements/setData', {data: medicationAgreement});
    }
  }

  public storeMedicationUses(pharmaceuticalTreatment: any): void {
    for (const use of pharmaceuticalTreatment['medicationUses']) {
      const medicationUse = MedicationUse.fromJson(use);
      store.commit('mp9/medication_uses/setData', {data: medicationUse});
    }
  }

  public storeAdministrationAgreements(pharmaceuticalTreatment: any): void {
    for (const administration of pharmaceuticalTreatment['administrationAgreements']) {
      const administrationAgreement = AdministrationAgreement.fromJson(administration);
      store.commit('mp9/administration_agreements/setData', {data: administrationAgreement});
    }
  }

  public storeVariableDosingRegimens(pharmaceuticalTreatment: any): void {
    for (const regimen of pharmaceuticalTreatment['variableDosingRegimen']) {
      const variableDosingRegimen = VariableDosingRegimen.fromJson(regimen);
      store.commit('mp9/variable_dosing_regimens/setData', {data: variableDosingRegimen});
    }
  }

  public storeDrug(pharmaceuticalTreatment: any): void {
    if (pharmaceuticalTreatment['drug'] !== null) {
      store.commit('drugs/set_data', [pharmaceuticalTreatment['drug']]);
    }

    if (pharmaceuticalTreatment['drugs']) {
      const drugs = [];
      for (const drug of pharmaceuticalTreatment['drugs']) {
        drugs.push(drug);
      }

      store.commit('drugs/set_data', drugs);
    }
  }
}
