import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';
import {HealthcareProfessionalObject} from '@/domain/models/HealthcareProfessionalObject';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';

export class GeneratedHealthcareProfessional { 
  public id: number;
  public professionalObject: HealthcareProfessionalObject;
  public healthcareProvider: HealthcareProvider | null;

  constructor(id: number, professionalObject: HealthcareProfessionalObject, healthcareProvider: HealthcareProvider | null = null) {
    this.id = id;
    this.professionalObject = professionalObject;
    this.healthcareProvider = healthcareProvider;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      professionalObject: this.professionalObject,
      healthcareProvider: this.healthcareProvider,
    };
  }

  public static fromJson(json: unknown): HealthcareProfessional {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new HealthcareProfessional(
      parsedJson['id'] as number,
      HealthcareProfessionalObject.fromJson(parsedJson['professionalObject']),
      parsedJson['healthcareProvider'] ? HealthcareProvider.fromJson(parsedJson['healthcareProvider']) : null,
    );
  }

  public static multipleFromJson(json: unknown): HealthcareProfessional[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
