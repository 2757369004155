import {store} from '@/vue/store';
import {MedicationOverview} from "@/domain/models/MedicationOverview";
import utility from "@/vue/utility/utility";
import Dates from "@/vue/utility/dates";
import {GroupedMedicationOverviewRow, ObjectOfMedicationOverview, SortedGroupedMedicationOverview} from "@/interfaces/mp9/InstructionInterfaces";
import moment from "moment";
import {TimeFrame} from "@/interfaces/mp9/TimeFrame";

export class MedicationOverviewController {
  private storeName = 'mp9/medication_overview';

  public async read(patientId: number): Promise<MedicationOverview[]|null> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/medication-overview',
    });

    this.setWarningsOnStore(response.data.warnings);
    this.setMedicationOverviewOnStore(response.data.medicationOverview);

    return MedicationOverview.multipleFromJson(response.data.medicationOverview);
  }

  public clearData(): void {
    store.commit(this.storeName + '/set_data', []);
    store.commit(this.storeName + '/set_warnings', []);
  }

  private setMedicationOverviewOnStore(data: unknown): MedicationOverview[] | null {
    store.commit(this.storeName + '/set_data', data);
    return this.dataFromStore();
  }

  private setWarningsOnStore(warnings: unknown): void {
    store.commit(this.storeName + '/set_warnings', warnings);
  }

  public dataFromStore(): MedicationOverview[] | null {
    return store.getters[this.storeName + '/data'];
  }

  public medicationOverviewGrouped() {
    return this.groupMedicationOverview(this.medicationOverview(), 'pharmaceuticalTreatmentId', 'medicationAgreementId');
  }

  public stoppedMedicationOverviewRowsGrouped(timeFrame?: TimeFrame) {
    return this.groupMedicationOverview(this.stoppedMedicationOverview(timeFrame), 'pharmaceuticalTreatmentId', 'medicationAgreementId');
  }

  public medicalDevicesGrouped() {
    return this.groupMedicationOverview(this.medicalDevices(), 'pharmaceuticalTreatmentId', 'medicationAgreementId');
  }

  public medicationUsesGrouped() {
    return this.groupMedicationOverview(this.medicationUses(), 'pharmaceuticalTreatmentId', 'medicationUseId');
  }

  private groupMedicationOverview(medicationOverview: ObjectOfMedicationOverview, firstKey: 'pharmaceuticalTreatmentId', secondKey: 'medicationAgreementId' | 'medicationUseId'): SortedGroupedMedicationOverview {
    const groupedMedicationOverview: GroupedMedicationOverviewRow[] = [];

    const arrayOfRows = utility.convert_object_to_array(medicationOverview) as Array<MedicationOverview>;

    arrayOfRows.forEach(medicationOverview => {
      const indexOne = medicationOverview[firstKey]! ? medicationOverview[firstKey] : 0;
      const indexTwo = medicationOverview[secondKey] !== null ? medicationOverview[secondKey]! : 0;

      if (groupedMedicationOverview[indexOne] === undefined) {
        groupedMedicationOverview[indexOne] = {
          drugName: medicationOverview.drugName,
          usageType: medicationOverview.usageType,
          drugAtc: medicationOverview.drugAtc,
          startDateTime: medicationOverview.startDateTime,
          medications: [],
        };
      }

      if (groupedMedicationOverview[indexOne]!.medications[indexTwo]! === undefined) {
        groupedMedicationOverview[indexOne]!.medications[indexTwo] = {
          startDateTime: medicationOverview.startDateTime,
          endDateTime: medicationOverview.endDateTime,
          rows: [],
        };
      }

      groupedMedicationOverview[indexOne]!.medications[indexTwo]!.rows!.push(medicationOverview);
    });

    const sortedMedicationInsideTreatment = this.sortGroupedMedicationRowsSameTreatment(groupedMedicationOverview);
    return this.sortGroupedMedicationOverview(sortedMedicationInsideTreatment);
  }

  private sortGroupedMedicationRowsSameTreatment(groupedMedicationOverviewRow: GroupedMedicationOverviewRow[]): GroupedMedicationOverviewRow[] {
    // todo mp9 needs an refactor, this is done so we can sort the items after grouped
    // We first sort on start date and after that we sort on end date.
    // This so the medication overview will show grouped medication in the correct order.
    // First the item that is still active and after that the record that follows it up.
    for (const index in groupedMedicationOverviewRow) {
      groupedMedicationOverviewRow[parseInt(index)]!.medications!.sort((a, b) => {
        const startA = new Date(a.startDateTime).getTime();
        const startB = new Date(b.startDateTime).getTime();

        if (startA - startB !== 0) {
          return startA - startB; // Primary sort on startDateTime
        } else {
          const endA = a.endDateTime ? new Date(a.endDateTime).getTime() : null;
          const endB = b.endDateTime ? new Date(b.endDateTime).getTime() : null;

          // Handle cases where endDateTime might be null
          if (endA && endB) {
            return endA - endB; // Secondary sort on endDateTime
          } else if (!endA && endB) {
            return 1; // Place items with null endDateTime after those with a defined endDateTime
          } else if (endA && !endB) {
            return -1; // Place items with defined endDateTime before those with null endDateTime
          } else {
            return 0; // If both are null, they are equal
          }
        }
      });
    }

    return groupedMedicationOverviewRow;
  }

  // Sorten moet hier opnieuw op Pharmaceutical niveau
  private sortGroupedMedicationOverview(groupedMedicationOverview: GroupedMedicationOverviewRow[]): SortedGroupedMedicationOverview {
    const array: SortedGroupedMedicationOverview = [];
    Object.keys(groupedMedicationOverview).forEach(pharmaceuticalID => {
      array.push(groupedMedicationOverview[parseInt(pharmaceuticalID)]!);
    });

    // We moeten na de groepering weer re-sorten
    return utility.sort_data_by_columns(array, store.getters[this.storeName + '/sort_columns']);
  }

  public medicationOverview(showAll = true, showIfStopped = false): ObjectOfMedicationOverview {
    return utility.convert_array_to_object(store.getters[this.storeName + '/sorted_local_data'].filter((medicationOverview: MedicationOverview) => {
      return medicationOverview.isDosingInstruction && this.instructionIsVisible(medicationOverview, true, true, showAll, showIfStopped);
    })) as ObjectOfMedicationOverview;
  }
  public stoppedMedicationOverview(timeFrame?: TimeFrame): ObjectOfMedicationOverview {
    return utility.convert_array_to_object(utility.clone(store.getters[this.storeName + '/sorted_local_data']).filter((medicationOverview: MedicationOverview) => {
      return medicationOverview.isDosingInstruction && this.isStoppedRow(medicationOverview) && this.insideHistoryTimeFrame(medicationOverview.endDateTime, timeFrame);
    })) as ObjectOfMedicationOverview;
  }
  public medicationUses(showAll = true, showIfStopped = false): ObjectOfMedicationOverview {
    return utility.convert_array_to_object(store.getters[this.storeName + '/sorted_local_data'].filter((medicationOverview: MedicationOverview) => {
      return medicationOverview.isMedicationUse && this.instructionIsVisible(medicationOverview, true, true, showAll, showIfStopped);
    })) as ObjectOfMedicationOverview;
  }
  public medicalDevices(showAll = true, showIfStopped = false): ObjectOfMedicationOverview {
    return utility.convert_array_to_object(store.getters[this.storeName + '/sorted_local_data'].filter((medicationOverview: MedicationOverview) => {
      return medicationOverview.isMedicalDevice && this.instructionIsVisible(medicationOverview, true, true, showAll, showIfStopped);
    })) as ObjectOfMedicationOverview;
  }

  public insideHistoryTimeFrame(endDateTime: string, timeFrame?: TimeFrame): boolean {
    if (timeFrame && endDateTime !== '') {
      const maxDays = moment().subtract(timeFrame.days, 'days');
      if (moment(endDateTime).isAfter(maxDays)) {
        return true;
      }
    }

    return false;
  }

  public isStoppedRow(medicationOverviewRow: MedicationOverview): boolean {
    return !!(medicationOverviewRow.endDateTime && moment(medicationOverviewRow.endDateTime).isBefore(moment()));
  }

  public instructionIsVisible(medicationOverview: MedicationOverview, isFirstMedicationOverview: boolean, hasFutureInstructions: boolean, showAll: boolean, showIfStopped: boolean) {
    // Always show temprarily stopped rows
    // Always show when show_if_stopped
    if (medicationOverview.isTemporaryStop || showIfStopped) {
      return true;
    }

    // If stopped in past dont show
    if (medicationOverview.endDateTime && ! Dates.setDate(medicationOverview.endDateTime).isTodayOrFuture()) {
      return false;
    }

    // Always show if it's the first row
    if (isFirstMedicationOverview) {
      return true;
    }

    // Always show if it's part of a schema, and showAll is true
    if (hasFutureInstructions && showAll) {
      return true;
    }

    // When all should be open, return all
    if (!showAll) {
      return false;
    }

    // When it's between the stop and end date
    else if (Dates.nowIsBetweenDates(medicationOverview.startDateTime, medicationOverview.endDateTime)) {
      return true;
    }

    return false;
  }

  /*
   |--------------------------------------------------------------------------
   | Table Sort Methods
   |--------------------------------------------------------------------------
   */
  /**
   * @param sortState Can be either 'start_moment_datetime' or 'ABC'
   */
  public switchSortTo(sortState: string) {
    if (sortState === 'startDate') {
      store.commit(this.storeName + '/sort', {column_name: 'startDate', direction: 'DESC'});
    }
    if (sortState === 'ABC') {
      store.commit(this.storeName + '/sort', {column_name: 'drugName', direction: 'ASC'});
      store.commit(this.storeName + '/sort', {column_name: 'startDate', direction: 'ASC'});
    }
  }
}
