<template>
  <MediModal title="Extra protocol" size="large" @closed="close">
    <MedimoTargetSelector
        :columns="{name: 'Naam'}"
        :searchEndpoint="url"
        :search_on_route_change="false"
        :navigateOnClick="false"
        name="druglist_protocols"
        @triggered="save"
        :storeName="storeName" />
    <template #action-buttons>
      <MedimoButton @click="close" type="outline-primary">Annuleren</MedimoButton>
    </template>
  </MediModal>
</template>

<script setup lang="ts">
import MediModal from '@/vue/components/general/MediModal.vue';
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import {computed} from "vue";
import MedimoTargetSelector from "@/vue/components/selectors/targetselector/MedimoTargetSelector.vue";
import {useRoute} from "vue-router";
import {Protocol} from "@/domain/models/Protocol";
import {store} from "@/vue/store";

const route = useRoute();

const emit = defineEmits(['closed', 'save']);
const storeName = 'druglist_protocols';

interface Item {
  col: number,
  row: number,
}

interface Props {
  item: Item
}

const props = defineProps<Props>();

function save(protocol: Protocol) {
  const values = {
    global: global.value,
    id: protocol.id,
    text: protocol.name,
  };

  emit('save', props.item.row, props.item.col, 10, values);
  close();
}

function close() {
  emit('closed');
}

const quickFilters = computed(() => {
  return store.getters[storeName + '/quick_filters'];
});

const global = computed<boolean>(() => {
  if (!quickFilters.value?.scope) {
    return false;
  }

  return !!quickFilters.value.scope.group.global.value;
});

const url = computed<string>(() => {
  return '/api/v1/drug-list/' + (global.value ? 'global-' : '') + 'protocol/' + route.params.three + '/search';
});

</script>
