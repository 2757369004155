import BaseModule from "@/vue/store/modules/base_module";

// initial state
const state = {
  store_namespace: 'restart',
  ...BaseModule.state,
  data: [],
  sort_states: {
    'ABC': [
      {'column_name': 'drugName', 'direction': 'ASC'},
      {'column_name': 'startDateTime', 'direction': 'ASC'}
    ],
    'ATC': [
      {'column_name': 'drugAtc', 'direction': 'ASC'},
      {'column_name': 'drugName', 'direction': 'ASC'},
      {'column_name': 'startDateTime', 'direction': 'ASC'}
    ],
  },
};

// getters
const getters = {
  sort_states(state, getters, rootState, rootGetters) {
    return (currentSortState) => {
      return state.sort_states[currentSortState];
    };
  },
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
