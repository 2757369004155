import {ProviderSpecificationObject} from '@/domain/models/ProviderSpecificationObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {AddressObject} from '@/domain/models/AddressObject';
import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {ContactObject} from '@/domain/models/ContactObject';

export class GeneratedProviderSpecificationObject { 
  public organizationName: string;
  public organizationType: CodeObject | null;
  public addresses: AddressObject[] | null;
  public identifiers: IdentifierObject[] | null;
  public contact: ContactObject | null;

  constructor(organizationName: string, organizationType: CodeObject | null = null, addresses: AddressObject[] | null = null, identifiers: IdentifierObject[] | null = null, contact: ContactObject | null = null) {
    this.organizationName = organizationName;
    this.organizationType = organizationType;
    this.addresses = addresses;
    this.identifiers = identifiers;
    this.contact = contact;
  }

  public toJSON(): unknown {
    return {
      organizationName: this.organizationName,
      organizationType: this.organizationType,
      addresses: this.addresses,
      identifiers: this.identifiers,
      contact: this.contact,
    };
  }

  public static fromJson(json: unknown): ProviderSpecificationObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new ProviderSpecificationObject(
      parsedJson['organizationName'] as string,
      parsedJson['organizationType'] ? CodeObject.fromJson(parsedJson['organizationType']) : null,
      parsedJson['addresses'] ? AddressObject.multipleFromJson(parsedJson['addresses']) : null,
      parsedJson['identifiers'] ? IdentifierObject.multipleFromJson(parsedJson['identifiers']) : null,
      parsedJson['contact'] ? ContactObject.fromJson(parsedJson['contact']) : null,
    );
  }

  public static multipleFromJson(json: unknown): ProviderSpecificationObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
