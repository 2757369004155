<template>
  <div class="input-group">
    <medimo-labeled-checkbox :class="'h-100 ' + extra_classes"
                             :validation_class="validation_class"
                             :type="type"
                             v-bind:modelValue="modelValue"
                             @update:modelValue="onChecked($event)"
                             :name="name"
                             :label="label"
                             :inline="inline"
                             :placeholder="placeholder"
                             :disabled="disabled"
                             :help_text="help_text"
                             :label_cols="label_cols"
                             :label_position="label_position"
                             :labelFontStyle="labelFontStyle"
                             :label-clickable="labelClickable"
    >
    </medimo-labeled-checkbox>
  </div>
</template>

<script>

import MedimoLabeledCheckbox from './base/MedimoLabeledCheckbox';
import IsValidatedFormComponentMixin from '@/vue/mixins/form/IsValidatedFormComponentMixin';

export default {

  components: {
    MedimoLabeledCheckbox
  },

  emits: ['update:modelValue'],

  props: {
    'modelValue': {default: ''},
    'inline': {default: true},
    'name': {default: ''},
    'label': {default: ''},
    'disabled': {default: false},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'type': {default: 'text'},
    'help_text': {default: ''},
    'label_cols': {},
    'label_position': {default: 'left'},
    'labelFontStyle': {default: 'inherit'},
    'labelClickable': {default: false},
  },

  mixins: [
    IsValidatedFormComponentMixin
  ],

  data: function () {
    //
  },

  computed: {},

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onChecked(value) {
      this.$emit('update:modelValue', value);
    }
  },

  watch: {
    //
  }
};
</script>
