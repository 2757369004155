import Utility from '@/vue/utility/utility';

const state = {};

// getters
const getters = {
  shadow_data(state, getters, rootState) {
    return state.shadow_data;
  },
  find_db_model(state, getters, rootState) {
    return (id) => Utility.find_model_by_id(state.db_data, id);
  },
  find_shadow_model(state, getters, rootState) {
    return (id) => {
      return Utility.find_model_by_id(state.shadow_data, id);
    };
  },
  sorted_db_data(state, getters, rootState, rootGetters) {
    return Utility.sort_data_by_columns(state.db_data, state.sort_columns);
  },
  find_all_shadow_by_prop(state, getters, rootState) {
    return (payload) => Utility.find_models_by_property(state.shadow_data, payload.property, payload.value);
  },
  changes_ahead(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {
      return rootGetters['medication_agreement_changes/change_items_for_instruction'](dosingInstruction).length > 0;
    };
  },
  changes(state, getters, rootState, rootGetters) {
    return (dosageInstruction) => {

      const skips = rootGetters['dosage_instructions/props_skip_on_comparison'];

      const dbModel = getters['find_db_model'](dosageInstruction.id);
      if (!dbModel) {
        return ['Nieuwe regel'];
      } else {
        const changes = [];
        if (dosageInstruction.usage_type === 'S' && rootGetters['manual_dosing_schemas/local_changes_ahead'](dosageInstruction.id)) {
          changes.push('Handmatig schema is gewijzigd');
        }
        const keys = Object.keys(dosageInstruction);
        for (let i=0; i<keys.length; i++) {
          if (!skips.includes(keys[i]) && !Utility.is_equal(dosageInstruction[keys[i]],dbModel[keys[i]])) {
            changes.push({'key': keys[i], 'new':dosageInstruction[keys[i]], 'old':dbModel[keys[i]]});
          }
        }
        return changes;
      }
    };
  },
  shadow_changes_ahead(state, getters, rootState, rootGetters) {
    return (model) => {
      const localModel = getters['find'](model.id);

      if (!localModel) {
        return true;
      }

      const shadowModel = getters['find_shadow_model'](model.id);

      const skips = ['prescription_type'];

      const changes = [];
      const keys = Object.keys(localModel);
      for (let i=0; i<keys.length; i++) {
        if (!skips.includes(keys[i]) && !Utility.is_equal(localModel[keys[i]],shadowModel[keys[i]])) {
          changes.push({'key': keys[i], 'old':localModel[keys[i]], 'new':shadowModel[keys[i]]});
        }
      }

      if (changes.length>0) {
        return true;
      }

      if (shadowModel.usage_type === 'S' && rootGetters['manual_dosing_schemas/manualdosing_shadow_changes_ahead'](model.id)) {
        return true;
      }

      return false;
    };
  },
};

// actions
const actions = {
  async add_with_shadow({state, commit, dispatch, getters, rootState}, model) {
    commit('add_to_data', model);
    commit('add_to_shadow_data', Utility.deep_clone(model));
    commit('add_to_db_data', Utility.deep_clone(model));
  },
  add_with_shadow_multi({state, commit, dispatch, getters, rootState}, models) {
    models.forEach(model => {
      commit('add_to_data', model);
      commit('add_to_shadow_data', Utility.deep_clone(model));
      commit('add_to_db_data', Utility.deep_clone(model));
    });
  },
  set_with_shadow({state, commit, dispatch, getters, rootState}, models) {
    commit('set_data', models);
    commit('set_shadow_data', Utility.deep_clone(models));
    commit('set_db_data', Utility.deep_clone(models));
  },
  set_with_db_and_local_model({state, commit, dispatch, getters, rootState}, models) {
    commit('set_data', models);
    commit('set_db_data', Utility.deep_clone(models));
  },
  store_shadow_changes_multi({state, commit, dispatch, getters, rootState, rootGetters}, dosageInstructions) {
    dosageInstructions.forEach(function (dosageInstruction) {
      const shadowModel = Utility.find_model_by_property(state.shadow_data, 'id', dosageInstruction.id);
      const indexOfModel = Utility.find_index_of_matching_element(state.data, 'id', dosageInstruction.id);
      if (indexOfModel === -1) {
        commit('add_to_data', shadowModel);
      } else {
        state.data[indexOfModel] = Utility.deep_clone(shadowModel);
      }
    });
  },
  clear_shadow_changes_multi({state, commit, dispatch, getters, rootState, rootGetters}, dosageInstructions) {
    dosageInstructions.forEach(function (dosageInstruction) {
      commit('clear_shadow_changes', dosageInstruction.id);
    });
  },
  reset_local_data({state}, notUsedVar) {
    state.data = Utility.deep_clone(state.db_data);
    state.shadow_data = [];
  }
};

// mutations
const mutations = {
  create_shadow_model(state, id) {
    const index = Utility.find_index_of_matching_element(state.data, 'id', id);

    if (index === -1) {
      return;
    }

    //Get store values
    let shadow_data = state.shadow_data;
    //Delete current shadow model
    shadow_data = shadow_data.filter( (item) => { return item.id !== Utility.parse_id(id);});
    //Add new shadow model
    shadow_data.push(Utility.deep_clone(state.data[index]));
    //Update store with new values
    state.shadow_data = shadow_data;
  },
  set_shadow_data(state, new_data) {
    state.shadow_data = new_data;
  },
  set_db_data(state, new_data) {
    state.db_data = new_data;
  },
  update_prop(state, payload) {
    const index = Utility.find_index_of_matching_element(state.data, 'id', payload.id);
    state.data[index][payload.prop] = payload.value;
  },
  update_shadow_data_prop(state, payload) {
    const index = Utility.find_index_of_matching_element(state.shadow_data, 'id', payload.id);
    state.shadow_data[index][payload.prop] = payload.value;
  },
  update_shadow_model(state, updated_model) {
    const existing_index = Utility.find_index_of_matching_element(state.shadow_data, 'id', updated_model.id);
    state.shadow_data[existing_index] = updated_model;
  },
  add_to_shadow_data(state, new_model) {
    new_model = Utility.deep_clone(new_model);
    const new_data = state.shadow_data;

    // If a model with that ID already exists, we just replace it
    if (Utility.id_already_exists(state.shadow_data, new_model.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.shadow_data, 'id', new_model.id);
      state.shadow_data[existing_index] = new_model;
    }
    // If not, we add it to the top.
    else {
      new_data.unshift(new_model);
      state.shadow_data = new_data;
    }
  },
  add_to_db_data(state, new_model) {
    new_model = Utility.deep_clone(new_model);
    const new_data = state.db_data;

    // If an model with that ID already exists, we just replace it
    if (Utility.id_already_exists(state.db_data, new_model.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.db_data, 'id', new_model.id);
      state.db_data[existing_index] = new_model;
    }
    // If not, we add it to the top.
    else {
      new_data.unshift(new_model);
      state.db_data = new_data;
    }
  },
  store_shadow_changes(state, id) {
    //Update local model with shadow changes
    const shadow_index = Utility.find_index_of_matching_element(state.shadow_data, 'id', id);
    const local_index = Utility.find_index_of_matching_element(state.data, 'id', id);

    if (shadow_index === -1) {
      return;
    }

    if (local_index === -1) {
      const new_data = state.data;
      new_data.unshift(Utility.deep_clone(state.shadow_data[shadow_index]));
      state.data = new_data;
    } else {
      state.data[local_index] = Utility.deep_clone(state.shadow_data[shadow_index]);
    }
  },
  clear_all_db_changes(state) {
    state.data = [];
  },
  clear_all_shadow_changes(state) {
    state.shadow_data = [];
  },
  clear_all_local_changes(state) {
    state.data = state.db_data;
  },
  clear_shadow_changes(state, id) {
    const shadow_index = Utility.find_index_of_matching_element(state.shadow_data, 'id', id);
    const local_index = Utility.find_index_of_matching_element(state.data, 'id', id);
    if (local_index !== -1) {
      state.shadow_data[shadow_index] = Utility.deep_clone(state.data[local_index]);
    }
  },
  clear_local_changes(state, id) {
    const local_index = Utility.find_index_of_matching_element(state.data, 'id', id);
    const db_index = Utility.find_index_of_matching_element(state.db_data, 'id', id);
    if (db_index !== -1) {
      state.data[local_index] = Utility.deep_clone(state.db_data[db_index]);
    } else {
      state.data = Utility.delete_model_by_property(state.data, 'id', id);
    }
  },
  delete_local_model(state, id) {
    state.data = Utility.delete_model_by_property(state.data, 'id', id);
  },
  delete_shadow_model(state, id) {
    state.shadow_data = Utility.delete_model_by_property(state.shadow_data, 'id', id);
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
