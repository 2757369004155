import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  quick_filters: {
    'Medicatieafspraken': false,
    'Toedienafspraken': false,
    'Medicatiegebruik': false,
    'Wisselend doseerschema': false,
  },
  sort_columns: [{column_name: 'registration_datetime', direction: 'DESC'}],
};

const actions = { ...BaseModule.actions };
const getters = { ...BaseModule.getters };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
