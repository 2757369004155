import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';
import Quantity from '@/vue/utility/quantity';
import DosageInstruction from '@/vue/packages/dosageInstruction';
import moment from 'moment-mini';
import dosage_instructions_for from './dosage_instructions_for';

// initial state
// shape: [{ id, quantity }]
const state = {
  store_namespace: 'dosage_instructions',

  ...BaseModule.state,

  prescriptionTypes: [
    {value: 1, text: 'Regulier', unrelatedItems: ['manual_dosing_schema_decoded']},
    {value: 2, text: 'Uitgebreid', unrelatedItems: ['manual_dosing_schema_decoded']},
    {value: 3, text: 'Schema', unrelatedItems: ['manual_dosing_schema_decoded']},
    {
      value: 4,
      text: 'Handmatig',
      unrelatedItems: ['times', 'usage_type', 'if_needed', 'dosage_interval']
    },
  ],
  db_data: [],      // Database models
  shadow_data: [],  // Temporary models when editing
  data: [],         // Local updated models
  lastTempId: 0,
  sort_columns: [],
  // Provide easy access to a specific sorting state through a simple string. Allows for quick backend saving as well.
  // You can then quickly loop through these state's columns (since they're arrays, not objects) for the proper sort order.
  sort_states: {
    'ABC': [
      {'column_name': 'drug_name', 'direction': 'ASC'},
      {'column_name': 'start_moment_datetime', 'direction': 'ASC'}
    ],
    'T-ABC': [
      {'column_name': 'usage_type', 'direction': 'ASC'},
      {'column_name': 'drug_name', 'direction': 'ASC'},
      {'column_name': 'start_moment_datetime', 'direction': 'ASC'}
    ],
    'ATC': [
      {'column_name': 'drug_atc', 'direction': 'ASC'},
      {'column_name': 'drug_name', 'direction': 'ASC'},
      {'column_name': 'start_moment_datetime', 'direction': 'ASC'}
    ],
    'T-ATC': [
      {'column_name': 'usage_type', 'direction': 'ASC'},
      {'column_name': 'drug_atc', 'direction': 'ASC'},
      {'column_name': 'drug_name', 'direction': 'ASC'},
      {'column_name': 'start_moment_datetime', 'direction': 'ASC'}
    ],
  },

  cancelTokenSource: false,

  lsp_prescription_info: false
};

// getters
const getters = {
  ...BaseModule.getters,
  ...dosage_instructions_for.getters,
  sort_states(state, getters, rootState, rootGetters) {
    return (currentSortState) => {
      return state.sort_states[currentSortState];
    };
  },
  props_skip_on_comparison() {
    // When checking whether two dosing instructions are equal, some properties should nog be included:
    // TODO refactor - add these to separate meta prop like initial_meta_data
    return ['id', 'cancelled', 'wcia', 'wcia_string', 'temporary_stop', 'no_future_gifts', 'first_gift_not_today', 'warning_actual_dose_on_start_day', 'restart', 'mutation', 'parent_id', 'gift_before_start', 'dds_change_needed', 'dds_change_needed_stop', 'next_package_datetime', 'schema_id', 'if_needed', 'continuous', 'mutation_comment', 'mutation_datetime', 'start_datetime', 'prescription_type', 'stop_canceled', 'initial_meta_data', 'custom_medication_guard_signals'];
  },
  copy(state, getters) {
    return (dosingInstruction) => {
      const copy = Utility.deep_clone(dosingInstruction);
      copy.next_package_datetime = false;
      copy.gift_before_start = false;
      copy.mutation_comment = '';
      copy.dds_change_needed = false;
      copy.dds_change_needed_stop = false;
      copy.restart = false;
      copy.mutation = false;
      copy.wcia = '';
      copy.wcia_string = '';
      //copy.stop_datetime = null;
      copy.mutation_datetime = null;
      copy.initial_meta_data = false;
      copy.canceled = false;
      copy.provisional_status_id = false;
      return copy;
    };
  },
  local_schema_is_valid(state, getters) {
    return (first_dosing_instruction_id) => {
      const dosingInstructions = getters['for_schema'](first_dosing_instruction_id);
      return getters['schema_is_valid'](dosingInstructions);
    };
  },
  shadow_schema_is_valid(state, getters) {
    return (first_dosing_instruction_id) => {
      const dosingInstructions = getters['for_shadow_schema'](first_dosing_instruction_id);
      return getters['schema_is_valid'](dosingInstructions);
    };
  },
  schema_is_valid(state, getters) {
    return (schemaDosingInstructions) => {
      //Elke aanvullende wijziging moet ook daadwerkelijk een wijziging zijn:
      if (!getters['change_for_every_child_parent'](schemaDosingInstructions)) {
        return false;
      }
      //De mutation_datetime moet aansluiten op de start_datetime van de opvolger
      if (!getters['no_gap_between_child_parents'](schemaDosingInstructions)) {
        return false;
      }
      return true;
    };
  },
  change_for_every_child_parent(state, getters) {
    return (dosing_instructions) => {
      for (let i=0; i<dosing_instructions.length-1; i++) {
        const changes = getters['get_changes_between_instructions']({'old': dosing_instructions[i], 'new': dosing_instructions[i+1]});
        if (changes.length === 0) {
          return false;
        }
      }
      return true;
    };
  },
  no_gap_between_child_parents(state, getters) {
    return (dosing_instructions) => {
      for (let i=0; i<dosing_instructions.length-1; i++) {
        const parent = dosing_instructions[i];
        const child = dosing_instructions[i+1];
        if (!Utility.is_equal(parent.mutation_datetime, child.start_moment_datetime)) {
          return false;
        }
      }
      return true;
    };
  },
  valid_schema_properties(state, getters) {
    return (payload) => {
      const dosingInstruction = payload.dosing_instruction;

      const copy = Utility.deep_clone(dosingInstruction);
      copy.start_datetime = dosingInstruction.start_moment_datetime; //For schema items start_datetime === start_moment_datetime
      copy.usage_type = 'C'; //Force usage_type C for schema
      copy.continuous = true; //Force usage_type C for schema
      copy.if_needed = false; //Force if_needed = false for schema
      copy.stop_datetime = null; //Unset stop_datetime for schema
      copy.mutation_datetime = null;
      copy.prescription_type = 3;
      copy.amount = 0;
      copy.next_package_datetime = false;
      copy.gift_before_start = false;
      copy.dds_change_needed = false;
      copy.dds_change_needed_stop = false;
      copy.restart = false;
      copy.schema_id = payload.schema_id;
      return copy;
    };
  },
  successor_has_stop(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {

      let related_dosing_instructions = getters['for_first_dosing_instruction_id'](dosingInstruction.first_dosing_instruction_id);

      related_dosing_instructions = related_dosing_instructions.filter( (related_instruction) => {
        // Moet > zijn ipv >= want bij verschuiven is de STOP gelijk aan de HERSTART, wat dan
        // onterecht zou leiden tot een "successor" die STOP is, terwijl het de voorganger is
        // Een console log hier van de related_dosing_instructions gaf er één terug
        // door de >= was hij dus een match met zichzelf.
        // een nieuwe stop zat/zit in de medication_agreement_changes/change_items_for_instruction
        // Een lastig te ontcijferen gang van zaken, dus voor nu voeg ik de dosingInstruction die gecheckt
        // wordt weer toe aan de array, zodat de logica verder ongewijzigd blijft, maar het wel
        // werkt met verschuiven, _en_ alle andere varianten
        return related_instruction.start_moment_datetime > dosingInstruction.start_moment_datetime;
      });

      // Zie comment block hierboven
      related_dosing_instructions.push(dosingInstruction);

      // Check if there is a stop (temporary/unsaved/saved) in the schema:
      const stopped_instructions = related_dosing_instructions.filter( (dosing_instruction) => {

        // Als er een tijdelijke stop in de chain zit, past het ook niet in de huidige schema view
        if (dosing_instruction.mutation && dosing_instruction.mutation.type === 'S') {
          return true;
        }

        if (rootGetters['medication_agreement_changes/unsaved_stop_for_instruction'](dosing_instruction)) {
          return true;
        }

        return false;
      });

      return stopped_instructions.length > 0;
    };
  },
  using_infusion_interval(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {
      const dosingIntervals = rootGetters['dosage_intervals/all'];
      const selectedDosingInterval = dosingIntervals.filter(dosingInterval => { return dosingInterval.id === dosingInstruction.dosage_interval_id; });
      return !!(selectedDosingInterval.length > 0 && selectedDosingInterval[0].attributes.infusionInterval);
    };
  },
  can_be_changed_to_schema(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {

      // We kunnen niet naar de schema view wijzigen als
      //
      // - er waarden zijn opgeslagen die niet getoond worden in de schema view:
      //   * if_needed of !continuous
      //
      // - er waarden gewijzigd zijn die binnen een schema view niet afzonderlijk gewijzigd kunnen worden:
      //   * other_precriber, external_supply, administration_route, dosing_unit, episodes
      //
      // - het een handmatig schema betreft
      //   * usage_type === 'S'
      //
      // - er een tijdelijke stop ergens in de flow zit
      //
      // - het schema (in concept) gestopt is

      const related_dosing_instructions = getters['for_first_dosing_instruction_id'](dosingInstruction.first_dosing_instruction_id);

      // Update the local model with shadow model, or add it if there was no local model yet
      const index_of = Utility.find_index_of_matching_element(related_dosing_instructions, 'id', dosingInstruction.id);
      if (index_of === -1) {
        related_dosing_instructions.push(dosingInstruction);
      }
      else {
        related_dosing_instructions[index_of] = dosingInstruction;
      }

      if (related_dosing_instructions.length === 0) {
        return false;
      }

      // Check if there is a related invalid schema instruction:
      const first_dosing_instruction = related_dosing_instructions[0];
      const invalid_schema_instructions = related_dosing_instructions.filter( (dosing_instruction) => {
        // Zijn er waarden opgeslagen die niet in de schema view getoond kunnen worden?
        if (dosing_instruction.if_needed || !dosing_instruction.continuous) {
          return true;
        }
        // Zijn er waarden verschillend die niet afzonderlijk gewijzigd kunnen worden?
        const shared_props = ['info', 'other_precriber', 'external_supply', 'administration_route', 'dosing_unit', 'episodes'];
        const changed_shared_props = shared_props.filter( (prop) => {
          return !Utility.is_equal(dosing_instruction[prop], first_dosing_instruction[prop]);
        });
        if (changed_shared_props.length > 0) {
          return true;
        }
        // Is het een handmatig schema, een zonodig dosing instructie of een tijdelijke dosing instructie?
        if (dosing_instruction.usage_type === 'S' || dosing_instruction.usage_type === 'Z' || !dosing_instruction.continuous) {
          return true;
        }
        // Als er een tijdelijke stop in de chain zit, past het ook niet in de huidige schema view
        if (dosing_instruction.temporary_stop) {
          return true;
        }

        // Als er een stop in de chain zit, past het ook niet in de huidige schema view
        if (dosing_instruction.stop_datetime) {
          return true;
        }

        if (getters['using_infusion_interval'](dosing_instruction)) {
          return true;
        }

        if (rootGetters['medication_agreement_changes/unsaved_stop_for_instruction'](dosing_instruction)) {
          return true;
        }

        return false;
      });
      return invalid_schema_instructions.length === 0;
    };
  },
  ward(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {
      const patient = rootGetters['patients/find'](dosingInstruction.patient_id);
      return rootGetters['wards/find'](patient.ward.id);
    };
  },
  min_start_moment_datetime_for_correction(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {
      const minimal_default_6hours_ago = moment().subtract(6, 'hours');

      // Get predecessor (the (unsaved) local is the most updated one)
      // ( Zoek de voorgaande dosing instructie op. De lokale variant is de meest actuele (niet de db variant) )
      const predecessor = getters['predecessor'](dosingInstruction);

      if (predecessor) {
        return getters['min_start_datetime_based_on_predecessor'](predecessor).format('YYYY-MM-DD HH:mm');
      }

      return minimal_default_6hours_ago.format('YYYY-MM-DD HH:mm');
    };
  },
  max_start_moment_datetime_for_correction(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {

      // Get successor (the (unsaved) local is the most updated one)
      // ( Zoek opvolgende doseerinstructie op. De lokale variant is de meest actuele (niet de db variant) )
      const successor = getters['successor'](dosingInstruction.id);

      if (successor) {
        return successor.start_moment_datetime;
      }

      return null;
    };
  },
  /**
   *  Returns a moment() type
   */
  min_start_datetime_based_on_predecessor(state, getters) {
    return (predecessor) => {
      const minimal_default_6hours_ago = moment().subtract(6, 'hours');

      let min_based_on_predecessor = moment(predecessor.start_moment_datetime);
      if (predecessor.temporary_stop) {
        min_based_on_predecessor = moment(predecessor.mutation_datetime);
      }
      if (min_based_on_predecessor > minimal_default_6hours_ago) {
        return min_based_on_predecessor;
      }
      return minimal_default_6hours_ago;
    };
  },
  min_start_moment_datetime(state, getters, rootState, rootGetters) {
    return (dosingInstruction) =>
    {
      let minimal = moment().subtract(6, 'hours');

      // If there is an preceding dosing instruction, update the minimal based on this predecessor
      // (Als er een voorgaande doseer instructie is, dan is de minimale tijd minimaal de minimale tijd gebaseerd op deze voorganger)
      const predecessor = getters['predecessor'](dosingInstruction);
      if (predecessor) {
        // min_based_on_predecessor is a moment() object
        const min_based_on_predecessor = getters['min_start_datetime_based_on_predecessor'](predecessor);
        if (min_based_on_predecessor > minimal) {
          minimal = min_based_on_predecessor;
        }
      }

      // If are changing an existing instruction which is active
      // the minimal start moment datetime (mutation datetime) is the start moment datetime of the original instruction in database
      // For corrections this is handled differently (min_start_moment_datetime_for_correction)
      // (Als we een doseer instructie wijzigen die nu actief is, dan is de minimale start van een nieuwe mutatie, de start van de actieve
      // instructie in de database. Voor correcties is dit overigens niet het geval en gebruiken we (min_start_moment_datetime_for_correction))
      if (!Utility.is_new_id(dosingInstruction.id)) {
        const originalInstruction = getters['find_db_model'](dosingInstruction.id);
        const originalIsActive = originalInstruction.start_moment_datetime < moment().format('YYYY-MM-DD HH:mm');

        if (originalIsActive && minimal < moment(originalInstruction.start_moment_datetime)) {
          minimal = moment(originalInstruction.start_moment_datetime);
        }
      }

      return minimal.format('YYYY-MM-DD HH:mm');
    };
  },
  get_changes_between_instructions(state, getters, rootGetters) {
    return (payload) => {
      const originalInstruction = payload.old;
      const newInstruction = payload.new;
      const changes = [];

      if (originalInstruction.usage_type === 'S' && newInstruction.usage_type === 'S') {
        const originalSchema = rootGetters['manual_dosing_schemas/find_db_model'](originalInstruction.id);
        const newSchema = rootGetters['manual_dosing_schemas/find'](newInstruction.id);
        if (!Utility.is_equal(originalSchema, newSchema)) {
          changes.push({key: 'schema', old: originalSchema, new: newSchema});
        }
      }

      let skips = getters['props_skip_on_comparison'];
      if (originalInstruction.id !== newInstruction.id) {
        skips = skips.concat(['start_moment_datetime']);
      }

      const keys = Object.keys(newInstruction);

      for (let i = 0; i < keys.length; i++) {
        if (!skips.includes(keys[i]) && !Utility.is_equal(newInstruction[keys[i]], originalInstruction[keys[i]])) {
          changes.push({'key': keys[i], 'new': newInstruction[keys[i]], 'old': originalInstruction[keys[i]]});
        }
      }

      return changes;
    };
  },
  encoded_quantity(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {
      const infusionInterval = rootGetters['dosage_intervals/is_infusion_interval'](dosingInstruction.dosage_interval_id);
      if (infusionInterval) {
        return dosingInstruction.infusion_amount;
      }
      else {
        return Quantity.encodeQuantity(dosingInstruction.times);
      }
    };
  },
  started_before_today(state, getters, rootState, rootGetters) {
    return (dosageInstruction) => {
      const startOfDay = moment().startOf('day');
      const startDateTime = moment(dosageInstruction.start_moment_datetime);

      if (startDateTime > startOfDay) {
        return false;
      }
      if (dosageInstruction.stop_datetime && moment(dosageInstruction.stop_datetime, 'YYYY-MM-DD HH:mm') < startOfDay) {
        return false;
      }
      if (dosageInstruction.mutation_datetime && moment(dosageInstruction.mutation_datetime, 'YYYY-MM-DD HH:mm') < startOfDay) {
        return false;
      }

      return true;
    };
  },
  only_stop_is_added(state, getters) {
    return (dosingInstruction) => {

      // If there is no mutation, or it's not an stopped mutation return false;
      if (!dosingInstruction.mutation) {
        return false;
      }
      else if (dosingInstruction.mutation.type !== 'S') {
        return false;
      }

      // Otherwise, it's a stop mutation, but we have to check whether the human dosage has changed as wel:
      const predecessor = getters['find'](dosingInstruction.parent_id);
      if ( predecessor !== null ) {
        return true;
      }
      else {
        return false;
      }
    };
  },
  active(state, getters, rootState, rootGetters) {
    return (dosageInstruction) => {

      const successor = getters['successor'](dosageInstruction.id);
      if (successor && getters['only_stop_is_added'](successor)) {
        return getters['active'](successor);
      }

      const now = moment();
      const startDateTime = moment(dosageInstruction.start_moment_datetime);

      if (startDateTime > now) {
        return false;
      }
      if (dosageInstruction.stop_datetime && moment(dosageInstruction.stop_datetime, 'YYYY-MM-DD HH:mm') < now) {
        return false;
      }
      if (dosageInstruction.mutation_datetime && moment(dosageInstruction.mutation_datetime, 'YYYY-MM-DD HH:mm') < now) {
        return false;
      }

      return true;
    };
  },
  editable(state, getters) {
    return (dosingInstruction) => {
      // New instructions are always editable
      if (Utility.is_new_id(dosingInstruction.id)) {
        return true;
      }
      // Otherwise it's not editable if it's the dosing instruction stored in db is active or in past
      else {
        const db_instruction = getters['find_db_model'](dosingInstruction.id);

        if (getters['in_past'](db_instruction)) {
          return false;
        }

        if (getters['started_before_today'](db_instruction)) {
          return false;
        }
      }
      return true;
    };
  },
  in_past(state, getters, rootState, rootGetters) {
    return (dosageInstruction) => {
      const now = moment();
      if (dosageInstruction.stop_datetime && moment(dosageInstruction.stop_datetime, 'YYYY-MM-DD HH:mm') < now) {
        return true;
      }
      if (dosageInstruction.mutation_datetime && moment(dosageInstruction.mutation_datetime, 'YYYY-MM-DD HH:mm') < now) {
        return true;
      }
      return false;
    };
  },
  changes_ahead_for_agreement(state, getters, rootState) {
    return (medicationAgreementId) => {
      const models = Utility.find_models_by_property(state.data, 'medication_agreement_id', Utility.parse_id(medicationAgreementId));
      for (let i = 0; i < models.length; i++) {
        const db_model = Utility.find_model_by_property(state.db_data, 'id', Utility.parse_id(models[i].id));
        if (!db_model || !Utility.is_equal(db_model, models[i])) {
          return true;
        }
      }
      return false;
    };
  },
  find_all(state) {
    return (dosingInstructionIds) => {
      return state.data.filter((dosingInstruction) => {
        return dosingInstructionIds.includes(dosingInstruction.id);
      });
    };
  },
  for_medication_agreement(state, getters, rootState) {
    return (medicationAgreementId) => Utility.find_models_by_property(state.data, 'medication_agreement_id', medicationAgreementId);
  },
  human_dosage_string(state, getters, rootState, rootGetters) {
    return (dosingInstruction, patient) => {

      const quantity = Quantity.encodeQuantity(dosingInstruction.times);
      if (dosingInstruction.wcia_string !== '') {
        return dosingInstruction.wcia_string;
      }

      const infusionInterval = rootGetters['dosage_intervals/is_infusion_interval'](dosingInstruction.dosage_interval_id);

      if (infusionInterval) {
        const interval = rootGetters['dosage_intervals/find'](dosingInstruction.dosage_interval_id);
        const dosingUnit = rootGetters['dosage_units/find'](dosingInstruction.dosing_unit);
        return dosingInstruction.infusion_amount + ' ' + dosingUnit.text + ' ' + interval.text;
      }

      const standardTimes = rootGetters['wards/default_times'](patient.ward.id);

      let timeValues = dosingInstruction.times;
      if (typeof timeValues === 'undefined') {
        timeValues = Quantity.decodeQuantity(dosingInstruction.quantity);
      }
      let dosage = '';
      if (dosingInstruction.if_needed) {
        dosage += 'Zo nodig ';
      } // Always show dosage

      if (dosingInstruction.usage_type === 'S' || dosingInstruction.prescription_type === 4) {
        dosage += 'Handmatig schema';
      } else {
        dosage += DosageInstruction.getHumanTimeValues(timeValues, standardTimes);
        const dosingUnit = rootGetters['dosage_units/find'](dosingInstruction.dosing_unit);
        dosage += ' ' + dosingUnit.text;
      }

      const administrationRoute = dosingInstruction.administration_route;
      if (administrationRoute && administrationRoute !== dosingInstruction.defaultDrugAdministrationRoute) {
        dosage += ', ' + administrationRoute.toLowerCase();
      } // Only print dosage interval if not daily

      const dosageInterval = rootGetters['dosage_intervals/find'](dosingInstruction.dosage_interval_id);

      if (dosageInterval && dosageInterval.text !== 'dagelijks') {
        dosage += ', ' + dosageInterval.text;
      } //return dosage.replace(/^[,\s]+|[,\s]+$/g, ''); // + ' gebruik'

      return dosage;
    };
  },
  prescription_types(state, getters, rootState) {
    return () => state.prescriptionTypes;
  },
  predecessor(state, getters, rootState) {
    return (dosingInstruction) => {

      if (!dosingInstruction.parent_id) {
        return false;
      }

      const parent = Utility.find_model_by_property(state.data, 'id', dosingInstruction.parent_id);
      if (parent) {
        return parent;
      }

      //Edge case: missing id because of cancel
      let relatedDosingInstructions = getters['for_first_dosing_instruction_id'](dosingInstruction.first_dosing_instruction_id);
      relatedDosingInstructions = relatedDosingInstructions.filter( (relatedInstruction) => {
        return (relatedInstruction.start_moment_datetime < dosingInstruction.start_moment_datetime);
      });

      relatedDosingInstructions.sort( (a,b) => {
        return (a.start_moment_datetime > b.start_moment_datetime) ? -1 : 1;
      });

      if (relatedDosingInstructions.length > 0) {
        return relatedDosingInstructions[0];
      }
      return false;
    };
  },
  shadow_predecessor(state, getters, rootState) {
    return (dosingInstruction) => {

      if (!dosingInstruction.parent_id) {
        return false;
      }

      const parent = Utility.find_model_by_property(state.shadow_data, 'id', dosingInstruction.parent_id);
      if (parent) {
        return parent;
      }

      //Edge case: missing id because of cancel
      let relatedDosingInstructions = getters['for_shadow_first_dosing_instruction_id'](dosingInstruction.first_dosing_instruction_id);
      relatedDosingInstructions = relatedDosingInstructions.filter( (relatedInstruction) => {
        return (relatedInstruction.start_moment_datetime < dosingInstruction.start_moment_datetime);
      });

      relatedDosingInstructions.sort( (a,b) => {
        return (a.start_moment_datetime > b.start_moment_datetime) ? -1 : 1;
      });

      if (relatedDosingInstructions.length > 0) {
        return relatedDosingInstructions[0];
      }
      return false;
    };
  },
  successor(state, getters, rootState) {
    return (id) => {

      const dosingInstruction = Utility.find_model_by_id(state.data, id);
      if (!dosingInstruction) {
        return false;
      }
      const successor = Utility.find_model_by_property(state.data, 'parent_id', dosingInstruction.id);
      if (successor) {
        return successor;
      }

      //Edge case: missing id because of cancel
      let relatedDosingInstructions = getters['for_first_dosing_instruction_id'](dosingInstruction.first_dosing_instruction_id);

      relatedDosingInstructions = relatedDosingInstructions.filter( (relatedInstruction) => {
        return (relatedInstruction.start_moment_datetime > dosingInstruction.start_moment_datetime);
      });

      relatedDosingInstructions.sort( (a,b) => {
        return (a.start_moment_datetime < b.start_moment_datetime) ? -1 : 1;
      });

      if (relatedDosingInstructions.length > 0) {
        return relatedDosingInstructions[0];
      }
      return false;
    };
  },
  pending_provisional(state, getters, rootState) {
    return (dosingInstruction) => {

      if (dosingInstruction.initial_meta_data === false) {
        return false;
      }
      else if (dosingInstruction.initial_meta_data.pending_provisional === false) {
        return false;
      }
      return dosingInstruction.initial_meta_data.pending_provisional;
    };
  },
  for_db_schema(state, getters, rootState) {
    return (schemaId) => Utility.find_models_by_property(state.db_data, 'schema_id', schemaId);
  },
  for_db_first_dosing_instruction_id(state, getters, rootState) {
    return (first_dosing_instruction_id) => Utility.find_models_by_property(state.db_data, 'first_dosing_instruction_id', first_dosing_instruction_id);
  },
  for_shadow_first_dosing_instruction_id(state, getters, rootState) {
    return (first_dosing_instruction_id) => Utility.find_models_by_property(state.shadow_data, 'first_dosing_instruction_id', first_dosing_instruction_id);
  },
  for_first_dosing_instruction_id(state, getters, rootState) {
    return (first_dosing_instruction_id) => Utility.find_models_by_property(state.data, 'first_dosing_instruction_id', first_dosing_instruction_id);
  },
  actives_for_first_dosing_instruction_id(state, getters, rootState) {
    return (firstDosingInstructionId) => {
      const instructions = Utility.find_models_by_property(state.data, 'first_dosing_instruction_id', firstDosingInstructionId);
      return instructions.filter((instruction) => { return !getters['in_past'](instruction); });
    };
  }
};

// actions
const actions = {
  create_shadow_model_for_schema({state, commit, dispatch, getters, rootState, rootGetters}, first_dosing_instruction_id) {

    const dosingInstructions = getters['for_schema'](first_dosing_instruction_id);

    dosingInstructions.forEach((dosingInstruction) => {
      commit('create_shadow_model', dosingInstruction.id);
    });

    commit('medication_agreements/create_shadow_model', dosingInstructions[0].medication_agreement_id, {root: true});

    return true;
  },
  extend_backend_inputs({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstructions) {
    // Voor de toekomstige gelukkige:
    // JSON Data die mee komt vanuit de MedicationService is vaak nog niet as-is bruikbaar met
    // reeds bestaande componenten zoals de DosageInstructionRow
    // zo mist bijv de `times` - deze data moet dus toegevoegd worden, en dat gebeurt hier
    // Zorg dat je de patient in de patient store hebt zitten, de dosage_intervals gevuld zijn
    // en de ward van de patient in de wards store zit..
    dosingInstructions.forEach((dosingInstruction) => {

      // Skippen we omdat het een MP9 Resource is, die heeft zn eigen component en data
      if (dosingInstruction.mp9) {
        return;
      }

      if (dosingInstruction.wcia_string !== '') {

        const customSignal =
          {
            title: 'Oorspronkelijke dosering',
            subTitle: dosingInstruction.wcia_string,
            message: '',
            patientId: dosingInstruction.patient_id,
          };

        dosingInstruction.custom_medication_guard_signals = [customSignal];
      }

      if (dosingInstruction.times) { //times include time and values (like quantityTimings)
        return dosingInstruction;
      }

      const patient = rootGetters['patients/find'](dosingInstruction.patient_id);
      const infusionInterval = rootGetters['dosage_intervals/is_infusion_interval'](dosingInstruction.dosage_interval_id);

      let times = [];
      if (!infusionInterval) {
        times = Quantity.decodeQuantity(dosingInstruction.quantity);
      }

      const standardTimes = rootGetters['wards/default_times'](patient.ward.id);
      const notStandardTimes = times.filter( (timeValue) => {
        return timeValue.time !== "" && !standardTimes.includes(timeValue.time);
      });

      if (notStandardTimes.length === 0) {
        dosingInstruction.times = standardTimes.map( (standardTime) => {
          const entry = Utility.find_model_by_property(times, 'time', standardTime);
          const value = entry ? entry.value : '0';
          return {time: standardTime, value: value};
        });
      }
      else {
        dosingInstruction.times = times;
      }

      if (dosingInstruction.schema_id) {
        dosingInstruction.prescription_type = 3;
      }
      else if (dosingInstruction.usage_type === 'S') {
        dosingInstruction.prescription_type = 4;
      } else {
        if (notStandardTimes.length === 0) {
          dosingInstruction.prescription_type = 1;
        } else {
          dosingInstruction.prescription_type = 2;
        }
      }

      // Check if we have pre defined times with value, but without time.
      // If so we show the advanced screen, else the validation will
      // throw an error that the filled in times are invalid.
      const checkForEmptyTimes = times.filter((time) => {
        return time.time === '' && time.value !== '0';
      });

      if (checkForEmptyTimes.length > 0 && times.length !== checkForEmptyTimes.length) {
        dosingInstruction.prescription_type = 2;
      }
    });
    return dosingInstructions;
  },
  create_temporary({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    const dosingInstruction = Utility.deep_clone(payload.dosingInstruction);

    //If last page is lsp, add lsp prescription info as custom medication guard signal
    const current_route = rootGetters['routes/current_route'];
    if (current_route && current_route.includes('create-from-lsp') && state.lsp_prescription_info) {
      dosingInstruction.custom_medication_guard_signals = [state.lsp_prescription_info];
    }

    const tempId = state.lastTempId + 1;
    state.lastTempId = tempId;
    const newTempId = 'new-' + tempId;

    if (payload.manualDosingSchema) {
      const manualDosingSchema = Utility.deep_clone(payload.manualDosingSchema);
      manualDosingSchema.id = newTempId;
      commit('manual_dosing_schemas/add_to_shadow_data', manualDosingSchema, {root: true});
    }

    dosingInstruction.id = newTempId;
    if (!dosingInstruction.first_dosing_instruction_id) {
      dosingInstruction.first_dosing_instruction_id = newTempId;
    }

    dispatch('extend_backend_inputs', [dosingInstruction])
      .then((dosingInstructions) => {
        commit('add_to_shadow_data', dosingInstructions[0]);
      });

    return newTempId;
  },
  ...BaseModule.actions,
  create({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {

    dosingInstruction = Utility.deep_clone(dosingInstruction);

    const tempId = state.lastTempId + 1;
    state.lastTempId = tempId;
    const newTempId = 'new-' + tempId;

    dosingInstruction.id = newTempId;

    dispatch('extend_backend_inputs', [dosingInstruction])
      .then((dosingInstructions) => {
        commit('add_to_data', dosingInstructions[0]);
      });

    return newTempId;
  },
  set_with_db_and_local_model_extended({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstructions) {
    //Clear stores
    state.data = [];
    state.db_data = [];

    return dispatch('extend_backend_inputs', dosingInstructions).then(dosingInstructions => {
      commit('set_data', Utility.deep_clone(dosingInstructions));
      commit('set_db_data', dosingInstructions);
    });
  },
  set_with_db_extended({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstructions) {
    //Clear store
    state.db_data = [];

    return dispatch('extend_backend_inputs', dosingInstructions).then(dosingInstructions => {
      commit('set_db_data', dosingInstructions);
    });
  },
  get_date_info({state, getters, dispatch}, payload) {
    const dosingInstruction = payload.dosing_instruction;
    let minStartDateTime = payload.force_min_date;
    if (!minStartDateTime) {
      minStartDateTime = getters['min_start_moment_datetime'](dosingInstruction);
    }
    const endpoint = '/api/v1/patients/' + dosingInstruction.patient_id + '/dosage-instruction/backend-check/get-date-info';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: {min_start_datetime: minStartDateTime}
    }, {root: true});
  },
  add_or_update_shadow_data({state, commit}, dosingInstruction) {
    const index = Utility.find_index_of_matching_element(state.shadow_data, 'id', dosingInstruction.id);
    if (index === -1) {
      commit('add_to_shadow_data', dosingInstruction);
    }
    else {
      state.shadow_data[index] = dosingInstruction;
    }
  },
  process_backend_adds({state, dispatch, commit}, dosingInstructions) {
    dispatch('extend_backend_inputs', dosingInstructions)
      .then((dosingInstructions) => {
        dosingInstructions.forEach( (dosingInstruction) => {
          commit('add_to_data', dosingInstruction);
          commit('add_to_db_data', dosingInstruction);
        });
      });
  },
  set_quantity_value({state, commit, dispatch, getters, rootState}, payload) {
    const index = payload.index;
    const value = payload.value;
    const dosageInstruction = payload.dosageInstruction;

    const updated = Utility.deep_clone(dosageInstruction.times);
    updated[index].value = value;

    dosageInstruction.times = updated; // Kan hier normaal en shadow zijn, gaat goed in Vue3
  },
  fetch_manual_dosing_schema({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/manualdosingschema/' + payload.dosing_instruction_id;
    const vm = this;
    return dispatch('api/getEndpoint', {
      endpoint: endpoint
    }, {root: true}).then(response => {
      return response.data;
    });
  },
  check_first_gift({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/dosage-instruction/backend-check/validate-first-gift';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      return response.data;
    });
  },
  need_change_in_dds({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/dosage-instruction/backend-check/need-change-in-dds';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      return response.data;
    }).catch(error => {
      // Hier kan evt iets gedaan worden
      // Voor nu leeg om uncatched HTTP status errors te voorkomen
    });
  },
  check_actual_dose_on_start_day({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/dosage-instruction/backend-check/check-actual-dose-on-start-day';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      return response.data;
    });
  },
  calculate_amount({state, commit, dispatch, getters, rootState}, payload) {
    //cancel previous request
    if (state.cancelTokenSource !== false) {
      state.cancelTokenSource.cancel();
    }
    const CancelToken = axios.CancelToken;
    state.cancelTokenSource = CancelToken.source();

    const endpoint = '/api/v1/dosage-instruction/calculate/amount';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
      config: {
        cancelToken: state.cancelTokenSource.token,
      }
    }, {root: true});
  },
  calculate_stopdatetime({state, commit, dispatch, getters, rootState}, payload) {

    //cancel previous request
    if (state.cancelTokenSource !== false) {
      state.cancelTokenSource.cancel();
    }
    const CancelToken = axios.CancelToken;
    state.cancelTokenSource = CancelToken.source();

    const endpoint = '/api/v1/dosage-instruction/calculate/stopdatetime';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
      config: {
        cancelToken: state.cancelTokenSource.token,
      }
    }, {root: true});
  },
  convert_shadow_instruction_to_schema({state, commit, dispatch, getters, rootState}, payload) {
    const dosingInstruction = payload.dosing_instruction;
    const newSchemaId = payload.new_schema_id;

    const schemaDosingInstruction = getters['valid_schema_properties']({dosing_instruction: dosingInstruction, schema_id:  newSchemaId});
    commit('update_shadow_model', schemaDosingInstruction);
  },
  convert_concept_instruction_to_schema({state, commit, dispatch, getters, rootState}, firstDosingInstructionId) {

    // Deze functie wordt gebruikt om een aantal nieuwe concept doseerinstructies om te zetten naar een nieuwe concept schema
    const relatedDosingInstructions = getters['for_first_dosing_instruction_id'](firstDosingInstructionId);

    if (relatedDosingInstructions.length < 1) { return; }

    relatedDosingInstructions.forEach( (dosingInstruction) => {

      const newSchemaId = firstDosingInstructionId;
      // Regels die tot een schema behoren zijn altijd continu en dagelijks doseerinterval (usage_type = C en niet zonodig) en hebben een start datetime gelijk
      // aan de start moment datetime (dit is bij niet schema regels niet altijd zo)
      dispatch('convert_shadow_instruction_to_schema', {dosing_instruction: dosingInstruction, new_schema_id: newSchemaId})
        .then( () => {
          const schemaDosingInstruction = getters['valid_schema_properties']({dosing_instruction: dosingInstruction, schema_id: newSchemaId});
          commit('update_shadow_model', schemaDosingInstruction);
        });
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_lsp_prescription_info(state, info) {
    state.lsp_prescription_info = info;
  },
  cancel(state, id) {
    const index = Utility.find_index_of_matching_element(state.data, 'id', id);
    const dosingInstruction = state.data[index];
    dosingInstruction.canceled = true;
    dosingInstruction.cancelReason = 'TODO';
    state.data[index] = dosingInstruction;
  },
  undo_cancel(state, id) {
    const index = Utility.find_index_of_matching_element(state.data, 'id', id);
    const dosingInstruction = state.data[index];
    dosingInstruction.canceled = false;
    dosingInstruction.cancelReason = '';
    state.data[index] = dosingInstruction;
  },
  update_shadow_data(state, dosingInstruction) {
    const index = Utility.find_index_of_matching_element(state.shadow_data, 'id', dosingInstruction.id);
    state.shadow_data[index] = dosingInstruction;
  },
  delete(state, id) {
    const new_data = state.data;

    //Delete shadow data, db data, local data
    state.shadow_data = Utility.delete_model_by_property(state.shadow_data, 'id', id);

    state.db_data = Utility.delete_model_by_property(state.db_data, 'id', id);

    state.data = Utility.delete_model_by_property(state.data, 'id', id);
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
