import api from './modules/api';
import jira_issues from './modules/jira_issues';
import notifications from './modules/notifications';
import routes from './modules/routes/routes';
import current_user from './modules/current_user';
import patients from './modules/patients';

import users from './modules/users';
import target_permissions from './modules/permissions/target';
import target_cooperations from './modules/permissions/target_cooperations';
import all_security_groups from './modules/permissions/security_groups';
import user_security_groups from './modules/user/security_groups';
import user_permissions from './modules/user/permissions';
import all_permissions from './modules/user/permissions';
import user_security_group_members from './modules/user/security_group_members';
import security_group_logs from './modules/permissions/security_group_logs';
import security_group_members from './modules/permissions/security_group_members';

import clusters from './modules/clusters';
import config_patient_temporary_absences from './modules/config/patient/temporary_absences';
import config_patient_ward_assignments from './modules/config/patient/ward_assignments';
import locations from './modules/locations';
import drugs from './modules/drugs';
import druglist_protocols from './modules/druglist_protocols';
import dosage_instructions from './modules/dosage_instructions';
import dosage_units from './modules/dosage_units';
import dosage_intervals from './modules/dosage_intervals';
import medication_treatments from './modules/medication_treatments';
import medication_agreements from './modules/medication_agreements';
import administration_agreements from './modules/administration_agreements';
import medication_agreement_changes from './modules/medication_agreement_changes';
import medication_guard_signals from './modules/medication_guard_signals';
import medication_agreement_change_reasons from './modules/medication_agreement_change_reasons';
import indications from './modules/patient/indications';
import patient_provisionals from './modules/patient/patient_provisionals';
import dispense_settings from './modules/patient/dispenses/dispense_settings';
import dispense_schema_templates from './modules/patient/dispenses/dispense_schema_templates';
import indications_zindex from './modules/indications_zindex';
import intolerances from './modules/patient/intolerances';
import intolerances_zindex from './modules/intolerances_zindex';
import episodes from './modules/patient/episodes';
import episodes_zindex from './modules/episodes_zindex';
import readings from './modules/patient/readings';
import dispenses from './modules/patient/dispenses/dispenses';
import readings_bepalingen from './modules/readings_bepalingen';
import pharmacies from './modules/pharmacies';
import pharmacy_list_import from './modules/pharmacy_list_import';
import pharmacy_patient_import from './modules/pharmacy_patient_import';
import wards from './modules/wards';
import ward_mutations from './modules/ward/ward_mutations';
import administration_routes from './modules/administration_routes';
import authorize_provisional from './modules/authorize_provisional';
import unread_readings from './modules/unread_readings';
import manual_dosing_schemas from './modules/manual_dosing_schemas';
import initial_data from './modules/initial_data';
import navigation from './modules/settings/navigation';
import errors from './modules/settings/errors';
import breadcrumbs from './modules/settings/breadcrumbs';
import data_tables from './modules/settings/data_tables';
import modals from './modules/settings/modals';
import report_settings from './modules/settings/reports';
import patient_formulary from './modules/patient/drugs/formulary';
import patient_taxe from './modules/patient/drugs/taxe';
import bem from './modules/patient/bem';
import optin from './modules/patient/optin';
import bsn from './modules/patient/bsn';
import patient_import from './modules/patient/import';
import patient_mutations from './modules/patient/patient_mutations';
import patient_chats from './modules/patient/patient_chats';
import patient_search from './modules/patient/search';
import log_thrombosis from './modules/patient/log_thrombosis';
import chats from './modules/chats.js';
import actions from './modules/actions';
import active_actions from './modules/active_actions';
import sentry from './modules/sentry';
import status_pending_links from './modules/status_pending_links';
import nen7513_anomalies from './modules/nen7513/anomalies/nen7513_anomalies';
import nen7513_logs from './modules/nen7513/nen7513_logs';
import tdr_dashboard from './modules/patient/dashboards/tdr_dashboard';
import vaccinations from './modules/cluster/vaccinations';
import cluster_actions from './modules/cluster/actions';
import vaccination_actions from './modules/cluster/vaccination_actions';
import log from './modules/log';
import log_bsn from './modules/log_bsn';
import log_edi from './modules/log_edi';
import log_import from './modules/log_import';
import log_fax from './modules/log_fax';
import lsp_log from './modules/lsp_log';
import mp9_log from './modules/mp9_log';
import log_meetwaarden from './modules/log_meetwaarden';
import log_mobile_app from './modules/log_mobile_app';
import log_naw from './modules/log_naw';
import log_notification from './modules/log_notification';
import log_query from './modules/log_query';
import log_sms from './modules/log_sms';
import log_sso from './modules/log_sso';
import log_tdr_event_queue from './modules/log_tdr_event_queue';
import log_webservices from './modules/log_webservices';
import log_tromb from './modules/log_tromb';
import lsp_query from './modules/lsp_query';
import lsp_token from './modules/lsp_token';
import lsp_inschrijf_token from './modules/lsp_inschrijf_token';
import websocket from './modules/application/websocket';
import online_check from './modules/application/online_check';
import patient_medication_evaluation from './modules/patient/medication_evaluation/patient_medication_evaluation';
import medication_evaluation_readings from './modules/patient/medication_evaluation/medication_evaluation_readings';
import medication_evaluation from './modules/medication_evaluation';
import local_data_table from './modules/local_data_table';
import report_local_data_table1 from '@/vue/store/modules/reports_local/report_local_data_table1';
import report_local_data_table2 from '@/vue/store/modules/reports_local/report_local_data_table2';
import report_local_data_table3 from '@/vue/store/modules/reports_local/report_local_data_table3';
import report_local_data_table4 from '@/vue/store/modules/reports_local/report_local_data_table4';
import report_local_data_table5 from '@/vue/store/modules/reports_local/report_local_data_table5';
import report_local_data_table6 from '@/vue/store/modules/reports_local/report_local_data_table6';
import emergency_location from './modules/stock/emergency_location';
import emergency_stock from './modules/stock/emergency_stock';
import patient_stock from './modules/stock/patient';
import ward_stock from './modules/stock/ward';
import stock_record from './modules/stock/stock_record';
import stock_record_detail from './modules/stock/stock_record_detail';
import stock_record_detail_reservation from './modules/stock/stock_record_detail_reservation';
import stock_record_detail_history from './modules/stock/stock_record_detail_history';
import reports from './modules/reports';
import reports_selector from './modules/reports_selector';
import pharmacy_return from './modules/stock/pharmacy_return';
import provisional_drug_request from './modules/provisional_drug_request';
import medication_uses from "./modules/mp9/medication_uses";
import mp9_medication_agreements from "./modules/mp9/medication_agreements";
import mp9_administration_agreements from "./modules/mp9/administration_agreements";
import patient_selector from './modules/selectors/patient_selector';
import mutations_patient_selector from './modules/selectors/mutations_patient_selector';
import target_selector from './modules/selectors/target_selector';
import escalation_settings from './modules/escalation_settings';
import protocols from './modules/protocols';
import patients_protocol from './modules/patients_protocol';

import medimo_local_data_table_data from './modules/example_data/medimo_local_data_table_data';
import medimo_external_data_table_data from './modules/example_data/medimo_external_data_table_data';
import initial_data_mp9 from "./modules/mp9/initial_data";
import medimo_table_data from './modules/example_data/medimo_table_data';
import medication_overview from "@/vue/store/modules/mp9/medication_overview";
import variable_dosing_regimens from "@/vue/store/modules/mp9/variable_dosing_regimens";
import settings from "@/vue/store/modules/mp9/settings";
import mutation_history from "@/vue/store/modules/mp9/mutation_history";
import mutation_history_treatment from "@/vue/store/modules/mp9/mutation_history_treatment";
import dispense_history from "@/vue/store/modules/mp9/dispense_history";
import multi_stop from "@/vue/store/modules/mp9/multi_stop";
import restart from "@/vue/store/modules/mp9/restart";
import medication_dispenses from "@/vue/store/modules/mp9/medication_dispenses";
import mobile_app from "@/vue/store/modules/mobile_app";

import administration_areas from "@/vue/store/modules/patient/drug_settings/administration_areas";
import blockschemes from "@/vue/store/modules/patient/drug_settings/blockschemes";
import dds_suppress from "@/vue/store/modules/patient/drug_settings/dds_suppress";
import only_give_assigned from "@/vue/store/modules/patient/drug_settings/only_give_assigned";
import own_management from "@/vue/store/modules/patient/drug_settings/own_management";
import redflags from "@/vue/store/modules/patient/drug_settings/redflags";

const options = {

  namespaced: true,

  modules: {
    api: api,

    jira_issues: jira_issues,
    notifications: notifications,
    routes: routes,
    current_user: current_user,
    users: users,
    patients: patients,
    clusters: clusters,
    locations: locations,
    drugs: drugs,
    druglist_protocols: druglist_protocols,
    dosage_instructions: dosage_instructions,
    dosage_units: dosage_units,
    dosage_intervals: dosage_intervals,
    administration_agreements: administration_agreements,
    medication_treatments: medication_treatments,
    medication_agreements: medication_agreements,
    medication_agreement_changes: medication_agreement_changes,
    medication_guard_signals: medication_guard_signals,
    medication_agreement_change_reasons: medication_agreement_change_reasons,
    indications: indications,
    indications_zindex: indications_zindex,
    intolerances: intolerances,
    intolerances_zindex: intolerances_zindex,
    episodes: episodes,
    episodes_zindex: episodes_zindex,
    readings: readings,
    readings_bepalingen: readings_bepalingen,
    pharmacies: pharmacies,
    pharmacy_list_import: pharmacy_list_import,
    pharmacy_patient_import: pharmacy_patient_import,
    wards: wards,
    administration_routes: administration_routes,
    authorize_provisional: authorize_provisional,
    unread_readings: unread_readings,
    manual_dosing_schemas: manual_dosing_schemas,
    bem: bem,
    optin: optin,
    bsn: bsn,
    patient_import: patient_import,
    patient_chats: patient_chats,
    chats: chats,
    actions: actions,
    active_actions: active_actions,
    initial_data: initial_data,
    status_pending_links: status_pending_links,
    administration_areas: administration_areas,
    blockschemes: blockschemes,
    dds_suppress: dds_suppress,
    only_give_assigned: only_give_assigned,
    own_management: own_management,
    redflags: redflags,
    log: log,
    log_bsn: log_bsn,
    log_edi: log_edi,
    log_import: log_import,
    log_fax: log_fax,
    log_meetwaarden: log_meetwaarden,
    log_mobile_app: log_mobile_app,
    log_naw: log_naw,
    log_notification: log_notification,
    log_query: log_query,
    log_sms: log_sms,
    log_sso: log_sso,
    log_tdr_event_queue: log_tdr_event_queue,
    log_tromb: log_tromb,
    log_thrombosis: log_thrombosis,
    log_webservices: log_webservices,
    lsp_log: lsp_log,
    mp9_log: mp9_log,
    lsp_query: lsp_query,
    lsp_token: lsp_token,
    lsp_inschrijf_token: lsp_inschrijf_token,
    sentry: sentry,
    nen7513_anomalies: nen7513_anomalies,
    nen7513_logs: nen7513_logs,
    patient_medication_evaluation: patient_medication_evaluation,
    medication_evaluation: medication_evaluation,
    medication_evaluation_readings: medication_evaluation_readings,
    local_data_table: local_data_table,
    reports: reports,
    reports_selector: reports_selector,
    provisional_drug_request: provisional_drug_request,
    escalation_settings: escalation_settings,
    protocols: protocols,
    patients_protocol: patients_protocol,
    mobile_app: mobile_app,

    // A nested namespace for all the setting stores
    settings: {
      namespaced: true,
      modules: {
        // Stores settings / variables needed cross-component. I.e. for the overview pages
        navigation: navigation,
        errors: errors,
        breadcrumbs: breadcrumbs,
        modals: modals,
        data_tables: data_tables,
        reports: report_settings,
      }
    },
    // A nested namespace for all the setting stores
    application: {
      namespaced: true,
      modules: {
        websocket: websocket,
        online_check: online_check,
      }
    },
    // A nested namespace for all the selector stores
    selectors: {
      namespaced: true,
      modules: {
        patient_selector: patient_selector,
        mutations_patient_selector: mutations_patient_selector,
        target_selector: target_selector,
      }
    },
    // A nested namespace for all the setting stores
    reports_local: {
      namespaced: true,
      modules: {
        // Stores settings / variables needed cross-component. I.e. for the overview pages
        report_local_data_table1: report_local_data_table1,
        report_local_data_table2: report_local_data_table2,
        report_local_data_table3: report_local_data_table3,
        report_local_data_table4: report_local_data_table4,
        report_local_data_table5: report_local_data_table5,
        report_local_data_table6: report_local_data_table6,
      }
    },
    example_data: {
      namespaced: true,
      modules: {
        medimo_table_data: medimo_table_data,
        medimo_local_data_table_data: medimo_local_data_table_data,
        medimo_external_data_table_data: medimo_external_data_table_data,
      }
    },
    config: {
      namespaced: true,
      modules: {
        patient: {
          namespaced: true,
          modules: {
            temporary_absences: config_patient_temporary_absences,
            ward_assignments: config_patient_ward_assignments,
          }
        },
      }
    },

    patient: {
      namespaced: true,
      modules: {
        drugs: {
          namespaced: true,
          modules: {
            formulary: patient_formulary,
            taxe: patient_taxe,
          }
        },
        dashboards: {
          namespaced: true,
          modules: {
            tdr: tdr_dashboard,
          }
        },
        dispenses: {
          namespaced: true,
          modules: {
            dispenses: dispenses,
            dispense_settings: dispense_settings,
            dispense_schema_templates: dispense_schema_templates,
          }
        },
        mutations: patient_mutations,
        search: patient_search,
        provisionals: patient_provisionals,
      }
    },
    ward: {
      namespaced: true,
      modules: {
        mutations: ward_mutations,
      }
    },

    user: {
      namespaced: true,
      modules: {
        permissions: user_permissions,
        security_groups: user_security_groups,
        security_group_members: user_security_group_members,
      }
    },
    permissions: {
      namespaced: true,
      modules: {
        security_groups: all_security_groups,
        permissions: all_permissions,
        target: target_permissions,
        target_cooperations: target_cooperations,
        security_group_logs: security_group_logs,
        security_group_members: security_group_members,
      }
    },
    cluster: {
      namespaced: true,
      modules: {
        actions: cluster_actions,
        vaccination_actions: vaccination_actions,
        vaccinations: vaccinations,
      }
    },
    stock: {
      namespaced: true,
      modules: {
        emergency_stock: emergency_stock,
        patient: patient_stock,
        ward: ward_stock,
        location: locations,
        emergency_location: emergency_location,
        stock_record: stock_record,
        stock_record_detail: stock_record_detail,
        stock_record_detail_reservation: stock_record_detail_reservation,
        stock_record_detail_history: stock_record_detail_history,
        pharmacy_return: pharmacy_return,
      }
    },
    mp9: {
      namespaced: true,
      modules: {
        medication_uses: medication_uses,
        medication_agreements: mp9_medication_agreements,
        administration_agreements: mp9_administration_agreements,
        variable_dosing_regimens: variable_dosing_regimens,
        medication_overview: medication_overview,
        initial_data: initial_data_mp9,
        settings: settings,
        mutation_history: mutation_history,
        mutation_history_treatment: mutation_history_treatment,
        dispense_history: dispense_history,
        multi_stop: multi_stop,
        medication_dispenses: medication_dispenses,
        restart: restart,
      }
    },
  },

  state: {
    //
  },

  getters: {
    //
  },

  mutations: {},

  actions: {
    //
  },

  plugins: []
};

export default {
  store_options: options,
};
