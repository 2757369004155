<template>
  <div class="protocol-item" v-if="item.show">
    <div class="item-text">
      <div v-if="options.length > 0 && !item.type" class="options">
        <div v-for="option in options" @click="selectOption(option)" :id="getOptionId(option)" class="button">
          <fa-icon icon="fa-solid fa-plus" /> {{ getOptionTitle(option) }}
        </div>
      </div>
      <div @click="selectOption(item.type)" v-else-if="editMode" class="title editMode">
        <div class="title-head">{{ title.html }}</div>
        <div>{{ title.txt }}</div>
      </div>
      <div v-else class="title">
        <div class="title-head">{{ title.html }}</div>
        <div>{{ title.txt }}</div>
      </div>
    </div>

    <div class="remove" v-if="editMode && item.children.length === 0">
        <fa-icon icon="fa-solid fa-times" @click="clear()" />
    </div>
    <div class="clear"></div>
    <div class="addBottom" v-if="showAddBottom()">
      <fa-icon icon="fa-solid fa-plus" @click="addBottom()" />
    </div>
    <div class="addRight">
      <fa-icon v-if="showAddRight()" icon="fa-solid fa-plus" @click="addRight()" :id="getOptionId('plus')" />
      <fa-icon v-if="showArrow()" icon="fa-solid fa-arrow-right" />
    </div>
  </div>
  <fa-icon v-else-if="showSplitArrow()" icon="fa-solid fa-arrow-turn-down-right" />
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import OptionHelper from '/modules/protocol/OptionHelper';
import Utility from '@/vue/utility/utility';

export default {
  props: {
    editMode: {required: true},
    rule: {required: true},
    ruleid: {required: true},
    item: {required: true},
    options: {required: true}
  },
  data: function () {
    return {
      displayOptions: true,
    };
  },
  mixins: [BaseComponentsMixin],
  computed: {
    title() {
      return (new OptionHelper(this.language, Utility)).getTitle(this.item);
    },
    language() {
      return this.$store.state.current_user.data.language;
    },
  },
  methods: {
    getOptionId(option) {
      //Id is used in acceptance tests:
      return 'option' + this.ruleid + '' + this.item.row + '' + this.item.col + '' + option;
    },
    getOptionTitle: function (option) {
      if (option === OptionHelper.startOption) return 'Start medicatie';
      if (option === OptionHelper.changeOption) return 'Wijzig medicatie';
      if (option === OptionHelper.stopOption) return 'Stop medicatie';
      if (option === OptionHelper.actionOption) return 'Voeg actie toe';
      if (option === OptionHelper.filterOption) return 'Filter criteria';
      if (option === OptionHelper.filterStatusOption) return 'Zoek medicatie';
      if (option === OptionHelper.stopFoundStatusOption) return 'Stop gevonden medicatie';
      if (option === OptionHelper.customQuestionOption) return 'Stel vraag';
      if (option === OptionHelper.customAnswerOption) return 'Antwoordoptie';
      if (option === OptionHelper.extraProtocolOption) return 'Extra protocol';
    },
    clear: function () {
      this.$emit('clear', this.ruleid, this.item);
    },
    showAddRight: function () {
      return !(
        // do NOT show when:
        !this.editMode ||
        !this.item.type ||
        this.item.children.length > 0 ||
        this.item.type === OptionHelper.startOption && this.item.values.usageType !== 'C' ||
        this.item.type === OptionHelper.startOption && this.item.values.usageType === 'S' ||
        this.item.type === OptionHelper.changeOption && this.item.values.usageType === 'S' ||
        this.item.type === OptionHelper.actionOption ||
        this.item.type === OptionHelper.stopOption ||
        this.item.type === OptionHelper.stopFoundStatusOption ||
        this.item.type === OptionHelper.extraProtocolOption
      );
    },
    showArrow: function () {
      return this.item.type && this.item.children.length > 0;
    },
    showSplitArrow: function() {
      if (this.item.show) {
        return false;
      }

      // check if cell right of me is a visible item
      for (let i = 0; i < this.rule.items.length; i++) {
        if (this.rule.items[i].row === this.item.row && this.rule.items[i].col === this.item.col + 1 && this.rule.items[i].show) {
          return true;
        }
      }

      return false;
    },
    addRight: function () {
      this.$emit('add', 'right', this.ruleid, this.item);
    },
    showAddBottom: function () {
      if (this.item.col === 0) return false;

      return this.editMode && this.item.type && this.item.type !== OptionHelper.stopOption && this.item.type !== OptionHelper.changeOption;
    },
    addBottom: function () {
      this.$emit('add', 'bottom', this.ruleid, this.item);
    },
    selectOption: function (option) {
      this.$emit('select', option, this.ruleid, this.item);
    },
  }
};
</script>

<style scoped lang="scss">
svg.svg-inline--fa {
  color: #bababa;
  &.fa-plus, &.fa-xmark {
    cursor: pointer;
  }
  &.fa-arrow-turn-down-right {
    position:absolute;
    top: 6px;
    right: 3px;
  }
}
.protocol-item {
  height: 100%;
  padding: 0 0 10px 10px;
  .item-text {
    float: left;
    width: 195px;
    min-height: 10px;
    .options {
      width: 190px;
      padding-top: 5px;
      .button {
        color: #444;
        background-color: #F3F3F3;
        width: 100%;
        text-align: left;
        cursor: pointer;
        padding: 1px 1px 1px 10px;
        height: 25px;
        margin-top: 1px;
        margin-bottom: 1px;
        border: 1px solid #BABABA;
      }
    }
    .title {
      margin-bottom: 15px;
      padding-top: 5px;
      text-align: left;
      &.editMode {
        cursor:pointer;
      }
      .title-head {
        font-weight: bold;
      }
    }
  }
  .remove {
    width:14px;
    min-height: 50px;
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .addBottom {
    position:absolute;
    bottom: 0;
    left: 95px;
  }
  .addRight {
    position:absolute;
    bottom: 30%;
    right: 3px;
  }
}
</style>
