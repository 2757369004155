<template>
  <div>
    <input type="file" @change="select" ref="fileUpload" id="fileUpload">
    <input type="text" hidden :name="name" :value="file.name">
    <div class="progress"  v-if="fileSelected">
      <div class="progress-bar" role="progressbar"
           v-bind:class="{
              'in-progress': inProgress,
              'is-done': isDone,
           }"
           :style="'width: ' + progress + '%;'" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">
        {{ progress }}%
      </div>
    </div>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {},

  props: {
    name: {required: true},
    upload_endpoint: {required: true},
    chunkSize: {default: 2048000}
  },

  mixins: [BaseComponentsMixin],

  data() {
    return {
      file: {size: 0, name: ''},
      chunks: [],
      totalChunks: [],
      uploaded: 0,
      errorCount: 0,
    };
  },

  computed: {
    fileSelected() {
      return this.file.size > 0 || this.file.name.length > 0;
    },
    progress() {
      // We kunnen hem ook doen op basis van de Chunks
      // Als er nog 3 chunks te gaan zijn, van 5 total chunks hebben
      return Math.floor((1 - (this.chunks.length / this.totalChunks)) * 100);
    },
    inProgress() {
      return this.chunks.length > 0;
    },
    isDone() {
      return this.chunks.length === 0 && this.fileSelected;
    },
    formData() {
      if (!this.fileSelected) {
        return {};
      }
      const formData = new FormData;

      formData.append('is_first', this.uploaded === 0);
      formData.append('is_last', this.chunks.length === 1);
      formData.append('file', this.chunks[0], `${this.file.name}.part`);

      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: this.upload_endpoint,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: event => {
          this.uploaded += event.loaded;
        }
      };
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    select(event) {
      this.file = event.target.files.item(0);
      this.createChunks();
    },
    upload() {
      axios(this.config).then(response => {
        this.$store.dispatch('api/processPossibleOldStyleErrorNotification', response).then(errorNotification => {
          if (errorNotification !== null) {
            // Met de old style notys willen we de voortgang aborten
            this.reset();
          }
        });
        this.chunks.shift();
      }).catch(error => {
        if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
          console.log('error', error);
        }
      });
    },
    createChunks() {
      this.totalChunks = 0;
      this.chunks = [];
      const size = this.chunkSize, chunks = Math.ceil(this.file.size / size);

      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
          i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }

      this.totalChunks = this.chunks.length;
    },
    reset() {
      this.file = {size: 0, name: ''};
      this.chunks = [];
      this.uploaded = 0;
      this.$refs.fileUpload.value = null;
    }
  },

  watch: {
    chunks: {
      deep: true,
      handler(n, o) {
        // Zodra het aantal chunks veranderd maar nog steeds meer is dan 0
        // uploaden we opnieuw, net zo lang tot er 0 chunks zijn
        if (n.length > 0) {
          this.upload();
        }
      },
    },
    file: {
      deep: true,
      handler() {
        // Als het bestand veranderd, moet deze gereset worden, anders loopt het percentage door
        this.uploaded = 0;
      }
    },
    isDone(isDone) {
      if (isDone) {
        this.$emit('update:modelValue', this.file.name);
      }
    }
  },
};
</script>
