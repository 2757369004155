<template>
    <medimo-validated-date-time-picker
            v-bind="$attrs"

            :only_date="true"
            :close_on_date_change="true"
            :datetime_format="datetime_format"
            :help_text="help_text"
    ></medimo-validated-date-time-picker>
</template>

<script>

import MedimoValidatedDateTimePicker from './MedimoValidatedDateTimePicker';
import MedimoLabeledDateTimePicker from "@/vue/components/general/form/base/MedimoLabeledDateTimePicker.vue";

export default {
  inheritAttrs: false,
  components: {
    MedimoLabeledDateTimePicker,
    MedimoValidatedDateTimePicker,
  },
  props: {
    datetime_format: {default: "DD-MM-YYYY"},
    'help_text': {default: ''},
  },
};
</script>
