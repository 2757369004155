<template>
  <medi-modal title="Nieuwe actie" size="medium" @closed="close">
    <action-form :action="action" @update="updateAction" user_base_entity_type="Admin" />
    <template v-slot:action-buttons>
      <medimo-button @click.native="save">Opslaan</medimo-button>
      <medimo-button @click.native="close" type="outline-primary">Annuleren</medimo-button>
    </template>
  </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';
import ActionForm from '@/vue/components/patients/action-dialogs/ActionForm';

export default {
  emits: ['closed', 'save'],
  components: {ActionForm, MediModal},

  props: {
    item: {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      action: {},
      columnTranslations: {
        'name': 'name',
        'template_id': 'templateId',
        'description': 'description',
        'repeat_after_days': 'repeatAfterDays',
        'is_recurrent': 'isRecurrent',
        'inform_by_mail': 'informByMail',
        'inform_them': 'informThem',
        'is_private': 'privacy',
        'is_assigned_to_physician': 'isAssignedToPhysician',
        'is_assigned_to_pharmacy': 'isAssignedToPharmacy',
        'is_assigned_to_care': 'isAssignedToCare',
        'is_visible_to_physician': 'isParticipatingPhysician',
        'is_visible_to_care': 'isParticipatingCare',
        'is_visible_to_pharmacy': 'isParticipatingPharmacy',
        'start_after_days': 'startAfterDays',
        'finish_within_days': 'finishWithinDays',
      }
    };
  },

  created() {
    if (this.item.values) {
      this.initActionFromValues(this.item.values);
    } else {
      this.initialize();
    }
  },

  methods: {
    initialize() {
      this.action = {
        name: '',
        template_id: '',
        description: '',
        repeat_after_days: 0,
        is_recurrent: false,
        inform_by_mail: false,
        inform_them: false,
        is_private: false,
        is_assigned_to_physician: false,
        is_assigned_to_pharmacy: true,
        is_assigned_to_care: false,
        is_visible_to_physician: false,
        is_visible_to_care: false,
        is_visible_to_pharmacy: true,
        // Protocol specific:
        start_after_days: 0,
        finish_within_days: 0,
      };
    },
    updateAction(prop, value) {
      this.action[prop] = value;
    },
    validate() {
      if (this.action.name === '') {
        this.$store.dispatch('notifications/addWarningAlert', {title: 'U dient een actie naam in te vullen'});
        return false;
      }
      if (this.action.is_assigned_to_care === false && this.action.is_assigned_to_pharmacy === false && this.action.is_assigned_to_physician === false) {
        this.$store.dispatch('notifications/addWarningAlert', {title: 'Minimaal 1 partij dient verantwoordelijk te zijn'});
        return false;
      }
      return true;
    },
    save() {
      if (!this.validate()) {
        return;
      }
      this.$emit('save', this.item.row, this.item.col, 1, this.actionToProtocolValues());
      this.close();
    },
    actionToProtocolValues() {
      const values = {};
      const keys = Object.keys(this.columnTranslations);
      for (let i=0; i<keys.length; i++) {
        const key = keys[i];
        values[this.columnTranslations[key]] = this.action[key];
      }
      return values;
    },
    initActionFromValues(values) {
      if (typeof values['isAssignedTo'] === 'string') {
        values['isAssignedTo' . values['isAssignedTo'].toLowerCase()] = 1;
      }

      values['is_assigned_to_care'] = values['isAssignedToCare'];
      values['is_assigned_to_physician'] = values['isAssignedToPhysician'];
      values['is_assigned_to_pharmacy'] = values['isAssignedToPharmacy'];

      Object.keys(this.columnTranslations).forEach((key) => {
        this.setAction(key, values[this.columnTranslations[key]]);
      });
    },
    setAction(prop, value) {
      this.action[prop] = value;
    },
    close() {
      this.$emit('closed');
    },
  },
};
</script>
