<template>
  <medimo-link @click="$emit('click', $event)" :disabled="disabled" :target="target" :class="btnClasses + (marginRight ? ' mr-1' : '')" :to="to" v-bind="$attrs" :navigateHard="navigateHard">
    <i v-if="icon_class.length && !loading" :class="icon_class"></i>
    <i v-if="icon_class.length && loading" class="fa fa-spinner fa-spin"></i>
    <fa-icon v-if="icon_fa.length && !loading" :icon="icon_fa" :size="fa_size"/>
    <fa-icon v-if="icon_fa.length && loading" icon="fa-solid fa-spinner" spin :size="fa_size"/>
        <slot></slot>
        <span class="badge-container ml-1" v-if="badge_count">
            <span class="badge badge-danger badge-pill">{{ badge_count }}</span>
        </span>
    </medimo-link>
</template>

<script>
import MedimoLink from './MedimoLink';
import MedimoLoader from './MedimoLoader';

export default {
  components: {
    MedimoLoader,
    MedimoLink,
  },

  emits: ['click'],

  props: {
    target: {default: '_self'},
    icon: {default: ''},
    to: {default: ''},
    size: {default: ''},
    type: {default: 'primary'},
    loading: {default: false},
    outline: {default: false},
    disabled: {default: false},
    badge_count: {default: 0},
    marginRight: {default: true},
    no_blinking_on_disabled: {default: false},
    navigateHard: {default: false}
  },

  computed: {
    icon_class() {
      return (!this.icon.length || this.icon.startsWith('fa-')) ? '' : ('medimo-icon ' + this.icon);
    },
    icon_fa() {
      return this.icon.startsWith('fa-') ? this.icon : '';
    },
    fa_size() {
      return this.size === 'sm' ? null : 'lg';
    },
    btnClasses() {
      let classes = ['btn', 'btn-' + this.type, 'btn-sm-block'];

      if (this.size === 'sm') {
        classes.push('btn-sm');
      } else if (this.size === 'md') {
        classes.push('btn-md');
      } else if (this.size === 'lg') {
        classes.push('btn-lg');
      } else if (this.size === 'block') {
        classes.push('btn-block');
      }

      if (this.outline) {
        classes = classes.filter(classString => {
          return classString !== 'btn-' + this.type;
        });
        classes.push('btn-outline-' + this.type);
      }

      if (this.type === 'light') {
        classes.push('text-medimo-50');
      }

      if (this.no_blinking_on_disabled) {
        classes.push('no-blinking');
      }

      if (this.disabled) {
        classes.push('disabled');
      }

      if (!this.$slots.default) {
        classes.push('icon-only');
      }

      return classes.join(' ');
    }
  },
};
</script>

<style scoped lang="scss">
.btn-secondary {
  color: white;
}

.icon-only {
  svg.svg-inline--fa {
    margin-right: 0.1805rem;
  }
}
</style>
