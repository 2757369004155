import DataType from "@/enums/DataType";
import MedicationChecks from "@/declarations/MedicationCheck";
import StoreUpdateData from "@/declarations/StoreUpdateData";
import {store} from '@/vue/store';
import {AdministeringScheduleObject} from "@/domain/models/AdministeringScheduleObject";
import {MedicationGuard} from "@/domain/models/MedicationGuard";
import {DosingInstructionObject} from "@/domain/models/DosingInstructionObject";
import {TimeObject} from "@/domain/models/TimeObject";
import {HealthcareProvider} from "@/domain/models/HealthcareProvider";
import {HealthcareProfessional} from "@/domain/models/HealthcareProfessional";
import {MedicationAgreement} from "@/domain/models/MedicationAgreement";
import {AdministrationAgreement} from "@/domain/models/AdministrationAgreement";

export abstract class AbstractMedication<T> {
  protected abstract storeName: string;
  protected abstract apiEndpoint: string;
  protected abstract fromJson(data: unknown): T;

  private controller: AbortController | null = null;

  public async mutation(patientId: number, id: number, minStartDateTime: string): Promise<Mutation> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id + '/mutation',
      data: {
        minStartDatetime: minStartDateTime
      }
    });

    return response.data;
  }

  public async check(patientId: number, id: number): Promise<MedicationChecks> {
    const draft = store.getters[this.storeName + '/' + DataType.Draft](id);
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id,
      data: draft
    });

    return response.data;
  }

  public async createDefault(patientId: number, id: number): Promise<void> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id + '/create-default',
    });

    const drug = response.data['drug'];
    const agreement = this.fromJson(response.data['medicationAgreement']);
    store.commit(this.storeName + '/setData', {data: agreement, drug: drug[0]});
  }

  public async medicationGuard(patientId: number, id: number): Promise<MedicationGuard> {
    // todo mp9 this way we can cancel previous calls, needs a refactor.
    if (this.controller !== null) {
      this.controller.abort();
      this.controller = new AbortController();
    } else {
      this.controller = new AbortController();
    }

    const draft = store.getters[this.storeName + '/' + DataType.Draft](id);
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id + '/medication-guard',
      data: draft,
      signal: this.controller.signal
    });

    return MedicationGuard.fromJson(response.data);
  }

  public async fromMedicationAgreement(medicationAgreement: MedicationAgreement): Promise<T> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + medicationAgreement.patientId + '/' + this.apiEndpoint + '/from-medication-agreement/' + medicationAgreement.id,
    });

    const data = this.fromJson(response.data.data);
    store.commit(this.storeName + '/setData', {data: data});
    return data;
  }

  public async fromAdministrationAgreement(administrationAgreement: AdministrationAgreement): Promise<T> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + administrationAgreement.patientId + '/' + this.apiEndpoint + '/from-administration-agreement/' + administrationAgreement.id,
    });

    const data = this.fromJson(response.data.data);
    store.commit(this.storeName + '/setData', {data: data});
    return data;
  }

  public storeDraft(id: number): void {
    const draft = store.getters[this.storeName + '/' + DataType.Draft](id);
    store.commit(this.storeName + '/storeDraft', draft);
  }

  public setConceptOnStore(data: unknown, drug: unknown): void {
    store.commit(this.storeName + '/clearData');
    const agreement = this.fromJson(data);
    store.commit(this.storeName + '/setData', {data: agreement, drug: drug});
  }

  public pushToStore(data: unknown, drug: unknown): void {
    const agreement = this.fromJson(data);
    store.commit(this.storeName + '/setData', {data: agreement, drug: drug});
  }

  public removeConcept(id: number): void {
    store.commit(this.storeName + '/removeConcept', id);
  }

  public removeConceptRowFromStore(id: number): void {
    store.commit(this.storeName + '/removeConceptRowFromStore', id);
  }

  public revertChanges(id: number): void {
    store.commit(this.storeName + '/revertChanges', id);
  }

  public removeLast(): void {
    store.commit(this.storeName + '/removeLast');
  }

  public removeFromStore(): void {
    store.commit(this.storeName + '/clearData');
  }

  public async pushDosingInstruction(id: number, dosingInstruction: DosingInstructionObject): Promise<DosingInstructionObject> {
    store.commit(this.storeName + '/pushDosingInstruction', {
      id: id,
      dosingInstruction: dosingInstruction
    });

    return dosingInstruction;
  }

  public async setDosingInstructions(id: number, dosingInstructions: DosingInstructionObject[]): Promise<DosingInstructionObject[]> {
    store.commit(this.storeName + '/setDosingInstructions', {
      id: id,
      dosingInstructions: dosingInstructions
    });

    return dosingInstructions;
  }

  public async removeDosingInstruction(id: number, dosingInstructionId: number): Promise<void> {
    store.commit(this.storeName + '/removeDosingInstruction', {
      id: id,
      dosingInstructionId: dosingInstructionId
    });
  }

  public async removeDosingInstructionBySequence(id: number, sequenceNumber: number): Promise<void> {
    store.commit(this.storeName + '/removeDosingInstructionBySequence', {
      id: id,
      sequenceNumber: sequenceNumber
    });
  }

  public async removeDosingInstructionByAdministrationTime(id: number, time: TimeObject): Promise<void> {
    store.commit(this.storeName + '/removeDosingInstructionByAdministrationTime', {
      id: id,
      time: time
    });
  }

  public updateStore(payload: StoreUpdateData): void {
    this.createAdministeringScheduleWhenNull(payload);
    store.commit(this.storeName + '/' + payload.type, {
      id: payload.id,
      value: payload.value,
      key: payload.key,
      dosingInstructionId: payload.dosingInstructionId, // Not required when updating other than dosing instruction.
    });
  }

  /**
   * Function is used to ensure an administeringSchedule is present whenever an element
   * in the administering schedule is updated. This element can be null from the backend.
   */
  private createAdministeringScheduleWhenNull(payload: StoreUpdateData): void {
    if (payload.type !== 'updateAdministeringSchedule') {
      return;
    }

    if (payload.dosingInstructionId === undefined) {
      return;
    }

    const draft = store.getters[this.storeName + '/' + DataType.Draft](payload.id);

    // Find the index of the dosing instruction with the specified id
    const dosingInstructionIndex = draft.instructionsForUse?.dosingInstructions.findIndex(
      (dosingInstruction: DosingInstructionObject) => dosingInstruction.id === payload.dosingInstructionId
    );

    if (dosingInstructionIndex !== -1) {
      // So when administerSchedule does not exist we update the store with an empty AdministeringScheduleObject object.
      // eslint-disable-next-line
      // @typescript-eslint/ban-ts-comment
      if (draft.instructionsForUse?.dosingInstructions[dosingInstructionIndex]?.administeringSchedule === null) {
        this.updateStore({
          id: payload.id,
          type: 'updateDosingInstruction',
          value: new AdministeringScheduleObject(),
          key: 'administeringSchedule',
          dosingInstructionId: payload.dosingInstructionId
        });
      }
    }
  }
}

export interface Mutation {
  minimumStartDateTime: string,
  mutationDateTime: string,
  nextPackageDateTime: string,
  prescriber?: HealthcareProfessional,
  requester?: HealthcareProfessional,
  supplier?: HealthcareProvider,
}
