//Added for tests

// initial state
// shape: [{ id, quantity }]
const state = {
  // Keeps track of the open Modals
  openMediModalUids: [],

  open_select: false,
  open_alert: false,
};

// getters
const getters = {
  allowed_to_close(state, getters, rootState) {
    return !state.open_select && !state.open_alert;
  },
  are_open(state, getters, rootState) {
    return state.openMediModalUids.length > 0;
  },
  highest_z_index(state) {
    let max = 0;
    state.openMediModalUids.forEach(openUid => {
      if (openUid.zIndex > max) {
        max = openUid.zIndex;
      }
    });

    return max;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  add_to_open_medimodal_uids(state, payload) {
    const matchingUid = state.openMediModalUids.filter(openUid => {
      return openUid.uid === payload.uid;
    });

    // When there are no matches, we add it.
    if (matchingUid.length === 0) {
      state.openMediModalUids.push(payload);
    }
  },
  update_open_medimodal_uids(state, payload) {
    state.openMediModalUids.forEach(openUid => {
      if (openUid.uid === payload.uid) {
        openUid.zIndex = payload.zIndex;
      }
    });
  },
  remove_from_open_medimodal_uids(state, uid) {
    const openUids = [];
    state.openMediModalUids.forEach(openUid => {
      if (openUid.uid !== uid) {
        openUids.push(openUid);
      }
    });

    state.openMediModalUids = openUids;
  },
  set_open_select(state, value) {
    state.open_select = value;
  },
  set_open_alert(state, value) {
    state.open_alert = value;
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
