import {DispenseRequestError} from '@/domain/models/DispenseRequestError';

export class GeneratedDispenseRequestError { 
  public text: string;

  constructor(text: string) {
    this.text = text;
  }

  public toJSON(): unknown {
    return {
      text: this.text,
    };
  }

  public static fromJson(json: unknown): DispenseRequestError {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DispenseRequestError(
      parsedJson['text'] as string,
    );
  }

  public static multipleFromJson(json: unknown): DispenseRequestError[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
