import {store} from "@/vue/store";
import {DispenseRequest} from "@/domain/models/DispenseRequest";
import {Options} from "@/controllers/InitialDataController";
import {DispenseRequestWarning} from "@/domain/models/DispenseRequestWarning";
import {DispenseRequestError} from "@/domain/models/DispenseRequestError";
import {CodeObject} from "@/domain/models/CodeObject";
import {MedicationOverview} from "@/domain/models/MedicationOverview";
import moment from "moment-mini";
import {DeadlineDates} from "@/interfaces/mp9/DeadlineDates";

export class DispenseRequestController {
  public async store(patientId: number, dispenseRequests: DispenseRequest[], pharmacyId: number, deliveryDate: string): Promise<void> {
    await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request',
      data: {
        dispenseRequests: dispenseRequests,
        deliveryDate: deliveryDate,
        pharmacyId: pharmacyId,
      }
    });
  }

  public async list(patientId: number): Promise<InitialDispenseResponseData> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request',
    });

    return {
      medication: MedicationOverview.multipleFromJson(response.data.medication),
      additionalWishes: response.data.additionalWishes,
      defaultPharmacy: response.data.defaultPharmacy,
      dispenseLocations: response.data.dispenseLocations,
      deliveryDates: {
        today: moment(response.data.deliveryDates.todayDate),
        tomorrow: moment(response.data.deliveryDates.tomorrowDate),
        week: moment(response.data.deliveryDates.nextDeliveryDate),
        default: moment(response.data.deliveryDates.defaultDate),
      },
    };
  }

  public async create(patientId: number, medicationAgreementId: number): Promise<DispenseRequestResponse> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request/' + medicationAgreementId,
    });

    return {
      dispenseRequest: DispenseRequest.fromJson(response.data.dispenseRequest),
      dispenseRequestHistory: DispenseRequest.multipleFromJson(response.data.dispenseRequestHistory),
      errors: DispenseRequestError.multipleFromJson(response.data.errors),
      warnings: DispenseRequestWarning.multipleFromJson(response.data.warnings),
      disabledCalculation: response.data.disabledCalculation as boolean,
      showCalculation: response.data.showCalculation as boolean,
      financialIndicationCodes: CodeObject.multipleFromJson(response.data.financialIndicationCodes),
      timeUnits: response.data.timeUnits as Options[],
    };
  }

  public async calculate(patientId: number, medicationAgreementId: number, amount: number, numberOfRefills: number, numberOfDays: number, enoughUntil: string, type: string): Promise<CalculateResponse> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request/' + medicationAgreementId + '/calculate',
      data: {
        amount: amount,
        numberOfRefills: numberOfRefills,
        numberOfDays: numberOfDays,
        enoughUntil: enoughUntil,
        type: type
      },
    });

    return response.data;
  }
}

export interface CalculateResponse {
  amount: number,
  enoughUntil: string,
  numberOfDays: number,
  dispenseRequest: DispenseRequest,
}

export interface InitialDispenseResponseData {
  medication: MedicationOverview[],
  additionalWishes: Options[],
  deliveryDates: DeadlineDates,
  defaultPharmacy: Options,
  dispenseLocations: Options[],
}

export interface DispenseRequestResponse {
  dispenseRequest: DispenseRequest,
  dispenseRequestHistory: DispenseRequest[],
  errors: DispenseRequestError[],
  warnings: DispenseRequestWarning[],
  disabledCalculation: boolean,
  showCalculation: boolean,
  financialIndicationCodes: CodeObject[],
  timeUnits: Options[],
}
