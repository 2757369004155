<template>
  <component :is="isRow ? 'medimo-form-row' : 'div'" :no_margins="no_margins">
    <component :is="isRow ? 'medimo-form-column' : 'div'" v-if="label.length" :no_margin="no_margins" :column_classes="inline ? label_column_classes : 'col-12'">
      <label :class="'pr-2 ' + type + ' ' + validation_class" :for="name">
        {{ label }}
      </label>
    </component>
    <component :is="isRow ? 'medimo-form-column' : 'div'" :no_margin="no_margins" :column_classes="inline ? input_column_classes : 'col-12'">
      <medimo-input :input_class="extra_classes"
                    :validation_class="validation_class"
                    :type="type"
                    v-bind:modelValue="modelValue"
                    :name="name"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :help_text="help_text"
                    :sub_text="sub_text"
                    :focus="focus"
                    :hide_zero="hide_zero"
                    :clear_on_click="clear_on_click"
                    @update:modelValue="onInput($event)"
                    @blur="onBlur($event)"
                    @focus="$emit('focus', $event)"
                    @keydown_backspace="$emit('keydown_backspace', $event)" @keydown_tab="$emit('keydown_tab', $event)" @keydown_enter="$emit('keydown_enter', $event)" @keydown_left="$emit('keydown_left', $event)" @keydown_up="$emit('keydown_up', $event)" @keydown_right="$emit('keydown_right', $event)" @keydown_down="$emit('keydown_down', $event)" @keydown_period="$emit('keydown_period', $event)" @keyup_backspace="$emit('keyup_backspace', $event)" @keyup_tab="$emit('keyup_tab', $event)" @keyup_enter="$emit('keyup_enter', $event)" @keyup_left="$emit('keyup_left', $event)" @keyup_up="$emit('keyup_up', $event)" @keyup_right="$emit('keyup_right', $event)" @keyup_down="$emit('keyup_down', $event)" @keyup_period="$emit('keyup_period', $event)"
                    :min="min"
                    :max="max"
                    :step="step"
      ></medimo-input>
    </component>
    <component v-if="labelEnd.length" :is="isRow ? 'medimo-form-column' : 'div'" :no_margin="no_margins" :column_classes="inline ? input_column_classes : 'col-12'">
      <label :class="'pr-2 ' + type + ' ' + validation_class" :for="name">
        {{ labelEnd }}
      </label>
    </component>
  </component>
</template>

<script>

import MedimoInput from './MedimoInput';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import MedimoFormRow from './MedimoFormRow';
import MedimoFormColumn from './MedimoFormColumn';

export default {

  components: {
    MedimoInput,
    MedimoTooltip,
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['keyup', 'focus', 'blur', 'update:modelValue', 'keydown_backspace', 'keydown_tab', 'keydown_enter', 'keydown_left', 'keydown_up', 'keydown_right', 'keydown_down', 'keydown_period', 'keyup_backspace', 'keyup_tab', 'keyup_enter', 'keyup_left', 'keyup_up', 'keyup_right', 'keyup_down', 'keyup_period'],

  props: {
    'no_margins': {default: true},
    // Set to true to display the label and input in a single row
    'inline': {default: true},
    'modelValue': {default: '', type: [String, Number, null]}, // Dit is in Vue3 de nieuwe value
    'name': {default: ''},
    'label': {default: ''},
    'labelEnd': {default: ''},
    'disabled': {default: false},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'type': {default: 'text'},
    'help_text': {default: ''},
    'sub_text': {default: ''},
    'focus': {default: false},
    'hide_zero': {default: false},
    'clear_on_click': {default: false},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    'label_column_classes': {default: 'col-auto'},
    'input_column_classes': {default: 'col-sm'},
    'min': {},
    'max': {},
    'step': {},

    // If we want to use this component multiple times in a custom layout, we can omit the column/row
    isRow: {default: true},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasHelpText() {
      return this.help_text.length;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value);
    },
    onBlur(value) {
      this.$emit('blur', value);
    },
  },

  watch: {
    //
  }
};
</script>
