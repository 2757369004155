import {GeneratedDosingInstructionObject} from '@/domain/models/generated/GeneratedDosingInstructionObject';
import Utility from "@/vue/utility/utility";

export class DosingInstructionObject extends GeneratedDosingInstructionObject {
  public static fromObject(object: DosingInstructionObject): DosingInstructionObject {
    const clonedObject = Utility.deep_clone(object);
    return DosingInstructionObject.fromJson(clonedObject);
  }

  public showComplexView() {
    if (this.administeringSchedule !== null) {
      // When nominalValue is more then one and weekdays set, open in complex view.
      if (this.administeringSchedule.frequency?.nominalValue != null && this.administeringSchedule.frequency.nominalValue > 1 && this.administeringSchedule.weekDay !== null && this.administeringSchedule.weekDay.length > 0) {
        return true;
      }

      // Multiple times on dosing instructions, open in complex view.
      if (this.administeringSchedule.administrationTime !== null && this.administeringSchedule.administrationTime.length > 1) {
        return true;
      }

      // Multiple part of days set on dosing instruction, open in complex view.
      if (this.administeringSchedule.partOfDay !== null && this.administeringSchedule.partOfDay.length > 1) {
        return true;
      }
    }

    if (this.administeringSpeed) {
      return true;
    }

    return false;
  }

  public isPause(): boolean {
    return this.dose === null && this.administeringSchedule === null && this.administeringSpeed === null && this.doseDuration !== null;
  }
}
