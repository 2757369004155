<template>
  <medi-modal
      v-if="tree_filter_modal_props"
      :title="tree_filter_modal_props.title"
      size="large"
      :ref="'modal' + uid"
      @closed="close()"
  >
    <filter-tree :tree="tree" :filter_route="filter_route" />
    <medimo-labeled-checkbox v-model="inverse" label="Patient voldoet niet aan bovenstaande criteria" label_position="right" />

    <template v-slot:action-buttons>
      <button @click.prevent="storeFilter($event)">Opslaan</button>
    </template>
  </medi-modal>
</template>

<script>
import FilterTree from './FilterTree.vue';
import MedimoLabeledCheckbox from "@/vue/components/general/form/base/MedimoLabeledCheckbox.vue";
import MediModal from "@/vue/components/general/MediModal.vue";

export default {
  emits: ['closed', 'save'],
  components: {FilterTree, MedimoLabeledCheckbox, MediModal},

  props: [
    'height',
    'filter_route',
    'tree_filter_modal_props',
    'language'
  ],

  data() {
    return {
      tree: {
        groups: this.tree_filter_modal_props.groups,
        logic: this.tree_filter_modal_props.logic,
      },
      //if inverse === true: the answer is true when patient does NOT meet the given criteria
      inverse: this.tree_filter_modal_props.inverse,
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
  },

  methods: {
    hasOptions: function (group) {
      let hasOption = false;

      if (typeof group.groups !== 'undefined') {
        group.groups.forEach((subGroup) => {
          if (this.hasOptions(subGroup)) {
            hasOption = true;
          }
        });
      }

      if (typeof group.items !== 'undefined') {
        group.items.forEach((item) => {
          if (item.text) {
            hasOption = true;
          }
        });
      }

      return hasOption;
    },
    storeFilter() {
      if (this.hasOptions(this.tree)) {
        const json =  JSON.stringify({
          'logic': this.tree.logic,
          'groups': this.tree.groups,
          'inverse': this.inverse
        });
        this.$emit('save', this.tree_filter_modal_props.rowId, this.tree_filter_modal_props.colId, this.tree_filter_modal_props.option, json);
      }

      this.close();
    },
    close() {
      this.$emit('closed');
    }
  }
};
</script>
