import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  sort_columns: [{column_name: 'surname', direction: 'ASC'}],
};

const getters = {
  ...BaseModule.getters
};

const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/protocols/patients/' + payload.entityId + '?entityType=' + payload.entityType,
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
