import {GeneratedRangeObject} from '@/domain/models/generated/GeneratedRangeObject';

export class RangeObject extends GeneratedRangeObject {
  public processRangeInput(value: string, allowUnspecified = false): void {
    if (allowUnspecified && value.toLowerCase() === 'x') {
      this.unsetValues();
      this.setUnspecified();
      return;
    }

    const replacedValue = value.trim().replace(',', '.');

    // Check if the input contains a dash ('-')
    if (replacedValue.includes('-')) {
      const firstDashIndex = replacedValue.indexOf('-');

      // Check if the dash is at the start or end
      if (firstDashIndex === 0 || firstDashIndex === replacedValue.length - 1) {
        return;
      }

      const afterFirstDash = replacedValue.slice(firstDashIndex + 1);

      // Check if there's more than one dash
      if (afterFirstDash.includes('-')) {
        return;
      }

      const splittedValue = replacedValue.split('-');
      const firstValue = Number(splittedValue[0]);
      const secondValue = Number(splittedValue[1]);

      // Check if both values are valid numbers
      if (!isNaN(firstValue) && !isNaN(secondValue)) {
        this.unsetValues();
        if (firstValue === secondValue) {
          // If both values are the same, treat it as a nominalValue
          this.setNominalValue(firstValue);
          return;
        } else {
          this.setMinimumValue(Math.min(firstValue, secondValue));
          this.setMaximumValue(Math.max(firstValue, secondValue));
          return;
        }
      }
    } else {
      // Handle single numeric input case
      const parsedValue = replacedValue !== '' ? Number(replacedValue) : null;
      if (parsedValue === null || !isNaN(parsedValue)) {
        this.unsetValues();
        this.setNominalValue(parsedValue);
        return;
      }
    }
  }

  public retrieveFormattedRange(): string {
    if (this.isUnspecified) {
      return 'x';
    }

    if (this.nominalValue !== null) {
      return this.nominalValue.toString();
    }

    if (this.minimumValue !== null && this.maximumValue !== null) {
      return `${this.minimumValue}-${this.maximumValue}`;
    }

    return '';
  }

  public isOneTime(): boolean {
    return this.nominalValue === 1 && this.timeUnit === null;
  }

  private setUnspecified(): void {
    this.isUnspecified = true;
  }

  private setNominalValue(value: number | null): void {
    this.nominalValue = value;
  }

  private setMinimumValue(value: number | null): void {
    this.minimumValue = value;
  }

  private setMaximumValue(value: number | null): void {
    this.maximumValue = value;
  }

  public highestValue(): number | null {
    return this.nominalValue ?? this.maximumValue;
  }

  private unsetValues(): void {
    this.nominalValue = null;
    this.minimumValue = null;
    this.maximumValue = null;
    this.isUnspecified = false;
  }
}
