import {store} from "@/vue/store";
import {SettingPerAreaObject} from "@/domain/models/SettingPerAreaObject";

export class AdministrationAreasController {
  private storeName = 'administration_areas';

  public getPaginatedFromStore(): SettingPerAreaObject[] {
    return store.getters[this.storeName + '/paginated_data'];
  }

  public getColumnsFromStore(): string[] {
    return store.getters[this.storeName + '/state']('columns');
  }

  public async get(patientId: number): Promise<void> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + patientId + '/drug-settings/administration-areas',
    });

    const data = response.data.data.settingsPerArea.map((model: SettingPerAreaObject) => {
      return {
        ...model,
        administrationRoutes: model.settings.map(settings => {
          return settings.route.toLowerCase();
        }).join(' '),
        intramusculair: this.setRouteFilter(model, 'intramusculair'),
        intraveneus: this.setRouteFilter(model, 'intraveneus'),
        subcutaan: this.setRouteFilter(model, 'subcutaan'),
        transdermaal: this.setRouteFilter(model, 'transdermaal'),
        parenteraal: this.setRouteFilter(model, 'parenteraal'),
      };
    });

    store.commit(this.storeName + '/set_data', data);
    store.commit(this.storeName + '/set_administration_areas_columns', response.data.data);
  }

  public async toggle(patientId: number, id: number, route: string, checked: boolean): Promise<void> {
    const payload = {
      id: id,
      route: route,
      checked: checked,
    };

    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/patients/' + patientId + '/drug-settings/administration-areas/toggle',
      data: payload,
    });

    store.commit(this.storeName + '/update_administration_area_setting', payload);

    await store.dispatch('notifications/addSuccessAlert', {
      message: response.data.data.message,
    });
  }

  private setRouteFilter(model: SettingPerAreaObject, route: string): boolean | null {
    const setting = model.settings.find((setting) => {
      return setting.route.toLowerCase() === route;
    });

    if (setting === undefined) {
      return null;
    }

    return setting.value;
  }
}