import {AdministeringScheduleObject} from '@/domain/models/AdministeringScheduleObject';
import {RangeObject} from '@/domain/models/RangeObject';
import {CodeValue} from '@/domain/models/CodeValue';
import {TimeObject} from '@/domain/models/TimeObject';
import {QuantityObject} from '@/domain/models/QuantityObject';

export class GeneratedAdministeringScheduleObject { 
  public frequency: RangeObject | null;
  public weekDay: CodeValue[] | null;
  public administrationTime: TimeObject[] | null;
  public interval: QuantityObject | null;
  public isFlexible: boolean | null;
  public partOfDay: CodeValue[] | null;

  constructor(frequency: RangeObject | null = null, weekDay: CodeValue[] | null = null, administrationTime: TimeObject[] | null = null, interval: QuantityObject | null = null, isFlexible: boolean | null = null, partOfDay: CodeValue[] | null = null) {
    this.frequency = frequency;
    this.weekDay = weekDay;
    this.administrationTime = administrationTime;
    this.interval = interval;
    this.isFlexible = isFlexible;
    this.partOfDay = partOfDay;
  }

  public toJSON(): unknown {
    return {
      frequency: this.frequency,
      weekDay: this.weekDay,
      administrationTime: this.administrationTime,
      interval: this.interval,
      isFlexible: this.isFlexible,
      partOfDay: this.partOfDay,
    };
  }

  public static fromJson(json: unknown): AdministeringScheduleObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new AdministeringScheduleObject(
      parsedJson['frequency'] ? RangeObject.fromJson(parsedJson['frequency']) : null,
      parsedJson['weekDay'] ? CodeValue.multipleFromJson(parsedJson['weekDay']) : null,
      parsedJson['administrationTime'] ? TimeObject.multipleFromJson(parsedJson['administrationTime']) : null,
      parsedJson['interval'] ? QuantityObject.fromJson(parsedJson['interval']) : null,
      parsedJson['isFlexible'] as boolean,
      parsedJson['partOfDay'] ? CodeValue.multipleFromJson(parsedJson['partOfDay']) : null,
    );
  }

  public static multipleFromJson(json: unknown): AdministeringScheduleObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
