import {EmailAddressObject} from '@/domain/models/EmailAddressObject';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedEmailAddressObject { 
  public type: CodeObject;
  public address: string;

  constructor(type: CodeObject, address: string) {
    this.type = type;
    this.address = address;
  }

  public toJSON(): unknown {
    return {
      type: this.type,
      address: this.address,
    };
  }

  public static fromJson(json: unknown): EmailAddressObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new EmailAddressObject(
      CodeObject.fromJson(parsedJson['type']),
      parsedJson['address'] as string,
    );
  }

  public static multipleFromJson(json: unknown): EmailAddressObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
