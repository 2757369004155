import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';
import Quantity from "@/vue/utility/quantity";
import ProvisionalStatusResource from "@/domain/models/resources/ProvisionalStatusResource";
import moment from "moment-mini";

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  tmpId: 0,

  sort_states: {
    'ABC': [
      {'column_name': 'drugName', 'direction': 'ASC'},
      {'column_name': 'calculatedStartDateTime', 'direction': 'ASC'},
      {'column_name': 'id', 'direction': 'ASC'},
    ],
    'T-ABC': [
      {'column_name': 'usageType', 'direction': 'ASC'},
      {'column_name': 'drugName', 'direction': 'ASC'},
      {'column_name': 'calculatedStartDateTime', 'direction': 'ASC'},
      {'column_name': 'id', 'direction': 'ASC'},
    ],
    'ATC': [
      {'column_name': 'drugAtc', 'direction': 'ASC'},
      {'column_name': 'drugName', 'direction': 'ASC'},
      {'column_name': 'calculatedStartDateTime', 'direction': 'ASC'},
      {'column_name': 'id', 'direction': 'ASC'},
    ],
    'T-ATC': [
      {'column_name': 'usageType', 'direction': 'ASC'},
      {'column_name': 'drugAtc', 'direction': 'ASC'},
      {'column_name': 'drugName', 'direction': 'ASC'},
      {'column_name': 'calculatedStartDateTime', 'direction': 'ASC'},
      {'column_name': 'id', 'direction': 'ASC'},
    ],
  },
};

// getters
const getters = {
  ...BaseModule.getters,
  tmpId(state, getters, rootState, rootGetters) {
    // UsePatientProvisionals.generateTemporaryId gebruiken, anders mis je de +1
    return 'new-' + state.tmpId;
  },
  sort_states(state, getters, rootState, rootGetters) {
    return (currentSortState) => {
      return state.sort_states[currentSortState];
    };
  },
  regular(state, getters, rootState, rootGetters) {
    return state.data.filter(provisionalStatus => {
      return !provisionalStatus.drug.medical_device;
    });
  },
  medical_devices(state, getters, rootState, rootGetters) {
    return state.data.filter(provisionalStatus => {
      return provisionalStatus.drug.medical_device;
    });
  },
  predecessor(state, getters, rootState) {
    return (provisionalStatus) => {

      if (!provisionalStatus.parentProvisionalStatusId) {
        return false;
      }

      const parent = Utility.find_model_by_property(state.data, 'id', provisionalStatus.parentProvisionalStatusId);
      if (parent) {
        return parent;
      }

      //Edge case: missing id because of cancel
      let relatedDosingInstructions = getters['forFirstProvisionalStatusId'](provisionalStatus.firstProvisionalStatusId);
      relatedDosingInstructions = relatedDosingInstructions.filter( (relatedInstruction) => {
        return (relatedInstruction.startDateTime < provisionalStatus.startDateTime);
      });

      relatedDosingInstructions.sort( (a,b) => {
        return (a.startDateTime > b.startDateTime) ? -1 : 1;
      });

      if (relatedDosingInstructions.length > 0) {
        return relatedDosingInstructions[0];
      }
      return false;
    };
  },
  successor(state, getters, rootState) {
    return (id) => {

      const dosingInstruction = Utility.find_model_by_id(state.data, id);
      if (!dosingInstruction) {
        return false;
      }
      const successor = Utility.find_model_by_property(state.data, 'parentProvisionalStatusId', dosingInstruction.id);
      if (successor) {
        return successor;
      }

      // Edge case: missing id because of cancel
      let relatedDosingInstructions = getters['forFirstProvisionalStatusId'](dosingInstruction.firstProvisionalStatusId);

      relatedDosingInstructions = relatedDosingInstructions.filter( (relatedInstruction) => {
        // Onderstaand was start_moment_datetime -> is dat wat anders hier?
        return (relatedInstruction.startDateTime > dosingInstruction.startDateTime);
      });

      relatedDosingInstructions.sort( (a,b) => {
        return (a.startDateTime < b.startDateTime) ? -1 : 1;
      });

      if (relatedDosingInstructions.length > 0) {
        return relatedDosingInstructions[0];
      }
      return false;
    };
  },
  forFirstProvisionalStatusId(state, getters, rootState) {
    return (firstProvisionalStatusId) => Utility.find_models_by_property(state.data, 'firstProvisionalStatusId', firstProvisionalStatusId);
  },
  startedBeforeToday(state, getters, rootState, rootGetters) {
    return (provisionalStatus) => {
      const startOfDay = moment().startOf('day');
      const startDateTime = moment(provisionalStatus.startDateTime);

      if (startDateTime > startOfDay) {
        return false;
      }
      if (provisionalStatus.stopDateTime && moment(provisionalStatus.stopDateTime, 'YYYY-MM-DD HH:mm') < startOfDay) {
        return false;
      }
      if (provisionalStatus.mutationDateTime && moment(provisionalStatus.mutationDateTime, 'YYYY-MM-DD HH:mm') < startOfDay) {
        return false;
      }

      return true;
    };
  },
  minStartDateTimeForCorrection(state, getters, rootState, rootGetters) {
    return (provisionalStatus) => {
      const minimal_default_6hours_ago = moment().subtract(6, 'hours');

      // Get predecessor (the (unsaved) local is the most updated one)
      // ( Zoek de voorgaande dosing instructie op. De lokale variant is de meest actuele (niet de db variant) )
      const predecessor = getters['predecessor'](provisionalStatus);

      if (predecessor) {
        return getters['minStartDateTimeBasedOnPredecessor'](predecessor).format('YYYY-MM-DD HH:mm');
      }

      return minimal_default_6hours_ago.format('YYYY-MM-DD HH:mm');
    };
  },
  maxStartDateTimeForCorrection(state, getters, rootState, rootGetters) {
    return (provisionalStatus) => {

      // Get successor (the (unsaved) local is the most updated one)
      // ( Zoek opvolgende doseerinstructie op. De lokale variant is de meest actuele (niet de db variant) )
      const successor = getters['successor'](provisionalStatus.id);

      if (successor) {
        return successor.startDateTime;
      }

      return null;
    };
  },
  minStartDateTime(state, getters, rootState, rootGetters) {
    return (provisionalStatus) =>
    {
      let minimal = moment().subtract(6, 'hours');

      // If there is an preceding dosing instruction, update the minimal based on this predecessor
      // (Als er een voorgaande doseer instructie is, dan is de minimale tijd minimaal de minimale tijd gebaseerd op deze voorganger)
      const predecessor = getters['predecessor'](provisionalStatus);
      if (predecessor) {
        // min_based_on_predecessor is a moment() object
        const min_based_on_predecessor = getters['min_start_datetime_based_on_predecessor'](predecessor);
        if (min_based_on_predecessor > minimal) {
          minimal = min_based_on_predecessor;
        }
      }

      // If are changing an existing instruction which is active
      // the minimal start moment datetime (mutation datetime) is the start moment datetime of the original instruction in database
      // For corrections this is handled differently (min_start_moment_datetime_for_correction)
      // (Als we een doseer instructie wijzigen die nu actief is, dan is de minimale start van een nieuwe mutatie, de start van de actieve
      // instructie in de database. Voor correcties is dit overigens niet het geval en gebruiken we (min_start_moment_datetime_for_correction))
      if (!Utility.is_new_id(provisionalStatus.id)) {
        const originalInstruction = getters['find_db_model'](provisionalStatus.id);
        const originalIsActive = originalInstruction.startDateTime < moment().format('YYYY-MM-DD HH:mm');

        if (originalIsActive && minimal < moment(originalInstruction.startDateTime)) {
          minimal = moment(originalInstruction.provisionalStatus);
        }
      }

      return minimal.format('YYYY-MM-DD HH:mm');
    };
  },
  minStartDateTimeBasedOnPredecessor(state, getters) {
    return (predecessor) => {
      const minimal_default_6hours_ago = moment().subtract(6, 'hours');

      let min_based_on_predecessor = moment(predecessor.startDateTime);
      if (predecessor.temporaryStop) {
        min_based_on_predecessor = moment(predecessor.stopDateTime);
      }
      if (min_based_on_predecessor > minimal_default_6hours_ago) {
        return min_based_on_predecessor;
      }
      return minimal_default_6hours_ago;
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  async store({state, commit, dispatch, getters, rootState}, payload) {
    await dispatch('api/postEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patientId + '/provisionals/',
      data: {...payload.provisional, ...{action: payload.action}},
    }, {root: true}).then(response => {
      // And add to data
      const model = response.data.data;
      dispatch('extendBackendInputs', [model]).then((extendedModels) => {
        dispatch('add_with_shadow', extendedModels[0]);
      });
    });
  },
  async fetchSingle({state, commit, dispatch, getters, rootState}, payload) {
    await dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patientId + '/provisionals/' + payload.provisionalId,
    }, {root: true}).then(async response => {
      const model = response.data.data;
      await dispatch('extendBackendInputs', [model]).then(async (extendedModels) => {
        await dispatch('add_with_shadow', extendedModels[0]);
      });
    });
  },
  async fetchAll({state, commit, dispatch, getters, rootState}, payload) {
    await dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patientId + '/combined',
    }, {root: true}).then(async response => {
      const allModels = response.data.data.statuses.concat(response.data.data.medicalDevices, response.data.data.provisionalStatuses);
      await dispatch('extendBackendInputs', allModels).then(async (extendedModels) => {
        await dispatch('add_with_shadow_multi', extendedModels);
      });
    });
  },
  async fetchFromStatus({state, commit, dispatch, getters, rootState}, payload) {
    await dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patientId + '/provisionals/create-from-status/' + payload.statusId,
    }, {root: true}).then(async response => {
      const model = response.data.data;
      await dispatch('extendBackendInputs', [model]).then(async (extendedModels) => {
        // We voegen hem hier alleen aan de shadow toe, want tijdelijk
        await dispatch('add_with_shadow', extendedModels[0]);
      });
    });
  },
  deleteSingle({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/deleteEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patientId + '/provisionals/' + payload.provisionalId,
    }, {root: true}).then(() => {
      commit('remove_from_data', {id: payload.provisionalId});
    });
  },
  async extendBackendInputs({state, commit, dispatch, getters, rootState, rootGetters}, provisionalStatuses) {
    // Custom versie voor de Provisional statuses
    provisionalStatuses.forEach((provisionalStatus) => {

      // Zit niet in Provisionals?
      // if (dosingInstruction.wcia_string !== '') {
      //
      //   const customSignal =
      //     {
      //       title: 'Oorspronkelijke dosering',
      //       subTitle: dosingInstruction.wcia_string,
      //       message: '',
      //       patientId: dosingInstruction.patient_id,
      //     };
      //
      //   dosingInstruction.custom_medication_guard_signals = [customSignal];
      // }

      if (provisionalStatus.times) {
        return provisionalStatus;
      }

      const patient = rootGetters['patients/find'](provisionalStatus.patientId);
      const infusionInterval = rootGetters['dosage_intervals/is_infusion_interval'](provisionalStatus.dosageInterval);

      let times = [];
      if (!infusionInterval) {
        times = Quantity.decodeQuantity(provisionalStatus.quantity);
      }

      const standardTimes = rootGetters['wards/default_times'](patient.ward.id);
      const notStandardTimes = times.filter( (timeValue) => {
        return timeValue.time !== "" && !standardTimes.includes(timeValue.time);
      });

      if (notStandardTimes.length === 0) {
        provisionalStatus.times = standardTimes.map( (standardTime) => {
          const entry = Utility.find_model_by_property(times, 'time', standardTime);
          const value = entry ? entry.value : '0';
          return {time: standardTime, value: value};
        });
      }
      else {
        provisionalStatus.times = times;
      }

      if (provisionalStatus.schema_id) {
        provisionalStatus.prescriptionType = 3;
      }
      else if (provisionalStatus.usageType === 'S') {
        provisionalStatus.prescriptionType = 4;
      } else {
        if (notStandardTimes.length === 0) {
          provisionalStatus.prescriptionType = 1;
        } else {
          provisionalStatus.prescriptionType = 2;
        }
      }

      // Check if we have pre defined times with value, but without time.
      // If so we show the advanced screen, else the validation will
      // throw an error that the filled in times are invalid.
      const checkForEmptyTimes = times.filter((time) => {
        return time.time === '' && time.value !== '0';
      });

      if (checkForEmptyTimes.length > 0 && times.length !== checkForEmptyTimes.length) {
        provisionalStatus.prescriptionType = 2;
      }
    });

    return provisionalStatuses;
  },
  set_quantity_value({state, commit, dispatch, getters, rootState}, payload) {
    const index = payload.index;
    const value = payload.value;
    const dosageInstruction = payload.dosageInstruction;

    const updated = Utility.deep_clone(dosageInstruction.times);
    updated[index].value = value;

    dosageInstruction.times = updated; // Kan hier normaal en shadow zijn, gaat goed in Vue3
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  increaseTmpId (state, id) {
    state.tmpId++;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
