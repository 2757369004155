enum Ucum {
  Week = 'wk',
  Day = 'd',
  Year = 'a',
  Month = 'mo',

  Second = 's',
  Minute = 'min',
  Hour = 'h',
}

export default Ucum;
