import BaseModule from '@/vue/store/modules/base_module';
import Utility from "@/vue/utility/utility";

const state = {
  ...BaseModule.state,
  filter_columns: ['name', 'administrationRoutes'],
  data: [],
  columns: [],
  sort_columns: [
    {column_name: 'name', direction: 'ASC'}
  ],
};

const getters = {
  ...BaseModule.getters,
};

const actions = {
  ...BaseModule.actions,
};

const mutations = {
  ...BaseModule.mutations,
  update_administration_area_setting(state, payload) {
    const index = Utility.find_index_of_matching_element(state.data, 'id', payload.id);
    const settingIndex = Utility.find_index_of_matching_element(state.data[index].settings, 'route', payload.route);
    state.data[index].settings[settingIndex].value = payload.checked;
  },
  set_administration_areas_columns(state, payload) {
    state.columns = payload.columns;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
