import {GeneratedPharmaceuticalProduct} from '@/domain/models/generated/GeneratedPharmaceuticalProduct';

export class PharmaceuticalProduct extends GeneratedPharmaceuticalProduct {
  public reference(): string {
    if (this.zi) {
      return 'zi ' + this.zi;
    }

    if (this.hpk) {
      return 'hpk ' + this.hpk;
    }

    if (this.prk) {
      return 'prk ' + this.prk;
    }

    if (this.gpk) {
      return 'gpk ' + this.gpk;
    }

    if (this.isMagistralPreparation) {
      return 'e.p.';
    }

    return '';
  }
}
