import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,

  data: [],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  set_data(state, payload) {
    state.data = payload.data;
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions: actions,
  mutations,
};
