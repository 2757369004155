<template>
    <!--
        This one's a mess, trying too many things at once. Apologies.
        Ideally we'd have 1 that's Checkbox only and one with label (instead of just a wrapper of this component)
        Maybe even a left and right version.
    -->
    <div :class="'medimo-checkbox align-items-center ' + (just_the_checkbox ? 'just_the_checkbox' : '') + ' ' + (inline && !just_the_checkbox ? 'row' : '') + ' ' + validation_class + ' ' + extra_classes">
      <div :class="'checkbox-label ' + label_position + ' ' + (inline ? 'inline' : '') + ' ' + (inline && !just_the_checkbox ? 'col-' + label_cols : 'col-12')  + ' ' + (label_position === 'right' ? 'order-12' : '') + ' ' + (help_text.length ? 'with-help-text' : '')"
           :style="(labelClickable ? 'cursor: pointer;' : '')"
           v-if="$slots['label'] || label.length"
           @click="onLabelClick"
      >
        <label v-if="label.length">{{ label }}</label>
        <slot name="label" v-else></slot>
      </div>
      <div :id="id? id : false" :class="'checkbox-container ' + (inline && !just_the_checkbox ? 'col-auto' : (just_the_checkbox ? '' : 'col-12')) + ' ' + (label_position === 'right' ? 'order-1' : '') + ' ' + extra_classes_container">
        <input :id="input_id"
               :name="name"
               type="checkbox"
               :class="(checked ? 'isChecked' : 'notChecked') + ' ' + (indeterminate ? 'isIndeterminate' : '')"
               :checked="checked ? 'checked' : ''"
               :value="checked ? 'on': ''"
               :disabled="disabled"
               :indeterminate.prop="indeterminate"
        >
        <span class="checkmark" v-bind:class="{disabled: disabled}" @click="onClick"></span>
      <medimo-tooltip v-if="help_text.length" :content="help_text" :center="false">
        <fa-icon icon="fa-solid fa-circle-question" class="text-primary" size="lg"/>
      </medimo-tooltip>
    </div>
  </div>

</template>

<script>
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';

export default {

  components: {
    MedimoTooltip,
  },

  emits: ['update:modelValue'],

  props: {
    // Set to TRUE to get a "normal" checkbox with no rows, cols, or whatever
    'just_the_checkbox': {default: false},

    // Set to true to get a single line with the label in there
    'inline': {default: true},
    'modelValue': {default: '', type: [String, Number, Boolean, null]},
    'indeterminate': {default: false},

    'id': {default: false},
    'input_id': {default: false},

    // Use the <medimo-labeled-checkbox> if you want to add a label
    'label': {default: ''},
    'labelClickable': {default: false},
    'label_position': {default: 'left'},

    'name': {default: 'checkbox'},

    'extra_classes': {default: ''},
    'extra_classes_container': {default: ''},
    'validation_class': {default: ''},

    'label_cols': {default: 'auto'},

    'help_text': {default: ''},

    'disabled': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      checked: false,
    };
  },

  computed: {
    checkbox_cols() {
      if (this.label_position === 'right') {
        return 1;
      }
      return 12 - this.label_cols;
    }
  },

  created() {
    // We can't modify the prop, so we work around that with the checked value
    this.checked = this.modelValue;
  },

  mounted() {
    //
  },

  methods: {
    onLabelClick() {
      if (this.labelClickable) {
        this.onClick();
      }
    },
    onClick() {
      if (this.disabled) {
        return null;
      }

      this.checked = !this.checked;

      // And emit back up so it works with any v-models
      this.$emit('update:modelValue', this.checked);
    }
  },

  watch: {
    modelValue(newValue) {
      if (newValue !== this.checked) {
        this.checked = newValue;
      }
    }
  }
};
</script>
