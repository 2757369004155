import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {CodeSystem} from '@/domain/models/CodeSystem';

export class GeneratedIdentifierObject { 
  public identifier: string | null;
  public system: CodeSystem | null;

  constructor(identifier: string | null = null, system: CodeSystem | null = null) {
    this.identifier = identifier;
    this.system = system;
  }

  public toJSON(): unknown {
    return {
      identifier: this.identifier,
      system: this.system,
    };
  }

  public static fromJson(json: unknown): IdentifierObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new IdentifierObject(
      parsedJson['identifier'] as string,
      parsedJson['system'] ? CodeSystem.fromJson(parsedJson['system']) : null,
    );
  }

  public static multipleFromJson(json: unknown): IdentifierObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
