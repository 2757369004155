import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,

  search_endpoint: '/api/v1/locations/search',

  data: [],
  filter_columns: [],
  search_columns: [],

  quick_filters: {},
  default_quick_filters: {},

  sort_columns: [{column_name: 'naam', direction: 'ASC'},],
  default_sort_columns: [{column_name: 'naam', direction: 'ASC'},],
};

const getters = {
  ...BaseModule.getters
};

const actions = {
  ...BaseModule.actions,
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/locations/single/' + payload.id,
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
};

const mutations = {
  ...BaseModule.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
