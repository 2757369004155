import {ParticularitiesObject} from '@/domain/models/ParticularitiesObject';

export class GeneratedParticularitiesObject { 
  public formularium: string | null;
  public inDdsAssortment: boolean | null;
  public isEmergencySupply: boolean | null;
  public isArbo: boolean | null;
  public isRefrigerated: boolean | null;
  public isOpium: boolean | null;
  public isOffTheMarket: boolean | null;
  public emergencySupplyLabel: string | null;
  public arboLabel: string | null;
  public refrigeratedLabel: string | null;
  public opiumLabel: string | null;
  public offTheMarketLabel: string | null;

  constructor(formularium: string | null = null, inDdsAssortment: boolean | null = null, isEmergencySupply: boolean | null = null, isArbo: boolean | null = null, isRefrigerated: boolean | null = null, isOpium: boolean | null = null, isOffTheMarket: boolean | null = null, emergencySupplyLabel: string | null = null, arboLabel: string | null = null, refrigeratedLabel: string | null = null, opiumLabel: string | null = null, offTheMarketLabel: string | null = null) {
    this.formularium = formularium;
    this.inDdsAssortment = inDdsAssortment;
    this.isEmergencySupply = isEmergencySupply;
    this.isArbo = isArbo;
    this.isRefrigerated = isRefrigerated;
    this.isOpium = isOpium;
    this.isOffTheMarket = isOffTheMarket;
    this.emergencySupplyLabel = emergencySupplyLabel;
    this.arboLabel = arboLabel;
    this.refrigeratedLabel = refrigeratedLabel;
    this.opiumLabel = opiumLabel;
    this.offTheMarketLabel = offTheMarketLabel;
  }

  public toJSON(): unknown {
    return {
      formularium: this.formularium,
      inDdsAssortment: this.inDdsAssortment,
      isEmergencySupply: this.isEmergencySupply,
      isArbo: this.isArbo,
      isRefrigerated: this.isRefrigerated,
      isOpium: this.isOpium,
      isOffTheMarket: this.isOffTheMarket,
      emergencySupplyLabel: this.emergencySupplyLabel,
      arboLabel: this.arboLabel,
      refrigeratedLabel: this.refrigeratedLabel,
      opiumLabel: this.opiumLabel,
      offTheMarketLabel: this.offTheMarketLabel,
    };
  }

  public static fromJson(json: unknown): ParticularitiesObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new ParticularitiesObject(
      parsedJson['formularium'] as string,
      parsedJson['inDdsAssortment'] as boolean,
      parsedJson['isEmergencySupply'] as boolean,
      parsedJson['isArbo'] as boolean,
      parsedJson['isRefrigerated'] as boolean,
      parsedJson['isOpium'] as boolean,
      parsedJson['isOffTheMarket'] as boolean,
      parsedJson['emergencySupplyLabel'] as string,
      parsedJson['arboLabel'] as string,
      parsedJson['refrigeratedLabel'] as string,
      parsedJson['opiumLabel'] as string,
      parsedJson['offTheMarketLabel'] as string,
    );
  }

  public static multipleFromJson(json: unknown): ParticularitiesObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
