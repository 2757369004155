<template>
  <MedimoTr :link="editLink" :navigateHard="props.navigateHard" :navigateOnClick="navigateOnClick" @triggered="emit('triggered')">
    <template v-for="(column, columnName) in props.columns">
      <MedimoTargetSelectorTdExtended v-if="isExtendedColumn(column)"
          :column="column as ExtendedColumn"
          :columnClasses="props.columnClasses[columnName] ?? 'col'">
        {{ props.target[columnName] }}
      </MedimoTargetSelectorTdExtended>
      <MedimoTd v-else
          :column_classes="props.columnClasses[columnName] ?? 'col'">
          {{ props.target[columnName] }}
      </MedimoTd>
    </template>
    </MedimoTr>
</template>

<script setup lang="ts">
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTd from "@/vue/components/general/table/MedimoTd.vue";
import {computed} from "vue";
import {useRoute} from "vue-router";
import {ExtendedColumn, isExtendedColumn} from "@/vue/components/selectors/targetselector/ExtendedColumn";
import MedimoTargetSelectorTdExtended from "@/vue/components/selectors/targetselector/MedimoTargetSelectorTdExtended.vue";

const route = useRoute();
const emit = defineEmits(['triggered']);

interface Props {
  target: {[key: string]: string | number | null},
  columns: {[key: string]: string | number | null | ExtendedColumn},
  columnClasses: { [key: string]: string },
  navigateHard: boolean,
  navigateOnClick?: boolean,
  navigateToUrl?: Function,
}

const props = withDefaults(defineProps<Props>(), {
  navigateHard: false,
  navigateOnClick: true,
});

const editLink = computed(() => {
  if (props.navigateToUrl) {
    return props.navigateToUrl(props.target);
  }

  if (props.target.editLink) {
    return props.target.editLink;
  } else if (props.target.id) {
    // guess new endpoint
    let endpoint = route.path + '/' + props.target.id;
    let first = true;
    // keep query params
    Object.keys(route.query).forEach(key => {
      const param = route.query[key];
      if (Array.isArray(param)) {
        param.forEach(value => {
          endpoint += (first ? '?' : '&') + key + '=' + value;
          first = false;
        });
      } else {
        endpoint += (first ? '?' : '&') + key + '=' + param;
        first = false;
      }
    });
    return endpoint;
  }

  return '';
});

</script>
