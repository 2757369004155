import Utility from '@/vue/utility/utility';
import BaseShadowModule from '@/vue/store/modules/base_shadow_module';
import Export from '@/vue/utility/export';

// initial state
// shape: [{ id, quantity }]
const state = {
  query: '',

  // Dit is voor de MedimoLocalDataTable
  // Supports dot notation. If you want to filter by a child's value, or nested value you can use:
  // 'medication_agreements.dosage_instructions.quantity_timing_full'
  // Do make sure the value exists when you filter like that. Obviously ;-)
  filter_columns: [],

  // Use this one to pre-limit the result to a set of IDs. Handy to just filter by IDs, or search within a specific subset.
  // Works in tandem with the rest. Do not forget to set the id column you want to filter by as a search or it won't work obviously.
  // You can use this creatively as well to search for multiple matches, essentially an "or or or" search. Just specify:
  // ["paracetamol","metropolol"] and then as search columns ['naam'] and that will work as well ;-)
  // I'll see if I can work that into it's own feature later.
  filter_by_ids: [],

  // Is an array with objects that define the sorting order, and ASC or DESC
  // Syntax is: [{column_name: 'column_name1', direction: 'ASC'}, {column_name: 'column_name2', direction: 'DESC'}]
  // The column name accepts nested properties like: [{column_name:'medication_treatments.medication_agreements.dosage_instructions.start_datetime', direction: 'ASC'}]
  // Use the mutations to add, remove or change a sort
  sort_columns: [],
  // default_sort_columns: [], <-- deze toevoegen als je een default wil gebruiken die altijd gerestored wordt (mits niet opgeslagen)

  // Dit is voor de MedimoExternalDataTable
  // Define what columns you want to search for here. Externally. This will override the default value set in a Controller
  // and send the column names to the API endpoint.
  // So if you want to confine a search to just one specific column, you set ['one'] element in this array, if you want multiple
  // just set ['more','than','one'] :)
  search_columns: [],

  // Dit is voor de MedimoLocalDataTable
  export_columns: [],

  // Als je nog extra waardes mee wilt sturen zoals bijvoorbeeld "from" of iets anders dat je in de controller
  // nodig hebt, dan kan dat hier. De syntax is:
  // {parameterProperty: parameterValue}
  params: {},

  // Don't set this value, it will set itself. Used to reset the search_columns back to the store default, when applicable
  default_search_columns: null,

  // Is an array with objects that tell the ApiController to use a QuickFilter or not.
  // Quickfilters are specifically defined functions in the controller that will be called when the request contains them
  // This can be a simple column value toggle, or a more elaborate query
  // The Syntax is: {quick_filter_name_1: true, quick_filter_name_2: false}
  // Respective buttons will always be shown in a MedimoExternalDatable
  quick_filters: {
    // 'Formularium': false,    // -> triggers a quickFilterFormularium function
    // 'GDS': false,            // -> triggers a quickFilterGds function
    // 'Noodvoorraad': false,   // -> triggers a quickFilterNoodvoorraad function
    // 'Noodvoorraad': {
    //   value: true,
    //   visible: false,
    // },   // Zet een quickfilter altijd aan, en disabled de interactie voor de user

    // Grouped quick filter:
    // 'Medicijnen': {                    // The group name - only internally used
    //   'mustBeActive': true,             // Option to enforce this group to always have one filter active
    //   'group': {                       // Array with the grouped filters. This one indicates this is a grouped quick_filter instead of a normal one
    //     'Alleen Paracetamol': false,   // Quick filter itself
    //     'Alleen Ibuprofen': false,
    //     'Alleen Iets Anders': {
    //       value: true,
    //       visible: false,
    //     },
    //   }
    // },
  },
  // default_quick_filters: [], <-- deze toevoegen als je een default wil gebruiken die altijd gerestored wordt (mits niet opgeslagen)

  // Set to TRUE to allow only 1 quick filter to be active at once.
  // Automatically sets all other to FALSE when setting a new one to TRUE
  //
  // Zet deze op FALSE als je grouped quickfilters icm normale quick filters
  // gebruikt. De grouped filters togglen dan automatisch al binnen die groep.
  toggleQuickFilters: false,
  // Zet deze op TRUE om te garanderen dat er altijd 1 quick_filter actief is.
  mustbeActive: false,

  // Deze zorgt er voor dat de quick filters in de store gebruikt worden
  // de MedimoLocalDatatable stuurt die verder aan.
  useLocalQuickFilters: false,

  db_data: [],      // Database models
  shadow_data: [],  // Temporary models when editing
  data: [],         // Local updated models

  /*
   |--------------------------------------------------------------------------
   | Search / ExternalDataTable related variables
   |--------------------------------------------------------------------------
   */
  // If it's null, it will throw an error when searching with the default action.
  // Use the set_search_endpoint mutation to configure a dynamic endpoint
  search_endpoint: null,
  // Als je wil exporteren kun je dat endpoint hier instellen. Deze krijgt eenzelfde query
  // als het search_endpoint maar zal server side anders verwerkt worden
  export_endpoint: null,
  select_endpoint: null,
  is_exporting: false, // Met deze houden we in de gaten of er al een export bezig is
  // Stores the results of a backend search
  search_results: [],
  // For the meta data of a search, total amount, pages, current_page etc.
  search_meta: {
    current_page: 0,
    total: 0,
    per_page: 10,
    to: 0,
    from: 0,
  },
  // This one Stores the last search action Promise, so we don't accidentally show old search results due to network latency
  last_search: null,
  searchAbortController: null,

  /*
   |--------------------------------------------------------------------------
   | LocalDataTable related variables
   |--------------------------------------------------------------------------
   */
  per_page: 10,
  current_page: 1,

  /*
   |--------------------------------------------------------------------------
   | DataTable related variables
   |--------------------------------------------------------------------------
   */

};

// getters
const getters = {
  state(state, getters, rootState, rootGetters) {
    return (property) => {
      return state[property];
    };
  },
  filter_columns(state, getters, rootState) {
    return state.filter_columns;
  },
  search_columns(state, getters, rootState) {
    return state.search_columns;
  },
  find(state, getters, rootState) {
    return (id) => Utility.find_model_by_id(state.data, id);
  },
  find_by_prop(state, getters, rootState) {
    return (payload) => Utility.find_model_by_property(state.data, payload.property, payload.value);
  },
  find_all_by_prop(state, getters, rootState) {
    return (payload) => Utility.find_models_by_property(state.data, payload.property, payload.value);
  },
  all(state, getters, rootState) {
    return state.data;
  },
  data(state, getters, rootState) {
    return state.data;
  },
  params(state, getters, rootState) {
    return state.params;
  },
  filtered_search_results(state, getters, rootState) {
    const filtered_data =  state.search_results.filter((model) => {
      return Utility.matches_filter_query(model, state.query, state.filter_columns);
    });
    return getters.privacy_filtered_data(filtered_data);
  },
  filtered_data(state, getters, rootState) {
    const filtered_data = state.data.filter((model) => {
      return Utility.matches_filter_query(model, state.query, state.filter_columns);
    });
    return getters.privacy_filtered_data(filtered_data);
  },
  privacy_filtered_data(state, getters, rootState) {
  // En hiermee passen we voor de DataTables data ook de privacy filter toe, mits relevant
    return (filtered_data) => {
      // _als_ een DataTable gebruikt wordt zal de privacy_filter nooit undefined zijn
      // wanneer er geen DataTable gebruikt wordt zal er geen filtering toegepast worden,
      // maar hoor je ook eigenlijk geen gebruik te maken van de filtered_data en filtered_search_results
      if (typeof state.privacy_filter !== 'undefined') {
        filtered_data = filtered_data.filter(model => {
          // Door beide op null te zetten bypass je de filter, bewust
          if (state.privacy_filter.property === null && state.privacy_filter.value === null) {
            return true;
          }
          return model[state.privacy_filter.property] == state.privacy_filter.value;
        });
      }
      return filtered_data;
    };
  },
  filtered_db_data(state, getters, rootState) {
    return state.db_data.filter((model) => {
      return Utility.matches_filter_query(model, state.query, state.filter_columns);
    });
  },
  filtered_local_data(state, getters, rootState) {
    return state.data.filter((model) => {
      return Utility.matches_filter_query(model, state.query, state.filter_columns);
    });
  },
  sorted_local_data(state, getters, rootState, rootGetters) {
    return Utility.sort_data_by_columns(getters.filtered_local_data, state.sort_columns);
  },
  sorted_db_data(state, getters, rootState, rootGetters) {
    return Utility.sort_data_by_columns(getters.filtered_db_data, state.sort_columns);
  },
  quick_filtered_data(state, getters, rootState, rootGetters) {
    // Als de quick_filters niet gedefinieerd zijn, of er zijn er 0, dan returnen we de ongewijzigde filtered_data
    if (
      typeof state.quick_filters === 'undefined'
      || Object.keys(state.quick_filters).length === 0
      || state.useLocalQuickFilters === false
    ) {
      return getters.filtered_data;
    }

    let data = getters.filtered_data;
    // if function returns true, quick_filter is handled
    const filterData = function (name, getter, value) {
      if (typeof value === 'boolean') {
        if (typeof getters[getter] !== 'undefined') {
          data = getters[getter](data, value);
          return true;
        }

        console.error('De quickFilter ' + name + ' is wel actief maar er is geen getter voor beschikbaar in de store. De getter zou de functie naam: "' + getter + '" moeten hebben.');
      }

      return false;
    };

    Object.keys(state.quick_filters).forEach(quick_filter_name => {
      const quick_filter_value = state.quick_filters[quick_filter_name];
      const quick_filter_getter = Utility.convert_to_snake_case(quick_filter_name) + '_quick_filter';

      // Simple quick filter
      if (filterData(quick_filter_name, quick_filter_getter, quick_filter_value)) {
        return; // return because we don't have to check the other filter types
      }

      // Somewhat more advanced filter with label
      if (typeof quick_filter_value.label === 'string') {
        if (filterData(quick_filter_name, quick_filter_getter, quick_filter_value.value)) {
          return; // return because we don't have to check the other filter types
        }
      }

      // Grouped quickfilters - same structure, but grouped
      if (typeof quick_filter_value.group !== 'undefined') {
        Object.keys(quick_filter_value.group).forEach(grouped_quick_filter_name => {
          const grouped_quick_filter_getter = Utility.convert_to_snake_case(grouped_quick_filter_name) + '_quick_filter';
          const grouped_quick_filter_value = quick_filter_value.group[grouped_quick_filter_name];
          // Simple quick filter
          if (filterData(grouped_quick_filter_name, grouped_quick_filter_getter, grouped_quick_filter_value)) {
            return; // return because we don't have to check the other filter types
          }

          // Somewhat more advanced filter with label
          if (typeof quick_filter_value.label === 'string') {
            filterData(grouped_quick_filter_name, grouped_quick_filter_getter, grouped_quick_filter_value.value);
          }
        });
      }
    });

    return data;
  },
  sorted_data(state, getters, rootState, rootGetters) {
    return Utility.sort_data_by_columns(getters.quick_filtered_data, state.sort_columns);
  },
  sort_columns(state, getters, rootState, rootGetters) {
    return state.sort_columns;
  },
  default_sort_columns(state, getters, rootState, rootGetters) {
    return state.default_sort_columns !== undefined ? state.default_sort_columns : state.sort_columns;
  },

  /*
   |--------------------------------------------------------------------------
   | Example Quick Filter Getter
   |--------------------------------------------------------------------------
   |
   | Dit is een example quick filter voor de Medimo Local Datatable
   |
   |
   */
  example_quick_filter(state, getters, rootState, rootGetters) {
    // Om deze filter te triggeren moet er een quick_filter met de naam "Example" zijn.
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        // Hier kun je logica toepassen die op basis van de model, of state, of ... waarde true/false teruggeeft
        return true;
      });
    };
  },


  /*
   |--------------------------------------------------------------------------
   | DataTable Getters
   |--------------------------------------------------------------------------
   |
   |
   |
   |
   */
  query(state, getters, rootState) {
    return state.query;
  },
  search_meta(state, getters, rootState) {
    return state.search_meta;
  },
  search_results(state, getters, rootState) {
    return getters.privacy_filtered_data(state.search_results);
  },
  quick_filters(state, getters, rootState) {
    return state.quick_filters;
  },
  default_quick_filters(state, getters, rootState) {
    return state.default_quick_filters !== undefined ? state.default_quick_filters : state.quick_filters;
  },
  find_search_result(state, getters, rootState) {
    return (id) => Utility.find_model_by_id(state.search_results, id);
  },
  quick_filter_status(state, getters, rootState) {
    return (quick_filter) => state.quick_filters[quick_filter];
  },
  /*
   |--------------------------------------------------------------------------
   | LocalDataTable related getters
   |--------------------------------------------------------------------------
   */
  current_page(state, getters, rootState) {
    return state.current_page;
  },
  per_page(state, getters, rootState) {
    return state.per_page;
  },
  local_data_table_data(state, getters, rootState) {
    return getters.sorted_data;
  },
  paginated_data(state, getters, rootState) {
    return getters.sorted_data.slice(getters.paginated_data_from - 1, (getters.paginated_data_to));
  },
  // From and To generate the integer in the array from which to load the data
  paginated_data_from(state, getters, rootState) {
    // Als het totaal 0 is, stond er toch 1 tot 0 van 0, dat is met de ? gefixt
    return (state.current_page * state.per_page - state.per_page + (getters.sorted_data.length ? 1 : 0));
  },
  paginated_data_to(state, getters, rootState) {
    const to = (state.current_page * state.per_page);

    // When the total amount of results is less than the amount per page, we return the total
    if (getters.sorted_data.length < to) {
      return getters.sorted_data.length;
    }
    return to;
  },
  paginated_meta_data(state, getters, rootState) {
    // Generate the meta data based on the currently filtered and sorted local data
    return {
      current_page: state.current_page,
      from: getters.paginated_data_from,
      last_page: Math.ceil((getters.sorted_data.length / state.per_page)),
      per_page: state.per_page,
      to: getters.paginated_data_to,
      total: getters.sorted_data.length,
    };
  },

  /*
   |--------------------------------------------------------------------------
   | Search related getterss
   |--------------------------------------------------------------------------
   */
  is_exporting(state, getters, rootState) {
    return state.is_exporting == true;
  },
  export_endpoint(state, getters, rootState) {
    return (payload) => {
      if (state.export_endpoint === null) {
        console.error('You need to define the export_endpoint with this.$store.commit("path/to/store/export_endpoint", "value")');
      }
      payload.endpoint = state.export_endpoint;
      return getters['endpoint'](payload);
    };
  },
  select_endpoint(state, getters, rootState) {
    return (payload) => {
      if (state.select_endpoint === null) {
        console.error('You need to define the select_endpoint with this.$store.commit("path/to/store/select_endpoint", "value")');
      }
      payload.endpoint = state.select_endpoint;
      return getters['endpoint'](payload);
    };
  },
  search_endpoint(state, getters, rootState) {
    return (payload) => {
      if (state.search_endpoint === null) {
        console.error('You need to define the search_endpoint with this.$store.commit("path/to/store/set_search_endpoint", "value")');
      }
      payload.endpoint = state.search_endpoint;
      return getters['endpoint'](payload);
    };
  },
  endpoint(state, getters, rootState) {
    return (payload) => {
      // Dit hele zooitje kan opgeruimd worden door met params te werken
      // dan is al die query ellende niet meer nodig en is het veel cleaner.
      // Kwestie van {data: {params: {urlParameter: value, urlParameter2: value}}}
      // in de dispatch api/getEndpoint hieronder mee te sturen.
      // Een refactor voor een andere dag.

      let endpoint = payload.endpoint;

      let concatenateCharacter = '?';
      if (endpoint.includes('?')) {
        concatenateCharacter = '&';
      }

      // If the Query is defined (and has an actual string) we include it.
      if (typeof payload.query !== 'undefined' && payload.query.length) {
        endpoint = endpoint + concatenateCharacter + 'query=' + encodeURIComponent(payload.query);
        concatenateCharacter = '&';
      }
      // If it's not explicitely defined, we use the State value
      else if (state.query.length > 0) {
        endpoint = endpoint + concatenateCharacter + 'query=' + encodeURIComponent(state.query);
        concatenateCharacter = '&';
      }

      // If the sort columns are defined, we add them here
      if (state.sort_columns.length) {
        // Loop throught the various column names and add them to an array URL parameter
        state.sort_columns.forEach((column, index) => {
          endpoint = endpoint + concatenateCharacter + 'sort_columns[' + column.column_name + ']=' + column.direction;
          concatenateCharacter = '&';
        });
      }

      // If any by_ids are defined, we add them here
      if (state.filter_by_ids.length) {
        // Loop throught the various filter_by_ids and add them to an array URL parameter
        state.filter_by_ids.forEach(filter_by_id => {
          endpoint = endpoint + concatenateCharacter + 'filter_by_ids[]=' + filter_by_id;
          concatenateCharacter = '&';
        });
      }

      // If any Search columns are defined, we add them here
      if (state.search_columns.length) {
        // Loop throught the various column names and add them to an array URL parameter
        state.search_columns.forEach(search_column => {
          endpoint = endpoint + concatenateCharacter + 'search_columns[]=' + search_column;
          concatenateCharacter = '&';
        });
      }

      // If any additional parameters are needed, they are added here
      if (Object.keys(state.params).length) {
        // Loop throught the various column names and add them to an array URL parameter
        Object.keys(state.params).forEach(param => {
          // Als hij null is kunnen we skippen
          if (state.params[param] === null) {
            return;
          }
          if (Array.isArray(state.params[param])) {
            Object.keys(state.params[param]).forEach((index) => {
              endpoint = endpoint + concatenateCharacter + param + '[]=' + state.params[param][index];
              concatenateCharacter = '&';
            });
          }
          else {
            endpoint = endpoint + concatenateCharacter + param + '=' + state.params[param];
            concatenateCharacter = '&';
          }
        });
      }

      function addQuickFilterToEndpoint(endpoint, quick_filter_name) {
        endpoint = endpoint + concatenateCharacter + 'quick_filters[]=' + quick_filter_name.toLowerCase();
        concatenateCharacter = '&';
        return endpoint;
      }
      function addQuickFilter(quick_filter_name, quick_filter_value) {
        // Een quick filter kan op 3 verschillende manieren "true" zijn
        // Met een reguliere waarde:
        if (quick_filter_value === true) {
          endpoint = addQuickFilterToEndpoint(endpoint, quick_filter_name);
        }
        // Omdat het een object is waar de "value" op true staat. Dit is voor onzichtbare quickFilters
        if (typeof quick_filter_value.value !== 'undefined'
          && quick_filter_value.value) {
          endpoint = addQuickFilterToEndpoint(endpoint, quick_filter_name);
        }
      }
      // If any quick filters are defined, we add them here
      if (Object.keys(state.quick_filters).length && !state.useLocalQuickFilters) {

        // Loop throught the quick filters add them to an array URL parameter
        Object.keys(state.quick_filters).forEach(quick_filter_name => {
          // Reguliere quickfilters
          addQuickFilter(quick_filter_name, state.quick_filters[quick_filter_name]);

          // Grouped quickfilters - zelfde structuur is dan mogelijk, alleen zijn ze grouped
          // in de quick_filters zelf
          if (typeof state.quick_filters[quick_filter_name].group !== 'undefined') {
            const quick_filter_group_name = quick_filter_name;
            Object.keys(state.quick_filters[quick_filter_group_name].group).forEach(quick_filter_name => {
              addQuickFilter(quick_filter_name, state.quick_filters[quick_filter_group_name].group[quick_filter_name]);
            });
          }

        });
      }

      // If we have a page number, we can add that here as well
      if (typeof payload.page !== 'undefined') {
        endpoint = endpoint + concatenateCharacter + 'page=' + payload.page;
        concatenateCharacter = '&';
      }

      // If we have a results_per_page variable, we can add that here as well
      if (payload.results_per_page && Number.isInteger(payload.results_per_page)) {
        endpoint = endpoint + concatenateCharacter + 'results_per_page=' + payload.results_per_page;
        concatenateCharacter = '&';
      }

      return endpoint;
    };
  },

  ...BaseShadowModule.getters
};

// actions
const actions = {
  /**
   * Handige action die async een hele array aan data toevoegd. Anders moet je overal in de code deze loop definieren,
   * op deze manier kunnen we hem simpel aanroepen voor bijv. het verwerken van een API response met modellen in een array
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param payload
   */
  add_to_data_by_array ({state, commit, dispatch, getters, rootState}, payload) {
    payload.data.forEach(model => {
      commit('add_to_data', model);
    });
  },

  /**
   * Queries a Search endpoint and stores the Data response object to the results
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param payload
   * @returns {*}
   */
  search({state, commit, dispatch, getters, rootState}, payload) {
    dispatch('abortSearch');

    // Met de AbortController, niet de cancelToken, die is deprecated:
    // https://axios-http.com/docs/cancellation
    state.searchAbortController = new AbortController();

    const endpoint = getters['search_endpoint'](payload);
    const promise = dispatch('api/getEndpoint', {
      endpoint: endpoint,
      rejectErrors: true,
      config: {
        signal: state.searchAbortController.signal,
      }
    }, {root: true});

    // Save the search for a check after it's been resolved
    commit('set_last_search', promise);

    promise.then(response => {
      if (typeof response !== 'undefined') {
        // Here we make sure only the last search of this store is processed
        if (promise == state.last_search) {
          commit('set_search_results', response.data.data);
          commit('set_search_meta', response.data.meta);
        }
      }
    }).catch(error => {
      //
    });

    return promise;
  },

  abortSearch({state, commit, dispatch, getters, rootState}, payload) {
    if (state.searchAbortController !== null) {
      state.searchAbortController.abort();
    }
  },

  select({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = getters['select_endpoint'](payload);

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      rejectErrors: true,
    }, {root: true});
  },

  /**
   * Exports the data in a similar way that you'd otherwise search for results
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param payload
   * @returns {*}
   */
  export({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = getters['export_endpoint'](payload);
    commit('set_state', {property: 'is_exporting', value: true});

    const promise = dispatch('api/postEndpoint', {
      endpoint: endpoint,
      rejectErrors: true,
    }, {root: true});

    // Save the search for a check after it's been resolved
    commit('set_last_search', promise);

    promise.catch(response => {
      commit('set_state', {property: 'is_exporting', value: false});
    });
    promise.then(response => {
      // Here we make sure only the last search of this store is processed
      if (promise == state.last_search) {
        const downloadUrl = response.data.data;

        commit('set_state', {property: 'is_exporting', value: false});

        axios.get(downloadUrl, {}, {responseType: 'blob'})
          .then(function (response) {
            const fileName = response.headers["content-disposition"].split("filename=")[1];
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
              window.navigator.msSaveOrOpenBlob(new Blob([response.data], {
                type: 'text/csv'
              }), fileName);
            }
            else {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
              document.body.appendChild(link);
              link.click();
            }
          }
          );
      }
    });

    return promise;
  },
  export_local_data({state, commit, dispatch, getters, rootState}, payload) {
    let data = getters['local_data_table_data'];
    if (data.length === 0) {
      return dispatch('notifications/addDangerAlert', {message: 'Geen resultaten om te exporteren'}, {root: true});
    }
    if (state.export_columns.length > 0) {
      const exportData = [];
      data.forEach(model => {
        const exportModel = {};
        state.export_columns.forEach(column => {
          exportModel[column] = model[column];
        });
        exportData.push(exportModel);
        data = exportData;
      });
    }
    Export.exportToCsv(data);
  },

  /**
   * Updates the Sort columns by input, works with either ASC, DESC or empty (removes the column)
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param payload
   * @returns {*}
   */
  sort({state, commit, dispatch, getters, rootState}, payload) {
    if (payload.direction === 'DESC' || payload.direction === 'ASC') {
      commit('sort', payload);
    } else if (payload.direction === '') {
      commit('sort_clear', payload.column_name);
    }
  },

  ...BaseShadowModule.actions
};

// mutations
const mutations = {
  clear_data(state) {
    state.data = [];
  },
  set_data(state, new_data) {
    state.data = new_data;
  },
  set_meta(state, new_meta) {
    state.meta = new_meta;
  },
  set_state(state, payload) {
    state[payload.property] = payload.value;
  },
  set_search_results(state, search_results) {
    state.search_results = search_results;
  },
  set_search_meta(state, search_meta) {
    state.search_meta = search_meta;
  },
  clear_search_meta(state, search_meta) {
    state.search_meta = {
      current_page: 0,
      total: 0,
      per_page: 10,
      to: 0,
      from: 0,
    };
  },
  set_last_search(state, promise) {
    state.last_search = promise;
  },
  set_search_endpoint(state, value) {
    state.search_endpoint = value;
  },
  set_select_endpoint(state, value) {
    state.select_endpoint = value;
  },
  set_export_endpoint(state, value) {
    state.export_endpoint = value;
  },
  clear_search_results(state) {
    state.search_results = [];
  },
  clear_search_columns(state) {
    state.search_columns = [];
  },
  clear_quick_filters(state) {
    Object.keys(state.quick_filters).forEach(quick_filter => {
      const filter = state.quick_filters[quick_filter];
      if (typeof filter === 'object') {
        if (typeof filter.group === 'object') {
          Object.keys(filter.group).forEach(grouped_quick_filter => {
            if (typeof filter.group[grouped_quick_filter] === 'object') {
              state.quick_filters[quick_filter].group[grouped_quick_filter].value = false;
            } else {
              state.quick_filters[quick_filter].group[grouped_quick_filter] = false;
            }
          });
        } else {
          state.quick_filters[quick_filter].value = false;
        }
      } else {
        state.quick_filters[quick_filter] = false;
      }
    });
  },
  clear_search_settings(state) {
    Object.keys(state.quick_filters).forEach(quick_filter => {
      const filter = state.quick_filters[quick_filter];
      if (typeof filter === 'object') {
        if (typeof filter.group === 'object') {
          Object.keys(filter.group).forEach(grouped_quick_filter => {
            if (typeof filter.group[grouped_quick_filter] === 'object') {
              state.quick_filters[quick_filter].group[grouped_quick_filter].value = false;
            } else {
              state.quick_filters[quick_filter].group[grouped_quick_filter] = false;
            }
          });
        } else {
          state.quick_filters[quick_filter].value = false;
        }
      } else {
        state.quick_filters[quick_filter] = false;
      }
    });

    // When the search_columns have been altered, the default_search_columns will contain the first (i.e. default) value.
    // We want to re-set them back to default here.
    if (state.default_search_columns !== null) {
      state.search_columns = state.default_search_columns;
    }

    state.params = {};
    state.filter_by_ids = [];
    state.search_results = [];
    state.query = '';
  },
  update_data_element(state, updated_model) {
    if (Utility.id_already_exists(state.data, updated_model.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.data, 'id', updated_model.id);
      state.data[existing_index] = updated_model;
    }
  },
  update_selected_search_result(state, updated_model) {
    if (Utility.id_already_exists(state.search_results, updated_model.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.search_results, 'id', updated_model.id);
      state.search_results[existing_index] = updated_model;
    }
  },
  update_or_create_selected_search_result(state, updated_model) {
    if (Utility.id_already_exists(state.search_results, updated_model.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.search_results, 'id', updated_model.id);
      state.search_results[existing_index] = updated_model;
    }
    else {
      state.search_results.push(updated_model);
    }
  },
  toggle_quick_filter(state, quick_filter) {
    const quick_filter_name = quick_filter;
    Object.keys(state.quick_filters).forEach(quick_filter => {
      const filter = state.quick_filters[quick_filter];
      // De quick filter kan ook een quick filter group zijn, of een onzichtbare quick filter.
      // In beide gevallen willen we de quick_filter hier negeren
      if (typeof filter === 'object' && (typeof filter.group === 'object' || (typeof filter.visible !== 'undefined' && filter.visible === false))) {
        return;
      }

      let toggleTo = false;
      if (quick_filter_name === quick_filter) {
        if (state.mustBeActive) {
          toggleTo = true;
        } else {
          if (typeof state.quick_filters[quick_filter] === 'object') {
            toggleTo = !state.quick_filters[quick_filter].value;
          } else {
            toggleTo = !state.quick_filters[quick_filter];
          }
        }
      }

      // Set all quick_filters to FALSE if toggleQuickFilters is enabled.
      if (state.toggleQuickFilters || quick_filter_name === quick_filter) {
        if (typeof filter === 'object') {
          state.quick_filters[quick_filter].value = toggleTo;
        } else {
          state.quick_filters[quick_filter] = toggleTo;
        }
      }
    });
  },
  toggle_grouped_quick_filter(state, payload) {
    const quick_filter_group_name = payload.quick_filter_group_name;
    const quick_filter_name = payload.quick_filter_name;

    const group = state.quick_filters[quick_filter_group_name];
    const filters = group.group;

    Object.keys(filters).forEach(function(quick_filter) {
      // disable all quickFilters in the group
      let toggleTo = false;
      // If this is the toggling quickFilter, toggle
      if (quick_filter === quick_filter_name) {
        // if mustBeActive is enabled for this group, the newValue must be true (we need one active at all times)
        if (group.mustBeActive) {
          toggleTo = true;
        } else {
          // get the current value to toggle
          if (typeof filters[quick_filter] === 'object') {
            toggleTo = !filters[quick_filter].value;
          } else {
            toggleTo = !filters[quick_filter];
          }
        }
      }

      if (typeof filters[quick_filter] === 'object') {
        filters[quick_filter].value = toggleTo;
      } else {
        filters[quick_filter] = toggleTo;
      }
    });
  },
  set_quick_filter(state, quick_filter) {
    state.quick_filters[quick_filter.name] = quick_filter.value;
  },
  add_to_data(state, new_model) {
    new_model = Utility.deep_clone(new_model);
    if (state.data === null) {
      state.data = [new_model];
      return;
    }

    const new_data = state.data;

    // If an model with that ID already exists, we just replace it
    if (Utility.id_already_exists(state.data, new_model.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.data, 'id', new_model.id);
      state.data[existing_index] = new_model;
    }
    // If not, we add it to the top.
    else {
      new_data.unshift(new_model);
      state.data = new_data;
    }
  },
  /**
   * @param state
   * @param payload object Moet de id field bevatten, id_column en delay optioneel
   */
  remove_from_data(state, payload) {
    const id = payload.id;
    let id_column = 'id';
    if (typeof payload.id_column !== 'undefined') {
      id_column = payload.id_column;
    }
    let delay = 50;
    if (typeof payload.delay !== 'undefined') {
      delay = payload.delay;
    }

    // If a model with that ID exists, we find and remove it
    const indexToRemove = Utility.find_index_of_matching_element(state.data, id_column, id);

    // Met deze delay er in kunnen we bestaande DOM elementen sluiten nadat de commit
    // voltooid is om zo render errors te voorkomen. Als je het direct doet, gaan
    // dingen stuk omdat het model dan ineens niet meer bestaat
    setTimeout(() => {
      delete state.data[indexToRemove];
    }, delay);
  },
  set_query(state, query) {
    state.query = query;
  },
  set_privacy_filter(state, privacy_filter) {
    state.privacy_filter = privacy_filter;
  },
  // Sets a single param
  // Has the {name: '', value: ''} syntax
  set_param(state, param) {
    state.params[param.name] = param.value;
  },
  // Has the {name: '', value: ''} syntax
  clear_param(state, paramName) {
    delete state.params[paramName];
  },
  // Has the {name: '', value: ''} syntax
  clear_params(state, param) {
    state.params = {};
  },
  change_property_of_model(state, payload) {
    const modelId = Utility.parse_id(payload.model_id);
    const existing_index = Utility.find_index_of_matching_element(state.data, 'id', modelId);

    // Niet updaten als hij onbekend is
    // Voorkomt fouten, maar alleen optioneel, als het een mogelijkheid is. Anders wil je dat hij kapot gaat.
    if (existing_index === -1 && payload.skip_not_found) {
      return;
    }

    state.data[existing_index][payload.property_key] = payload.value;
  },
  set_search_columns(state, array) {
    // If the default search_columns are still null, we use set the current value to that.
    // Since this will only happen on the first time that the search_columns changes, we can be pretty sure
    // that these are the default values.
    if (state.default_search_columns === null) {
      state.default_search_columns = state.search_columns;
    }
    state.search_columns = array;
  },
  set_filter_by_ids(state, array) {
    state.filter_by_ids = array;
  },
  set_current_page(state, page_number) {
    state.current_page = page_number;
  },
  /*
   |--------------------------------------------------------------------------
   | Sort Mutations
   |--------------------------------------------------------------------------
   |
   | These manage the sort columns of a store
   |
   |
   */
  sort(state, payload) {
    const newSortColumns = Utility.clone(state.sort_columns);
    let updated = false;

    // If there's an element with the same column_name, we update that one
    newSortColumns.forEach((sort_column, index) => {
      if (sort_column.column_name === payload.column_name) {
        // When there's an existing element for this column name, we update it.
        newSortColumns[index].direction = payload.direction;
        updated = true;
      }
    });

    // If there's no element with the same column name, we push it
    if (!updated) {
      newSortColumns.push(payload);
    }

    state.sort_columns = newSortColumns;
  },
  // Clears all the sort columns except for the column that's been given
  clear_sort_columns(state) {
    state.sort_columns = [];
  },
  clear_other_sort_columns(state, column_name) {
    const clonedSortColumns = Utility.clone(state.sort_columns);

    clonedSortColumns.forEach(sort_column => {
      // Skip the one we don't want to remove
      if (sort_column.column_name === column_name) {
        return;
      }
      // Get the ID of the matching value
      // If we don't do this, we're removing indices in a slowly shrinking array
      const indexToRemove = Utility.find_index_of_matching_element(state.sort_columns, 'column_name', sort_column.column_name);

      state.sort_columns.splice(indexToRemove, 1);
    });
  },
  ...BaseShadowModule.mutations
};

export default {
  state,
  getters,
  actions,
  mutations
};
