import {MedicationVerificationItem} from '@/domain/models/MedicationVerificationItem';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {CodeValue} from '@/domain/models/CodeValue';
import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import moment from 'moment';

export class GeneratedMedicationVerificationItem { 
  public id: number;
  public type: string;
  public typeIcon: string;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public pharmaceuticalTreatmentId: number;
  public description: string;
  public periodOfUse: TimeIntervalObject;
  public author: string;
  public authorExtra: string;
  public informant: string;
  public hasStop: boolean;
  public stopType: CodeValue | null;
  public temporaryStop: boolean;
  public afterLastVerificationDate: boolean;
  public comment: string | null;
  public asAgreedIndicator: boolean | null;
  public useIndicator: boolean | null;
  public provenance: string | null;
  public identifier: string;
  public hasAgreement: boolean;
  public registrationDatetime: moment.Moment;
  public createdAt: moment.Moment;
  public healthcareProfessional: HealthcareProfessional | null;
  public healthcareProvider: HealthcareProvider | null;

  constructor(id: number, type: string, typeIcon: string, pharmaceuticalProduct: PharmaceuticalProduct, pharmaceuticalTreatmentId: number, description: string, periodOfUse: TimeIntervalObject, author: string, authorExtra: string, informant: string, hasStop: boolean, stopType: CodeValue | null = null, temporaryStop: boolean, afterLastVerificationDate: boolean, comment: string | null = null, asAgreedIndicator: boolean | null = null, useIndicator: boolean | null = null, provenance: string | null = null, identifier: string, hasAgreement: boolean, registrationDatetime: moment.Moment, createdAt: moment.Moment, healthcareProfessional: HealthcareProfessional | null = null, healthcareProvider: HealthcareProvider | null = null) {
    this.id = id;
    this.type = type;
    this.typeIcon = typeIcon;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.description = description;
    this.periodOfUse = periodOfUse;
    this.author = author;
    this.authorExtra = authorExtra;
    this.informant = informant;
    this.hasStop = hasStop;
    this.stopType = stopType;
    this.temporaryStop = temporaryStop;
    this.afterLastVerificationDate = afterLastVerificationDate;
    this.comment = comment;
    this.asAgreedIndicator = asAgreedIndicator;
    this.useIndicator = useIndicator;
    this.provenance = provenance;
    this.identifier = identifier;
    this.hasAgreement = hasAgreement;
    this.registrationDatetime = registrationDatetime;
    this.createdAt = createdAt;
    this.healthcareProfessional = healthcareProfessional;
    this.healthcareProvider = healthcareProvider;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      type: this.type,
      typeIcon: this.typeIcon,
      pharmaceuticalProduct: this.pharmaceuticalProduct,
      pharmaceuticalTreatmentId: this.pharmaceuticalTreatmentId,
      description: this.description,
      periodOfUse: this.periodOfUse,
      author: this.author,
      authorExtra: this.authorExtra,
      informant: this.informant,
      hasStop: this.hasStop,
      stopType: this.stopType,
      temporaryStop: this.temporaryStop,
      afterLastVerificationDate: this.afterLastVerificationDate,
      comment: this.comment,
      asAgreedIndicator: this.asAgreedIndicator,
      useIndicator: this.useIndicator,
      provenance: this.provenance,
      identifier: this.identifier,
      hasAgreement: this.hasAgreement,
      registrationDatetime: this.registrationDatetime.format('YYYY-MM-DD HH:mm:ss'),
      createdAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss'),
      healthcareProfessional: this.healthcareProfessional,
      healthcareProvider: this.healthcareProvider,
    };
  }

  public static fromJson(json: unknown): MedicationVerificationItem {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationVerificationItem(
      parsedJson['id'] as number,
      parsedJson['type'] as string,
      parsedJson['typeIcon'] as string,
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['description'] as string,
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['author'] as string,
      parsedJson['authorExtra'] as string,
      parsedJson['informant'] as string,
      parsedJson['hasStop'] as boolean,
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['temporaryStop'] as boolean,
      parsedJson['afterLastVerificationDate'] as boolean,
      parsedJson['comment'] as string,
      parsedJson['asAgreedIndicator'] as boolean,
      parsedJson['useIndicator'] as boolean,
      parsedJson['provenance'] as string,
      parsedJson['identifier'] as string,
      parsedJson['hasAgreement'] as boolean,
      moment(parsedJson['registrationDatetime']),
      moment(parsedJson['createdAt']),
      parsedJson['healthcareProfessional'] ? HealthcareProfessional.fromJson(parsedJson['healthcareProfessional']) : null,
      parsedJson['healthcareProvider'] ? HealthcareProvider.fromJson(parsedJson['healthcareProvider']) : null,
    );
  }

  public static multipleFromJson(json: unknown): MedicationVerificationItem[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
