import {GeneratedVariableDosingRegimen} from '@/domain/models/generated/GeneratedVariableDosingRegimen';
import SnomedCt from "@/enums/SnomedCt";

export class VariableDosingRegimen extends GeneratedVariableDosingRegimen {
  public hasBeenCancelled(): boolean {
    if (this.stopType === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Cancelled;
  }

  public hasToBeChanged(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }
}
