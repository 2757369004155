<template>
    <medi-modal
            v-if="patient"
            :loading="loading"
            :title="''"
            :size="size"
            :ref="'modal' + uid"
            :min_height="min_height"
            @closed="close($event)"
    >
        <template v-slot:modal-header>
            <div class="col-auto">
                <medimo-link :to="'/config/patients/photo/' + patient.id">
                        <span class="avatar image d-flex align-items-center">
                            <img :src="patient.profile_picture" alt="">
                        </span>
                </medimo-link>
            </div>
            <div class="modal-title ml-2 mr-2 my-auto col-auto">
                {{ patient.name }}
            </div>
            <div class="modal-title right my-auto col">
                {{ title }}
                <slot name="title-buttons"></slot>
            </div>
        </template>
        <slot></slot>
        <template v-slot:action-buttons>
            <slot name="action-buttons"></slot>
        </template>
    </medi-modal>
</template>

<script>
import MediModal from '@/vue/components/general/MediModal';
import MedimoLink from '@/vue/components/general/MedimoLink';

export default {
  components: {
    MediModal,
    MedimoLink,
  },
  emits: ['closed'],
  props: {
    'loading': {default: false},
    'title': {default: ''},
    'size': {default: ''},
    'min_height': {default: 60},

    'patient_id': {required: true},
    'patient_data': {required: false, default: null},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    patient() {
      if (this.patient_data !== null) {
        return this.patient_data;
      }
      return this.$store.getters['patients/find'](this.patient_id);
    },
    uid() {
      return this._.uid;
    }
  },

  created() {
    if (this.patient === null) {
      this.$store.dispatch('patients/fetch_single', {id: this.patient_id});
    }
  },

  mounted() {
    //
  },

  methods: {
    close(event) {
      this.$emit('closed', event);
    }
  },

  watch: {
    //
  }
};
</script>
