import {CodeSystem} from '@/domain/models/CodeSystem';
import Utility from '@/vue/utility/utility';

export class GeneratedCodeSystem { 
  public id: number;
  public oid: string;
  public url: string;

  constructor(id: number, oid: string, url: string) {
    this.id = id;
    this.oid = oid;
    this.url = url;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      oid: this.oid,
      url: this.url,
    };
  }

  public static fromJson(json: unknown): CodeSystem {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new CodeSystem(
      parsedJson['id'] as number,
      parsedJson['oid'] as string,
      parsedJson['url'] as string,
    );
  }

  public static multipleFromJson(json: unknown): CodeSystem[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }

  public static fromObject(object: CodeSystem | null | undefined): CodeSystem {
    const clonedObject = Utility.deep_clone(object);
    return new CodeSystem(
      clonedObject.id as number,
      clonedObject.oid as string,
      clonedObject.url as string,
    );
  }
}
