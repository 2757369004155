// This file is autogenerated. It contains all icons used in this project. You can regenerate this file via `npm run update-icons`. After regenerating this file, execute `npm run prod`
// Your icons should have the following format in order to be found: <fa-icon icon='fa-(duotone|light|regular|solid|thin|kit) (fa-sharp )*fa-[a-z-]+' />
// To speed things up, the script currently only checks the app and resources directories. If your icon exists in another directory, feel free to add it as well.

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Use deep imports for FontAwesome icons to prevent unused icons being bundled in development
import { faBell as fadBell } from 'fontawesome/pro-duotone-svg-icons/faBell';
import { faBellRing as fadBellRing } from 'fontawesome/pro-duotone-svg-icons/faBellRing';
import { faCalendarDays as fadCalendarDays } from 'fontawesome/pro-duotone-svg-icons/faCalendarDays';
import { faClock as fadClock } from 'fontawesome/pro-duotone-svg-icons/faClock';
import { faFileExclamation as fadFileExclamation } from 'fontawesome/pro-duotone-svg-icons/faFileExclamation';
import { faFloppyDisk as fadFloppyDisk } from 'fontawesome/pro-duotone-svg-icons/faFloppyDisk';
import { faHatWizard as fadHatWizard } from 'fontawesome/pro-duotone-svg-icons/faHatWizard';
import { faMessages as fadMessages } from 'fontawesome/pro-duotone-svg-icons/faMessages';
import { faMortarPestle as fadMortarPestle } from 'fontawesome/pro-duotone-svg-icons/faMortarPestle';
import { faRepeat as fadRepeat } from 'fontawesome/pro-duotone-svg-icons/faRepeat';
import { faRotateLeft as fadRotateLeft } from 'fontawesome/pro-duotone-svg-icons/faRotateLeft';
import { faCapsule as fakCapsule } from '@/vue/icons/custom_icons';
import { faCalendarAlt as falCalendarAlt } from 'fontawesome/pro-light-svg-icons/faCalendarAlt';
import { faCalendarDays as falCalendarDays } from 'fontawesome/pro-light-svg-icons/faCalendarDays';
import { faCrosshairs as falCrosshairs } from 'fontawesome/pro-light-svg-icons/faCrosshairs';
import { faFileLines as falFileLines } from 'fontawesome/pro-light-svg-icons/faFileLines';
import { faArrowRightFromBracket as farArrowRightFromBracket } from 'fontawesome/pro-regular-svg-icons/faArrowRightFromBracket';
import { faArrowUpToLine as farArrowUpToLine } from 'fontawesome/pro-regular-svg-icons/faArrowUpToLine';
import { faBuilding as farBuilding } from 'fontawesome/pro-regular-svg-icons/faBuilding';
import { faChevronDown as farChevronDown } from 'fontawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp as farChevronUp } from 'fontawesome/pro-regular-svg-icons/faChevronUp';
import { faCircle as farCircle } from 'fontawesome/pro-regular-svg-icons/faCircle';
import { faCircleCheck as farCircleCheck } from 'fontawesome/pro-regular-svg-icons/faCircleCheck';
import { faCircleDot as farCircleDot } from 'fontawesome/pro-regular-svg-icons/faCircleDot';
import { faCircleExclamation as farCircleExclamation } from 'fontawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleInfo as farCircleInfo } from 'fontawesome/pro-regular-svg-icons/faCircleInfo';
import { faCircleNodes as farCircleNodes } from 'fontawesome/pro-regular-svg-icons/faCircleNodes';
import { faClipboardCheck as farClipboardCheck } from 'fontawesome/pro-regular-svg-icons/faClipboardCheck';
import { faClock as farClock } from 'fontawesome/pro-regular-svg-icons/faClock';
import { faDotCircle as farDotCircle } from 'fontawesome/pro-regular-svg-icons/faDotCircle';
import { faEyeSlash as farEyeSlash } from 'fontawesome/pro-regular-svg-icons/faEyeSlash';
import { faFileCertificate as farFileCertificate } from 'fontawesome/pro-regular-svg-icons/faFileCertificate';
import { faFiles as farFiles } from 'fontawesome/pro-regular-svg-icons/faFiles';
import { faGauge as farGauge } from 'fontawesome/pro-regular-svg-icons/faGauge';
import { faHeart as farHeart } from 'fontawesome/pro-regular-svg-icons/faHeart';
import { faLightbulb as farLightbulb } from 'fontawesome/pro-regular-svg-icons/faLightbulb';
import { faMortarPestle as farMortarPestle } from 'fontawesome/pro-regular-svg-icons/faMortarPestle';
import { faRectangleList as farRectangleList } from 'fontawesome/pro-regular-svg-icons/faRectangleList';
import { faQrcode as fasrQrcode } from 'fontawesome/sharp-regular-svg-icons/faQrcode';
import { faShuffle as farShuffle } from 'fontawesome/pro-regular-svg-icons/faShuffle';
import { faSnowflake as farSnowflake } from 'fontawesome/pro-regular-svg-icons/faSnowflake';
import { faSquare as farSquare } from 'fontawesome/pro-regular-svg-icons/faSquare';
import { faStar as farStar } from 'fontawesome/pro-regular-svg-icons/faStar';
import { faTimeline as farTimeline } from 'fontawesome/pro-regular-svg-icons/faTimeline';
import { faTriangleExclamation as farTriangleExclamation } from 'fontawesome/pro-regular-svg-icons/faTriangleExclamation';
import { faUser as farUser } from 'fontawesome/pro-regular-svg-icons/faUser';
import { faAdd as fasAdd } from 'fontawesome/pro-solid-svg-icons/faAdd';
import { faAdjust as fasAdjust } from 'fontawesome/pro-solid-svg-icons/faAdjust';
import { faArrowLeft as fasArrowLeft } from 'fontawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight as fasArrowRight } from 'fontawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowRightFromBracket as fasArrowRightFromBracket } from 'fontawesome/pro-solid-svg-icons/faArrowRightFromBracket';
import { faArrowTurnDownRight as fasArrowTurnDownRight } from 'fontawesome/pro-solid-svg-icons/faArrowTurnDownRight';
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare } from 'fontawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faBan as fasBan } from 'fontawesome/pro-solid-svg-icons/faBan';
import { faBell as fasBell } from 'fontawesome/pro-solid-svg-icons/faBell';
import { faBellRing as fasBellRing } from 'fontawesome/pro-solid-svg-icons/faBellRing';
import { faBirthdayCake as fasBirthdayCake } from 'fontawesome/pro-solid-svg-icons/faBirthdayCake';
import { faBomb as fasBomb } from 'fontawesome/pro-solid-svg-icons/faBomb';
import { faBriefcase as fasBriefcase } from 'fontawesome/pro-solid-svg-icons/faBriefcase';
import { faBriefcaseMedical as fasBriefcaseMedical } from 'fontawesome/pro-solid-svg-icons/faBriefcaseMedical';
import { faCancel as fasCancel } from 'fontawesome/pro-solid-svg-icons/faCancel';
import { faCaretDown as fasCaretDown } from 'fontawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretLeft as fasCaretLeft } from 'fontawesome/pro-solid-svg-icons/faCaretLeft';
import { faCaretRight as fasCaretRight } from 'fontawesome/pro-solid-svg-icons/faCaretRight';
import { faCaretUp as fasCaretUp } from 'fontawesome/pro-solid-svg-icons/faCaretUp';
import { faChartSimple as fasChartSimple } from 'fontawesome/pro-solid-svg-icons/faChartSimple';
import { faCheck as fasCheck } from 'fontawesome/pro-solid-svg-icons/faCheck';
import { faCircleExclamation as fasCircleExclamation } from 'fontawesome/pro-solid-svg-icons/faCircleExclamation';
import { faCircleInfo as fasCircleInfo } from 'fontawesome/pro-solid-svg-icons/faCircleInfo';
import { faCircleQuestion as fasCircleQuestion } from 'fontawesome/pro-solid-svg-icons/faCircleQuestion';
import { faCircleXmark as fasCircleXmark } from 'fontawesome/pro-solid-svg-icons/faCircleXmark';
import { faClock as fasClock } from 'fontawesome/pro-solid-svg-icons/faClock';
import { faClockRotateLeft as fasClockRotateLeft } from 'fontawesome/pro-solid-svg-icons/faClockRotateLeft';
import { faClose as fasClose } from 'fontawesome/pro-solid-svg-icons/faClose';
import { faDatabase as fasDatabase } from 'fontawesome/pro-solid-svg-icons/faDatabase';
import { faDisplay as fasDisplay } from 'fontawesome/pro-solid-svg-icons/faDisplay';
import { faDownload as fasDownload } from 'fontawesome/pro-solid-svg-icons/faDownload';
import { faEarth as fasEarth } from 'fontawesome/pro-solid-svg-icons/faEarth';
import { faEarthAmericas as fasEarthAmericas } from 'fontawesome/pro-solid-svg-icons/faEarthAmericas';
import { faEarthEurope as fasEarthEurope } from 'fontawesome/pro-solid-svg-icons/faEarthEurope';
import { faEllipsisVertical as fasEllipsisVertical } from 'fontawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faExclamation as fasExclamation } from 'fontawesome/pro-solid-svg-icons/faExclamation';
import { faExclamationTriangle as fasExclamationTriangle } from 'fontawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExternalLink as fasExternalLink } from 'fontawesome/pro-solid-svg-icons/faExternalLink';
import { faFloppyDisk as fasFloppyDisk } from 'fontawesome/pro-solid-svg-icons/faFloppyDisk';
import { faGear as fasGear } from 'fontawesome/pro-solid-svg-icons/faGear';
import { faGraduationCap as fasGraduationCap } from 'fontawesome/pro-solid-svg-icons/faGraduationCap';
import { faHandHoldingMedical as fasHandHoldingMedical } from 'fontawesome/pro-solid-svg-icons/faHandHoldingMedical';
import { faHouse as fasHouse } from 'fontawesome/pro-solid-svg-icons/faHouse';
import { faInbox as fasInbox } from 'fontawesome/pro-solid-svg-icons/faInbox';
import { faKey as fasKey } from 'fontawesome/pro-solid-svg-icons/faKey';
import { faList as fasList } from 'fontawesome/pro-solid-svg-icons/faList';
import { faLocationDot as fasLocationDot } from 'fontawesome/pro-solid-svg-icons/faLocationDot';
import { faLock as fasLock } from 'fontawesome/pro-solid-svg-icons/faLock';
import { faMagnifyingGlass as fasMagnifyingGlass } from 'fontawesome/pro-solid-svg-icons/faMagnifyingGlass';
import { faO as fasO } from 'fontawesome/pro-solid-svg-icons/faO';
import { faPause as fasPause } from 'fontawesome/pro-solid-svg-icons/faPause';
import { faPen as fasPen } from 'fontawesome/pro-solid-svg-icons/faPen';
import { faPlus as fasPlus } from 'fontawesome/pro-solid-svg-icons/faPlus';
import { faPrint as fasPrint } from 'fontawesome/pro-solid-svg-icons/faPrint';
import { faQuestionCircle as fasQuestionCircle } from 'fontawesome/pro-solid-svg-icons/faQuestionCircle';
import { faRemove as fasRemove } from 'fontawesome/pro-solid-svg-icons/faRemove';
import { faReplyAll as fasReplyAll } from 'fontawesome/pro-solid-svg-icons/faReplyAll';
import { faRetweet as fasRetweet } from 'fontawesome/pro-solid-svg-icons/faRetweet';
import { faRotateLeft as fasRotateLeft } from 'fontawesome/pro-solid-svg-icons/faRotateLeft';
import { faSave as fasSave } from 'fontawesome/pro-solid-svg-icons/faSave';
import { faSearch as fasSearch } from 'fontawesome/pro-solid-svg-icons/faSearch';
import { faCircleInfo as fassCircleInfo } from 'fontawesome/sharp-solid-svg-icons/faCircleInfo';
import { faMessages as fassMessages } from 'fontawesome/sharp-solid-svg-icons/faMessages';
import { faShieldQuartered as fasShieldQuartered } from 'fontawesome/pro-solid-svg-icons/faShieldQuartered';
import { faShuffle as fasShuffle } from 'fontawesome/pro-solid-svg-icons/faShuffle';
import { faSpinner as fasSpinner } from 'fontawesome/pro-solid-svg-icons/faSpinner';
import { faSquareCaretLeft as fasSquareCaretLeft } from 'fontawesome/pro-solid-svg-icons/faSquareCaretLeft';
import { faSquareCaretRight as fasSquareCaretRight } from 'fontawesome/pro-solid-svg-icons/faSquareCaretRight';
import { faSquareCheck as fasSquareCheck } from 'fontawesome/pro-solid-svg-icons/faSquareCheck';
import { faStar as fasStar } from 'fontawesome/pro-solid-svg-icons/faStar';
import { faStethoscope as fasStethoscope } from 'fontawesome/pro-solid-svg-icons/faStethoscope';
import { faTimes as fasTimes } from 'fontawesome/pro-solid-svg-icons/faTimes';
import { faTrashAlt as fasTrashAlt } from 'fontawesome/pro-solid-svg-icons/faTrashAlt';
import { faTrashCan as fasTrashCan } from 'fontawesome/pro-solid-svg-icons/faTrashCan';
import { faUpRightFromSquare as fasUpRightFromSquare } from 'fontawesome/pro-solid-svg-icons/faUpRightFromSquare';
import { faUpload as fasUpload } from 'fontawesome/pro-solid-svg-icons/faUpload';
import { faUser as fasUser } from 'fontawesome/pro-solid-svg-icons/faUser';
import { faUserGroup as fasUserGroup } from 'fontawesome/pro-solid-svg-icons/faUserGroup';

library.add(fadBell, fadBellRing, fadCalendarDays, fadClock, fadFileExclamation, fadFloppyDisk, fadHatWizard, fadMessages, fadMortarPestle, fadRepeat, fadRotateLeft, fakCapsule, falCalendarAlt, falCalendarDays, falCrosshairs, falFileLines, farArrowRightFromBracket, farArrowUpToLine, farBuilding, farChevronDown, farChevronUp, farCircle, farCircleCheck, farCircleDot, farCircleExclamation, farCircleInfo, farCircleNodes, farClipboardCheck, farClock, farDotCircle, farEyeSlash, farFileCertificate, farFiles, farGauge, farHeart, farLightbulb, farMortarPestle, farRectangleList, fasrQrcode, farShuffle, farSnowflake, farSquare, farStar, farTimeline, farTriangleExclamation, farUser, fasAdd, fasAdjust, fasArrowLeft, fasArrowRight, fasArrowRightFromBracket, fasArrowTurnDownRight, fasArrowUpRightFromSquare, fasBan, fasBell, fasBellRing, fasBirthdayCake, fasBomb, fasBriefcase, fasBriefcaseMedical, fasCancel, fasCaretDown, fasCaretLeft, fasCaretRight, fasCaretUp, fasChartSimple, fasCheck, fasCircleExclamation, fasCircleInfo, fasCircleQuestion, fasCircleXmark, fasClock, fasClockRotateLeft, fasClose, fasDatabase, fasDisplay, fasDownload, fasEarth, fasEarthAmericas, fasEarthEurope, fasEllipsisVertical, fasExclamation, fasExclamationTriangle, fasExternalLink, fasFloppyDisk, fasGear, fasGraduationCap, fasHandHoldingMedical, fasHouse, fasInbox, fasKey, fasList, fasLocationDot, fasLock, fasMagnifyingGlass, fasO, fasPause, fasPen, fasPlus, fasPrint, fasQuestionCircle, fasRemove, fasReplyAll, fasRetweet, fasRotateLeft, fasSave, fasSearch, fassCircleInfo, fassMessages, fasShieldQuartered, fasShuffle, fasSpinner, fasSquareCaretLeft, fasSquareCaretRight, fasSquareCheck, fasStar, fasStethoscope, fasTimes, fasTrashAlt, fasTrashCan, fasUpRightFromSquare, fasUpload, fasUser, fasUserGroup);

export default FontAwesomeIcon;
