
// initial state
const state = {
  store_namespace: 'settings',
  formState: [],
  repeatPeriod: false,
};

// getters
const getters = {
  state(state) {
    return state.formState;
  },
  repeat_period(state) {
    return state.repeatPeriod;
  },
};

// actions
const actions = {

};

// mutations
const mutations = {
  set_form_state(state, value) {
    state.formState = value;
  },
  set_repeat_period(state, value) {
    state.repeatPeriod = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
