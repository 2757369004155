const date = '';
import moment from 'moment-mini';

export default {

  dateIsBeforeNow(date) {
    const now = moment().unix();
    date = moment(date).unix();
    return date < now;
  },

  nowIsBetweenDates(fromDate, toDate) {

    const now = moment().unix();
    const date1 = moment(fromDate).unix();

    // When the length of the toDate is empty, just check the first date
    if (toDate === null) {
      return now > date1;
    }
    if (toDate.length === 0) {
      return now > date1;
    }

    const date2 = moment(toDate).unix();

    return now > date1 && now < date2;
  },

  /*
   |--------------------------------------------------------------------------
   | Carbon-like JS Date functionality
   |--------------------------------------------------------------------------
   |
   | Takes the Carbon data from the backend to manipulate in the
   | frontend
   |
   */
  setDate(localDateTime) {
    this.date = null;
    if (localDateTime == null) {
      return this; // not set, so return
    }
    this.date = moment(localDateTime);
    this.date.locale('NL');
    return this;
  },

  dutchDate() {
    if (this.date == null) {
      return ''; // empty
    }
    return this.date.format('DD-MM-YYYY');
  },

  dutchDateTime() {
    if (this.date == null) {
      return ''; // empty
    }
    return this.date.format('DD-MM-YYYY HH:mm');
  },

  isNowOrFuture() {
    // Empty date
    if (this.date == null) {
      return false;
    }
    // Today or anywhere in the future
    if (this.date.isSameOrAfter(moment())) {
      return true;
    }
    return false;
  },

  isTodayOrFuture() {
    // Empty date
    if (this.date == null) {
      return false;
    }
    // Today or anywhere in the future
    if (this.date.isSameOrAfter(moment().startOf('day'))) {
      return true;
    }
    return false;
  },

  normalizedDateTime() {
    if (this.isTodayOrFuture()) {
      return this.dutchDateTime();
    }
    return this.dutchDate();
  },

  /**
   * Displays a human readable date:
   * - Vandaag = 'Vandaag om 10:00u'
   * - Gisteren = 'Gisteren om 10:00u'
   * - Eerder maar in hetzelfde jaar = 'Woensdag 2 juni'
   * - Eerder in een ander jaar = 'Woensdag 2 juni YYYY'
   *
   * Optional parameter now for testing purposes
   */
  humanReadableDate(now, withTime) {
    // now
    if (typeof now === 'undefined') {
      now = moment();
    }

    let includeTime = '';
    if (withTime) {
      includeTime = ' HH:mmu';
    }

    // gister
    const yesterday = now.clone().subtract(1, 'days');

    // vandaag
    if (this.date.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
      return 'Vandaag om ' + this.date.format('HH:mm') + 'u';
    }

    // gisteren
    if (this.date.format('YYYY-MM-DD') === yesterday.format('YYYY-MM-DD')) {
      return 'Gisteren om ' + this.date.format('HH:mm') + 'u';
    }

    // dit jaar
    if (this.date.format('YYYY') === now.format('YYYY')) {
      return this.date.format('dddd D MMMM' + includeTime);
    }

    // vorig jaar of nog eerder
    return this.date.format('dddd D MMMM YYYY' + includeTime);
  },

  humanReadableDateWithTime(now) {
    return this.humanReadableDate(now, true);
  }
};
