import BaseModule from '@/vue/store/modules/base_module';

const state = {...BaseModule.state};
const getters = {...BaseModule.getters};
const actions = {...BaseModule.actions};
const mutations = {...BaseModule.mutations};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
