import {DispenseRequest} from '@/domain/models/DispenseRequest';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {CodeObject} from '@/domain/models/CodeObject';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import {QuantityObject} from '@/domain/models/QuantityObject';
import {AddressObject} from '@/domain/models/AddressObject';
import moment from 'moment';

export class GeneratedDispenseRequest { 
  public id: number;
  public externalIdentifier: string;
  public externalIdentifierSystem: CodeSystem;
  public periodOfUse: TimeIntervalObject | null;
  public numberOfRefills: number | null;
  public comment: string | null;
  public medicationAgreementId: number;
  public pharmaceuticalTreatmentId: number;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public additionalWishes: CodeObject[];
  public financialIndicationCode: CodeObject | null;
  public intendedSupplier: HealthcareProvider | null;
  public amount: QuantityObject | null;
  public registrationDatetime: moment.Moment;
  public dispenseLocation: AddressObject | null;
  public periodOfUseDescription: string | null;
  public sentStatus: boolean;

  constructor(id: number, externalIdentifier: string, externalIdentifierSystem: CodeSystem, periodOfUse: TimeIntervalObject | null = null, numberOfRefills: number | null = null, comment: string | null = null, medicationAgreementId: number, pharmaceuticalTreatmentId: number, pharmaceuticalProduct: PharmaceuticalProduct, additionalWishes: CodeObject[], financialIndicationCode: CodeObject | null = null, intendedSupplier: HealthcareProvider | null = null, amount: QuantityObject | null = null, registrationDatetime: moment.Moment, dispenseLocation: AddressObject | null = null, periodOfUseDescription: string | null = null, sentStatus: boolean) {
    this.id = id;
    this.externalIdentifier = externalIdentifier;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.periodOfUse = periodOfUse;
    this.numberOfRefills = numberOfRefills;
    this.comment = comment;
    this.medicationAgreementId = medicationAgreementId;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.additionalWishes = additionalWishes;
    this.financialIndicationCode = financialIndicationCode;
    this.intendedSupplier = intendedSupplier;
    this.amount = amount;
    this.registrationDatetime = registrationDatetime;
    this.dispenseLocation = dispenseLocation;
    this.periodOfUseDescription = periodOfUseDescription;
    this.sentStatus = sentStatus;
  }

  public toJSON(): unknown {
    return {
      id: this.id,
      externalIdentifier: this.externalIdentifier,
      externalIdentifierSystem: this.externalIdentifierSystem,
      periodOfUse: this.periodOfUse,
      numberOfRefills: this.numberOfRefills,
      comment: this.comment,
      medicationAgreementId: this.medicationAgreementId,
      pharmaceuticalTreatmentId: this.pharmaceuticalTreatmentId,
      pharmaceuticalProduct: this.pharmaceuticalProduct,
      additionalWishes: this.additionalWishes,
      financialIndicationCode: this.financialIndicationCode,
      intendedSupplier: this.intendedSupplier,
      amount: this.amount,
      registrationDatetime: this.registrationDatetime.format('YYYY-MM-DD HH:mm:ss'),
      dispenseLocation: this.dispenseLocation,
      periodOfUseDescription: this.periodOfUseDescription,
      sentStatus: this.sentStatus,
    };
  }

  public static fromJson(json: unknown): DispenseRequest {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DispenseRequest(
      parsedJson['id'] as number,
      parsedJson['externalIdentifier'] as string,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      parsedJson['periodOfUse'] ? TimeIntervalObject.fromJson(parsedJson['periodOfUse']) : null,
      parsedJson['numberOfRefills'] as number,
      parsedJson['comment'] as string,
      parsedJson['medicationAgreementId'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['additionalWishes'] ? CodeObject.multipleFromJson(parsedJson['additionalWishes']) : [],
      parsedJson['financialIndicationCode'] ? CodeObject.fromJson(parsedJson['financialIndicationCode']) : null,
      parsedJson['intendedSupplier'] ? HealthcareProvider.fromJson(parsedJson['intendedSupplier']) : null,
      parsedJson['amount'] ? QuantityObject.fromJson(parsedJson['amount']) : null,
      moment(parsedJson['registrationDatetime']),
      parsedJson['dispenseLocation'] ? AddressObject.fromJson(parsedJson['dispenseLocation']) : null,
      parsedJson['periodOfUseDescription'] as string,
      parsedJson['sentStatus'] as boolean,
    );
  }

  public static multipleFromJson(json: unknown): DispenseRequest[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
