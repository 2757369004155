import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  data: [],
  all: {},
  sort_columns: [
    {column_name: 'drugName', direction: 'ASC'}
  ],
  quick_filters: {
    'Alleen actief': true,
  },
  filter_columns: [
    'drugName',
    'startDateTime',
    'endDateTime',
  ],
};

const getters = {
  ...BaseModule.getters,
  alleen_actief_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filterState) => {
      if (!filterState) return data;

      return data.filter((model) => {
        return model.active;
      });
    };
  },
};

const actions = {
  ...BaseModule.actions,
};

const mutations = {
  ...BaseModule.mutations,
  set_all(state, payload) {
    state.all = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
