import {ContactObject} from '@/domain/models/ContactObject';
import {PhoneNumberObject} from '@/domain/models/PhoneNumberObject';
import {EmailAddressObject} from '@/domain/models/EmailAddressObject';

export class GeneratedContactObject { 
  public phoneNumbers: PhoneNumberObject[];
  public emailAddresses: EmailAddressObject[];

  constructor(phoneNumbers: PhoneNumberObject[], emailAddresses: EmailAddressObject[]) {
    this.phoneNumbers = phoneNumbers;
    this.emailAddresses = emailAddresses;
  }

  public toJSON(): unknown {
    return {
      phoneNumbers: this.phoneNumbers,
      emailAddresses: this.emailAddresses,
    };
  }

  public static fromJson(json: unknown): ContactObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new ContactObject(
      parsedJson['phoneNumbers'] ? PhoneNumberObject.multipleFromJson(parsedJson['phoneNumbers']) : [],
      parsedJson['emailAddresses'] ? EmailAddressObject.multipleFromJson(parsedJson['emailAddresses']) : [],
    );
  }

  public static multipleFromJson(json: unknown): ContactObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return Array.isArray(parsedJson) ? parsedJson.map((item) => this.fromJson(item)) : [];
  }
}
