<template>
  <div id="protocol-builder">
    <div class="rules">
      <div v-for="(rule, ruleId) in rules" class="rule">
        <div class="pt-4 pb-4 pl-1">Regel {{ ruleId + 1 }} <fa-icon v-if="editMode" class="remove" icon="fa-solid fa-times" @click="clearRule(ruleId)" /></div>
        <table>
          <tr v-for="row in rule.rows">
            <td v-for="item in getItemsInRow(ruleId, row - 1)" :class="{border: item.show}">
              <protocol-item
                  :editMode="editMode"
                  :rule="rule"
                  :ruleid="ruleId"
                  :item="item"
                  :options="getOptions(ruleId, item)"
                  @clear="clearItem"
                  @add="addItem"
                  @select="selectOption"
              />
            </td>
          </tr>
        </table>
      </div>
      <div id="addRule" v-if="editMode" @click="addRule()"><fa-icon icon="fa-solid fa-plus" />Extra regel toevoegen</div>
      <div id="json-rules" v-if="editMode" v-show="false">{{ jsonRules }}</div>
    </div>

    <protocol-action-modal v-if="showProtocolActionModal" :item="itemForModal" @closed="showProtocolActionModal = false" @save="setOption" />
    <extra-protocol-modal v-if="showExtraProtocolModal" :item="itemForModal" @closed="showExtraProtocolModal = false" @save="setOption" />
    <filter-tree-modal
        v-if="showTreeFilterModal"
        :tree_filter_modal_props="treeFilterModalProps"
        :filter_route="filter_route"
        :language="language"
        @closed="showTreeFilterModal = false"
        @save="setOption"
    />
  </div>
</template>

<script>
import ProtocolItem from '@/vue/components/protocol/ProtocolItem.vue';
import FilterTreeModal from '@/vue/components/protocol/FilterTreeModal.vue';
import ProtocolActionModal from '@/vue/components/protocol/ProtocolActionModal.vue';
import ExtraProtocolModal from '@/vue/components/protocol/ExtraProtocolModal.vue';
import OptionHelper from "@/modules/protocol/OptionHelper";
import ProtocolStatusTemplate from "@/modules/protocol/ProtocolStatusTemplate";

export default {
  components: {ProtocolActionModal, ExtraProtocolModal, FilterTreeModal, ProtocolItem},

  props: {
    'editMode': {required: true},
    'initial_rules': {required: true},
    'listId': {required: false},
    'protocolId': {required: false},
    'filter_route': {required: false},
    'language': {required: false},
    'used_episode_names': {required: false}
  },

  data() {
    return {
      rules: [],
      maxCol: 4,
      visibleRules: '',
      showTreeFilterModal: false,
      treeFilterModalProps: null,
      showProtocolActionModal: false,
      showExtraProtocolModal: false,
      itemForModal: null,
      ruleIdForModal: null,
    };
  },

  computed: {
    jsonRules() {
      return JSON.stringify(this.visibleRules);
    }
  },
  created() {
    this.rules = JSON.parse(this.initial_rules);

    //If we have no rules yet:
    // add the first rule and display the options for the first item
    if (this.rules.length === 0) {
      this.rules.push({
        cols: 4,
        rows: 1,
        items: [],
      });
      this.showItem(0, 0, 0);
    } else {
      for (let n = 0; n < this.rules.length; n++) {
        let maxRow = 0;
        let maxCol = 3;
        const items = this.rules[n];

        for (let i = 0; i < items.length; i++) {
          if (items[i].row > maxRow) maxRow = items[i].row;
          if (items[i].col > maxCol) maxCol = items[i].col;
        }

        this.rules[n].items = items;
        this.rules[n].cols = maxCol + 1;
        this.rules[n].rows = maxRow + 1;

        if (maxCol + 1 > this.maxCol) {
          this.maxCol = maxCol + 1;
        }
      }

      //Add parents and children
      for (let n = 0; n < this.rules.length; n++) {
        const items = this.rules[n];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.parent !== false) {
            const parentItem = this.getItem(n, item.parent.row, item.parent.col);
            item.parent = parentItem[1];
          }

          const children = [];
          for (let j = 0; j < item.children.length; j++) {
            const childItem = this.getItem(n, item.children[j].row, item.children[j].col);
            children.push(childItem[1]);
          }
          item.children = children;
        }
      }
    }

    this.updateVisibleItems();
  },
  methods: {
    flatten(values) {
      const flattened = {};
      for (let i = 0; i < values.length; i++) {
        //skip undefined's, token and manualdosing time entries (md-...)
        if (typeof flattened[values[i].name] === 'undefined' && values[i].name !== '_token' && values[i].name.substring(0, 3) !== 'md-') {
          flattened[values[i].name] = values[i].value;
        }
      }
      return flattened;
    },
    selectOption(option, ruleId, item) {
      this.itemForModal = item;
      this.ruleIdForModal = ruleId;
      if (option === OptionHelper.actionOption) {
        this.showProtocolActionModal = true;
      } else if (option === OptionHelper.filterOption) {
        this.showCriteriaFilterPopup(false, item, option);
      } else if (option === OptionHelper.filterStatusOption) {
        this.showCriteriaFilterPopup(true, item, option);
      } else if (option === OptionHelper.startOption) {
        this.showStartStatusPopup('createStatus', item.children.length > 0, item.children.length > 0, () => {
          let values = $('.form-status-new :input').serializeArray();

          values = this.flatten(values);
          values.type = 'N';

          //force usageType because it is not included when the element is disabled
          if (values['manualdosing-enabled'] === '1') {
            values.usageType = 'S';
          } else if ($('.form-status-new #radio_usageType_T').is(':checked')) {
            values.usageType = 'T';
          } else {
            values.usageType = 'C';
          }

          values.episodes = $('.form-status-new #episodesMultiSelect').val();
          this.setOption(item.row, item.col, option, values);
        }, item.values, true);
      } else if (option === OptionHelper.changeOption) {
        //On change a user cannot change the usageType (since this is not possible for provisionals)
        this.showStartStatusPopup('editStatus', true, item.children.length > 0, () => {
          let values = $('.form-status-new :input').serializeArray();
          values = this.flatten(values);
          values.type = 'W';
          values.episodes = $('.form-status-new #episodesMultiSelect').val();

          //force usageType because it is not included when the element is disabled
          if (values['manualdosing-enabled'] === '1') {
            values.usageType = 'S';
          } else if ($('.form-status-new #radio_usageType_T').is(':checked')) {
            values.usageType = 'T';
          } else {
            values.usageType = 'C';
          }

          this.setOption(item.row, item.col, option, values);
        }, item.values === false ? item.parent.values : item.values, false);
      } else if (option === OptionHelper.stopOption) {
        this.showStopStatusPopup('editStatus', () => {
          let values = $('.form-status-new :input').serializeArray();
          values = this.flatten(values);
          values.type = 'S';
          this.setOption(item.row, item.col, option, values);
        }, item.values === false ? item.parent.values : item.values, false);
      } else if (option === OptionHelper.stopFoundStatusOption) {
        this.showStopStatusPopup('editStatus', () => {
          let values = $('.form-status-new :input').serializeArray();
          values = this.flatten(values);
          values.type = 'S';
          this.setOption(item.row, item.col, option, values);
        }, item.values === false ? item.parent.values : item.values, true);
      } else if (option === OptionHelper.customQuestionOption) {
        this.showCreateCustomQuestionPopup(() => {
          let values = $('#createCustomQuestionForm :input').serializeArray();
          values = this.flatten(values);
          this.setOption(item.row, item.col, option, values);
          window.closePopup();
        }, 'Stel vraag', item.values);
      } else if (option === OptionHelper.customAnswerOption) {
        this.showCreateCustomAnswerPopup(() => {
          let values = $('#createCustomAnswerForm :input').serializeArray();
          values = this.flatten(values);
          this.setOption(item.row, item.col, option, values);
          window.closePopup();
        }, 'Antwoord optie', item.values);
      } else if (option === OptionHelper.extraProtocolOption) {
        this.showExtraProtocolModal = true;
      } else {
        this.setOption(item.row, item.col, option, []);
      }
    },
    getItemsInRow(ruleId, row) {
      const rowItems = [];
      for (let i = 0; i < this.rules[ruleId].cols; i++) {
        rowItems.push(this.getItem(ruleId, row, i)[1]);
      }
      return rowItems;
    },
    addItem(pos, ruleId, item) {
      if (pos === 'right') {
        this.showItem(ruleId, item.row, item.col + 1);
        this.addRelation(ruleId, item.row, item.col, item.row, item.col + 1);
      } else if (pos === 'bottom') {
        const maxRowInChildren = this.getMaxRowInChildren(ruleId, item.row, item.col);
        this.moveDownIfGreaterOrEqualTo(ruleId, maxRowInChildren + 1);
        this.showItem(ruleId, maxRowInChildren + 1, item.col);

        if (item.col > 0) {
          this.addRelation(ruleId, item.parent.row, item.parent.col, maxRowInChildren + 1, item.col);
        }
      }
    },
    addRule() {
      const newRuleIndex = this.rules.length;
      this.rules.push({
        cols: 4,
        rows: 1,
        items: [],
      });
      this.showItem(newRuleIndex, 0, 0);
    },
    getOptions: function (n, item) {
      //Don't load option if the item already has an option
      if (item.type !== false) {
        return [];
      }

      let options = [];

      // case first row
      if (item.col === 0) {
        options = [
          OptionHelper.startOption,
          OptionHelper.filterOption,
          OptionHelper.filterStatusOption,
          OptionHelper.actionOption,
          OptionHelper.customQuestionOption,
          OptionHelper.extraProtocolOption
        ];
      } else {
        const parent = item.parent;

        if (parent.type === OptionHelper.startOption || parent.type === OptionHelper.changeOption) {
          //options.push(OptionHelper.startOption);
          if (parent.values['usageType'] !== 'T') {
            options.push(OptionHelper.changeOption);
            if (this.siblingsDoNotContainOption(item, OptionHelper.stopOption)) {
              options.push(OptionHelper.stopOption);
            }
          }
        } else if (parent.type === OptionHelper.customQuestionOption) {
          return [OptionHelper.customAnswerOption];
        } else {
          if (item.parent.type === OptionHelper.filterStatusOption && this.siblingsDoNotContainOption(item, OptionHelper.stopFoundStatusOption)) {
            options.push(OptionHelper.stopFoundStatusOption);
          }
          options.push(OptionHelper.startOption);
          options.push(OptionHelper.filterOption);
          options.push(OptionHelper.filterStatusOption);
          options.push(OptionHelper.actionOption);
          options.push(OptionHelper.customQuestionOption);
          options.push(OptionHelper.extraProtocolOption);
        }
      }

      return options;
    },
    siblingsDoNotContainOption(item, type) {
      let found = false;
      const items = item.parent.children;
      items.forEach(function (item) {
        if (item.type === type) found = true;
      });
      return !found;
    },
    updateVisibleItems() {
      const visibleRules = [];

      for (let i = 0; i < this.rules.length; i++) {
        const itemsInRule = this.getVisibleItemsInRule(i);
        if (itemsInRule.length > 0) {
          visibleRules.push(itemsInRule);
        }
      }
      this.visibleRules = visibleRules;
    },
    getVisibleItemsInRule(n) {
      const filtered = this.rules[n].items.filter(function (item) {
        return item.show === true && item.type !== false;
      });
      const items = [];
      for (let i = 0; i < filtered.length; i++) {
        const item = filtered[i];
        const children = item.children.map(function (child) {
          return {row: child.row, col: child.col};
        });
        let parent = false;
        if (item.parent !== false) parent = {row: item.parent.row, col: item.parent.col};
        //items.push({row: item.row, col: item.col, show: true, type: item.type});
        items.push({
          row: item.row,
          col: item.col,
          show: true,
          type: item.type,
          parent: parent,
          children: children,
          values: item.values
        });
      }
      return items;
    },
    moveDownIfGreaterOrEqualTo(n, row) {
      const rule = this.rules[n];
      const items = rule.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.row >= row) {
          item.row += 1;

          if (item.row + 1 >= rule.rows && item.show === true) {
            rule.rows = item.row + 1;
          }
        }
      }
    },
    moveUpIfGreaterThan(n, row) {
      const rule = this.rules[n];
      let items = rule.items;
      const removeIndexAfter = [];

      //sort items array
      items = items.sort(function (a, b) {
        if (a.row === b.row) return a.col > b.col;
        else return a.row > b.row;
      });

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.row > row && item.show) {
          //clear the existing item
          const original = this.getItem(n, item.row - 1, item.col);
          item.row -= 1;
          removeIndexAfter.push(original[0]);
        }
      }
      removeIndexAfter.sort(function (a, b) {
        return a - b;
      });

      for (let i = 0; i < removeIndexAfter.length; i++) {
        const index = removeIndexAfter[i] - i;
        items.splice(index, 1);
      }

    },
    getMaxRowInChildren: function (n, r, c) {
      const item = this.getItem(n, r, c)[1];
      let maxRow = r;
      for (let i = 0; i < item.children.length; i++) {
        const child = item.children[i];
        if (this.getMaxRowInChildren(n, child.row, child.col) > maxRow) {
          maxRow = child.row;
        }
      }
      return maxRow;
    },
    isDisabled: function () {
      return false;
    },
    setOption: function (r, c, type, values) {
      //Get existing item or create a new one
      const itemValues = this.getItem(this.ruleIdForModal, r, c);
      const item = itemValues[1];
      const itemIndex = item[0];

      //Set values
      item.type = type;
      if (typeof values !== 'undefined') {
        item.values = values;
      }

      this.updateVisibleItems();
      this.rules[this.ruleIdForModal].items[itemIndex] = item;
    },
    addRelation: function (n, parent_r, parent_c, child_r, child_c) {
      const childValues = this.getItem(n, child_r, child_c);
      const parentValues = this.getItem(n, parent_r, parent_c);
      const childItem = childValues[1];
      const parentItem = parentValues[1];

      parentItem.children.push(childItem);
      childItem.parent = parentItem;
    },
    showItem: function (n, r, c) {
      const itemValues = this.getItem(n, r, c);
      const item = itemValues[1];
      const itemIndex = itemValues[0];
      item.show = true;

      const rule = this.rules[n];
      this.rules[n].items[itemIndex] = item;

      if (r >= rule.rows) {
        rule.rows = r + 1;
      }

      if (c >= rule.cols) {
        rule.cols = c + 1;
        if (rule.cols > this.maxCol) {
          this.maxCol = rule.cols;
        }
      }

      this.updateVisibleItems();
    },
    clearRule: function (n) {
      this.rules.splice(n, 1);
      this.updateVisibleItems();
    },
    clearItem: function (ruleId, item) {
      const parent = item.parent;

      let noChildrenParent = 0;
      if (parent !== false) {
        noChildrenParent = parent.children.length;
        const index = parent.children.indexOf(item);
        if (index > -1) {
          parent.children.splice(index, 1);
        }
      }

      //just reset the item
      item.values = false;
      item.children = [];
      item.type = false;

      if (!(item.row === 0 && item.col === 0)) {
        item.show = false;
      }

      if (noChildrenParent > 1) {
        //do some moving
        this.moveUpIfGreaterThan(ruleId, item.row);
      }
      this.updateVisibleItems();
    },
    getItem: function (n, r, c) {
      const rule = this.rules[n];

      for (let i = 0; i < rule.items.length; i++) {
        if (rule.items[i].row === r && rule.items[i].col === c) {
          return [i, rule.items[i]];
        }
      }

      const item = {
        col: c,
        row: r,
        type: false,
        values: false,
        show: false,
        children: [],
        parent: false
      };
      this.rules[n].items.push(item);
      return [this.rules[n].items.length - 1, item];
    },
    showCriteriaFilterPopup(onlyStatusRelated, item, option) {
      this.showTreeFilterModal = !this.showTreeFilterModal;

      let modalTitle = 'Voeg filter toe';
      if (onlyStatusRelated) {
        modalTitle = 'Zoek medicatie';
      }

      let groups = [{
        "t": "g",
        "name": "0-g-0",
        "logic": "a",
        "items": [{"t": "i", "name": "0-g-0-i-0", "value": "", "text": ""}]
      }];
      let logic = 'a';
      let inverse = false;

      if (typeof item.values !== 'undefined' && item.values !== false) {
        const tree = JSON.parse(item.values);
        groups = tree.groups;
        logic = tree.logic;
        if (typeof tree.inverse !== 'undefined') {
          inverse = tree.inverse;
        }
      }

      this.treeFilterModalProps = {
        title: modalTitle,
        groups: groups,
        logic: logic,
        inverse: inverse,
        option: option,
        rowId: item.row,
        colId: item.col,
      };
    },
    showCreateCustomQuestionPopup(onSubmit, title, values) {
      const buttons = [{
        label: "Annuleren",
        attributes: {
          class: "close secondary _button",
          id: "cancelButton",
        },
        callback: function () {
          window.closePopup();
        }
      }, {
        label: "Opslaan",
        attributes: {
          class: "close submit _button",
          id: "submitButton"
        },
        callback: function () {
          onSubmit();
        }
      }];

      const formId = 'createCustomQuestionForm';

      window.medimo.ModalActions.callbackModal(title, '<div id="' + formId + '">' + $("#createCustomQuestionTemplate").html() + '</div>', buttons);

      if (typeof values !== 'undefined') {
        const keys = Object.keys(values);

        for (let i = 0; i < keys.length; i++) {
          const name = keys[i];
          const value = values[name];
          if (name === 'question') {
            $('#createCustomQuestionForm #questionName').val(value);
          }
        }
      }
    },
    showCreateCustomAnswerPopup(onSubmit, title, values) {
      const buttons = [
        {
          label: "Annuleren",
          attributes: {
            class: "close secondary _button",
            id: "cancelButton",
          },
          callback: function () {
            window.closePopup();
          }
        },
        {
          label: "Opslaan",
          attributes: {
            class: "close submit _button",
            id: "submitButton"
          },
          callback: function () {
            onSubmit();
          }
        }
      ];

      const formId = 'createCustomAnswerForm';

      window.medimo.ModalActions.callbackModal(title, '<div id="' + formId + '">' + $("#createCustomAnswerTemplate").html() + '</div>', buttons);

      if (typeof values !== 'undefined') {
        const keys = Object.keys(values);

        for (let i = 0; i < keys.length; i++) {
          const name = keys[i];
          const value = values[name];
          if (name === 'answer') {
            $('#createCustomAnswerForm #answerName').val(value);
          }
        }
      }
    },
    showStartStatusPopup(formId, usageTypeSelectDisabled, switchToManualDosingSchemaDisabled, onSubmit, values, prescribeNowDisabled) {
      // prevents misplaced select2 options
      scrollTo(0, 0);

      const protocolStatusTemplate = new ProtocolStatusTemplate(this.used_episode_names);
      protocolStatusTemplate.editStartStatusTemplate(this.listId, this.protocolId, formId, usageTypeSelectDisabled, switchToManualDosingSchemaDisabled, onSubmit, values, 'start', prescribeNowDisabled);
    },
    showStopStatusPopup(formId, onSubmit, values, prescribeNowDisabled) {
      const protocolStatusTemplate = new ProtocolStatusTemplate(this.used_episode_names);
      protocolStatusTemplate.editStartStatusTemplate(this.listId, this.protocolId, formId, false, false, onSubmit, values, 'stop', prescribeNowDisabled);
    }
  }
};
</script>

<style scoped lang="scss">
#protocol-builder {
  .rule .remove {
    cursor: pointer;
  }
  svg.svg-inline--fa {
    color: #bababa;
  }
  table {
    border-collapse: separate;
    border-spacing: 2px;
    td {
      width: 220px;
      min-height: 5px;
      text-align: center;
      position: relative;
    }
  }
  #addRule {
    padding: 10px 0 10px 2px;
    cursor:pointer;
  }
}
</style>
