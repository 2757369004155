import {GeneratedTimeIntervalObject} from '@/domain/models/generated/GeneratedTimeIntervalObject';
import moment from "moment";
import Ucum from '@/enums/Ucum';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export class TimeIntervalObject extends GeneratedTimeIntervalObject {
  public isActive() {
    const now = moment();

    if (this.startDateTime?.isBefore(now) && this.endDateTimeOrDuration() === null) {
      return true;
    }

    if (this.startDateTime?.isBefore(now) && this.endDateTimeOrDuration()?.isAfter(now)) {
      return true;
    }

    return false;
  }

  public hasEnded() {
    const now = moment();

    if (this.endDateTimeOrDuration() === null) {
      return false;
    }

    if (this.endDateTimeOrDuration()?.isAfter(now)) {
      return false;
    }

    return true;
  }

  // Returns the selected end date time or the calculated
  // end date time base on the selected duration.
  public endDateTimeOrDuration(): moment.Moment | null {
    if (this.endDateTime) {
      return this.endDateTime;
    }

    if (this.duration && this.startDateTime) {
      // Mutable so we need to clone it with moment() else it will keep adding the days to the same instance whenever this method called.
      return moment(this.startDateTime).add(this.duration.value, this.convertDurationCode(this.duration.code?.code));
    }

    return null;
  }

  public durationText(): string {
    if (!this.duration) {
      return '';
    }

    return this.duration.value + ' ' + this.duration.code?.display;
  }

  private convertDurationCode(code: string | undefined): DurationConstructor {
    switch (code) {
      case Ucum.Year:
        return 'y';
      case Ucum.Week:
        return 'w';
      default:
        return 'd';
    }
  }
}
